import React from 'react';
import { Field } from 'react-final-form';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const DefaultRadioGroup = (props) => {
  const { label, name, value, onChange, onBlur, onFocus, error, sx, children, ...rest } = props;

  return (
    <FormControl error={!!error} sx={sx}>
      <FormLabel id={name}>{label}</FormLabel>
      <RadioGroup name={name} onChange={onChange} value={value} onBlur={onBlur} onFocus={onFocus} {...rest}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

DefaultRadioGroup.defaultProps = {
  type: 'text',
  onBlur: () => false,
  onFocus: () => false,
  onChange: () => false,
};

const TextInputWithField = ({ name, validate, ...rest }) => {
  return (
    <Field
      name={name}
      type="radio"
      validate={validate}
      render={({ input, meta: { touched, error } }) => (
        <DefaultRadioGroup error={touched && error ? error : false} {...input} {...rest} />
      )}
    />
  );
};

export default TextInputWithField;
