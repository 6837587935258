import React from 'react';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ListIcon from '@mui/icons-material/List';
import Typography from '@mui/material/Typography';
import Dialog from 'components/dialogs/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';

const CertificateDetailDialog = (props) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = React.useState(false);
  const { breakdown, ...rest } = props;

  const toggleDialog = () => {
    setToggle(!toggle);
  };

  return (
    <>
      {/* <Button color="secondary" endIcon={<InfoIcon />} onClick={toggleDialog}>
        {t('action.showCertificateDetails', 'Show details')}
      </Button> */}
      <Dialog max="960px" open={toggle} onClose={toggleDialog}>
        <DialogHeader onClose={toggleDialog}>
          <DialogTitle>
            <ListIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
            {t('dialog.certificateDetails.title', 'Breakdown of the certificate')}
          </DialogTitle>
        </DialogHeader>
        <DialogContent>
          <TableContainer sx={{ maxHeight: 450, minHeight: 450 }}>
            <Table stickyHeader size="small" aria-label="Certificate Breakdown">
              <TableHead>
                <TableRow>
                  <TableCell>{t('field.subject', 'Subject')}</TableCell>
                  <TableCell align="center">{t('field.expired', 'Expired')}</TableCell>
                  <TableCell align="center">{t('field.qualifiedIssuer', 'Qualified Issuer')}</TableCell>
                  <TableCell align="right">{t('field.expiresOn', 'Expires On')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {breakdown.map((item) => (
                  <TableRow key={item.subject} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="td" scope="row">
                      <Tooltip title={item.subject} placement="top-start">
                        <Typography
                          variant="body2"
                          sx={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {item.subject}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="td" scope="row" align="center">
                      {item.expired ? <HighlightOffIcon color="error" /> : <CheckIcon color="success" />}
                    </TableCell>
                    <TableCell component="td" scope="row" align="center">
                      {item.qualifiedIssuer ? <CheckIcon color="success" /> : <HighlightOffIcon color="error" />}
                    </TableCell>
                    <TableCell component="td" scope="row" align="right">
                      {moment(item.expiresOn, 'ddd MMM DD HH:mm:ss Z YYYY').format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

CertificateDetailDialog.defaultProps = {
  breakdown: [],
};

export default CertificateDetailDialog;
