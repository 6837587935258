import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ApiIcon from '@mui/icons-material/Api';
import DevicesIcon from '@mui/icons-material/Devices';
import SensorsIcon from '@mui/icons-material/Sensors';
import TerminalIcon from '@mui/icons-material/Terminal';
import InfoIcon from '@mui/icons-material/Info';
import InfoBlock from 'components/InfoBlock';

const DeviceInfo = ({ info, version }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h2">Device Information</Typography>

      <Grid sx={{ mt: 0.5 }} container spacing={2}>
        {/* First row */}
        <Grid item xs={6} md={3}>
          <InfoBlock
            icon={<ApiIcon color="secondary" />}
            title={info?.t1CInfoAPI?.version}
            subtitle={t('page.admin.info.apiVersion', 'API Version')}
            showCopy={true}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoBlock
            icon={<ApiIcon color="secondary" />}
            title={version}
            subtitle={t('page.admin.info.javascriptVersion', 'Javascript Version')}
            showCopy={true}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoBlock
            icon={<SensorsIcon color="secondary" />}
            title={info?.t1CInfoAPI?.status}
            subtitle={t('page.admin.info.status', 'Status')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoBlock
            icon={<InfoIcon color="secondary" />}
            title={info?.t1CInfoAPI?.logLevel}
            subtitle={t('page.admin.info.logLevel', 'Log Level')}
          />
        </Grid>

        {/* Second row */}
        <Grid item xs={6} md={3}>
          <InfoBlock
            icon={<TerminalIcon color="secondary" />}
            title={info?.t1CInfoOS?.platform}
            subtitle={t('page.admin.info.operatingSystem', 'Operating System')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoBlock
            icon={<TerminalIcon color="secondary" />}
            title={`${info?.t1CInfoOS?.version} - ${info?.t1CInfoOS?.architecture}`}
            subtitle={t('page.admin.info.version', 'Version')}
            showCopy={true}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <InfoBlock
            icon={<DevicesIcon color="secondary" />}
            title={info?.t1CInfoAPI?.uid}
            subtitle={t('page.admin.info.deviceId', 'Device ID')}
            showCopy={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceInfo;
