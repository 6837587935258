import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppFooter } from 'components/Layouts';
import Styled from './AppContainer.style';

const AppContainerMaxWidth = ({ children, fullscreen }) => {
  const theme = useTheme();

  return (
    <Styled
      id="main-content"
      theme={theme}
      fullscreen={!!fullscreen}
      maxWidth={false}
      className="app-layout__container"
    >
      <div className="app-layout__container__inner">{children}</div>

      <AppFooter />
    </Styled>
  );
};

AppContainerMaxWidth.defaultProps = {
  fullscreen: false,
};

export default AppContainerMaxWidth;
