import styled from '@emotion/styled';

const Styled = styled.div`
  /*Ratio is 8/5*/
  width: 400px;
  height: 250px;
  padding: 25px 25px 10px 0px;
  border-radius: 15px;
  position: relative;
  border-radius: 20px;
  background-image: linear-gradient(47deg, #85144a 0%, #71103c 31%, #550824 100%);
  color: white;

  .card-payment-logo {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    img {
      display: block;

      width: 75px;
    }
  }

  .chip-row {
    display: flex;
    align-items: center;
    margin-bottom: 13.5px;
  }

  .chip {
    width: 50px;
    margin-left: 25px;
    margin-right: 10px;
  }

  .chip-type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .chip-type__toggle {
    flex: 1;
    background-color: red;
    border-radius: 25px;
    height: 40px;
    background-color: #911856;
    margin: 0 10px;
    position: relative;
  }

  .chip-type__toggle__button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 4px;
    background-image: linear-gradient(134deg, #6a769b 0%, #201535 43%, #6a769b 100%);
  }

  .text-uppercase {
    font-size: 15px;
    text-transform: uppercase;
    color: white;
  }

  .card-content {
    width: 100%;
    background-color: white;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    text-align: right;
    padding-top: 7.5px;
    padding-bottom: 5px;
    padding-right: 40px;
    padding-bottom: 7.5px;
  }

  .card-content-text {
    color: black;
    line-height: 1.1;
    font-size: 14px;
  }
`;

export default Styled;
