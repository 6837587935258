import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Styled from './PinPadStepperDialog.style';

const steps = ['Sign', 'Download'];

const StepperDialog = (props) => {
  const { t } = useTranslation();
  const [type, setType] = React.useState();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [keyboardTab, setKeyboardTab] = React.useState('numpad');

  const { open, onClose } = props;

  const handleClose = React.useCallback(() => {
    onClose();
    setCurrentStep(0);
  }, []);

  React.useEffect(() => {
    return () => {
      if (open === false) {
        setKeyboardTab('numpad');
      }
    };
  }, [open]);

  const nextStep = () => setCurrentStep(currentStep + 1);

  const onStep1Completed = React.useCallback((response) => {
    setType(response);
    nextStep();
  }, []);

  const handleStep = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const isStepDisabled = (stepIndex) => currentStep < stepIndex;
  const isStepCompleted = (stepIndex) => currentStep > stepIndex;

  const renderStepContent = () =>
    React.useMemo(() => {
      if (currentStep === 1) {
        return (
          <Step2 type={type} keyboardTab={keyboardTab} onKeyboardTabChanged={setKeyboardTab} onClose={handleClose} />
        );
      }

      return <Step1 stepCompleted={onStep1Completed} onClose={handleClose} />;
    }, [currentStep, type, keyboardTab]);

  return (
    <Dialog onClose={handleClose} open={open} max={keyboardTab === 'numpad' ? `350px` : `550px`}>
      <Styled>
        <Box className="dialog__stepper" sx={{ width: '100%' }}>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} index={index} completed={isStepCompleted(index)} disabled={isStepDisabled(index)}>
                <StepButton color="inherit" onClick={() => handleStep(index)}>
                  <StepLabel>{t(`PinPad.stepper.step${index + 1}.stepTitle`)}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Divider />

        <Box className="dialog__content">{renderStepContent()}</Box>
      </Styled>
    </Dialog>
  );
};

StepperDialog.defaultProps = {
  open: false,
  onClose: () => false,
};

export default StepperDialog;
