import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ExampleCardAlert from 'components/generic/components/ExampleCardAlert';
import CardFront from './components/Front';
import CardBack from './components/Back';
import Styled from './BeIdCard.style';
import { Box, Paper, Typography } from '@mui/material';

const BeIdCard = () => {
  const theme = useTheme();

  const biometric = useSelector((state) => state.card.biometric);
  const picture = useSelector((state) => state.card.picture);
  const hasNoData = !biometric && !picture;

  return (
    <Styled theme={theme}>
      {hasNoData && <ExampleCardAlert />}

      <Typography variant="h3">Belgian EID</Typography>

      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item xs={12} md="auto">
          <CardFront hasNoData={hasNoData} biometric={biometric} picture={picture} />
        </Grid>
        <Grid item xs={12} md="auto">
          <CardBack hasNoData={hasNoData} biometric={biometric} />
        </Grid>
      </Grid>
    </Styled>
  );
};

export default BeIdCard;
