import React from 'react';
import { clsx } from 'clsx';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const Styled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(1.6)};
  border-width: 0;
  border-style: solid;
  border-bottom-width: thin;
  border-bottom-color: ${(props) =>
    props.theme.palette.mode === 'dark' ? alpha(props.theme.palette.primary[100], 0.08) : '#ddd'};
`;

const DialogHeader = ({ className, children, onClose }) => {
  const theme = useTheme();

  return (
    <Styled theme={theme} className={clsx('simpleDialog__header', className)}>
      <div className="simpleDialog__header__left">{children}</div>
      <div className="simpleDialog__header__right">
        <IconButton size="small" edge="end" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </Styled>
  );
};

DialogHeader.defaultProps = {
  onClose: () => false,
};

export default DialogHeader;
