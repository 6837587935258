import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const ErrorBlock = ({ title, subtitle, actions }) => (
  <Paper className="error-block" variant="block" elevation={2}>
    <Box className="error-block__header">
      <Typography className="error-block__header__title" variant="h2">
        {title}
      </Typography>
      <Typography className="error-block__header__subtitle" variant="body1" sx={{ mt: 1 }}>
        {subtitle}
      </Typography>
    </Box>
    {actions && (
      <Box className="error-block__actions" sx={{ mt: 2 }}>
        {actions}
      </Box>
    )}
  </Paper>
);

export default ErrorBlock;
