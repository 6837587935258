import Api from 'utils/Api';
import HttpError from 'utils/errors/HttpError';
import SyntaxError from 'utils/errors/SyntaxError';

const authenticate = async (means) => {
  try {
    let data = {
      means: means,
    };
    const response = await Api.post(`${window._env_.REACT_APP_BFF_URL}/v1/smart-id/auth`, data, {
      'Content-Type': 'application/json',
    });

    if (response.status === 200) {
      return response.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const getSessionResult = async (sessionId) => {
  try {
    const response = await Api.get(`${window._env_.REACT_APP_BFF_URL}/v1/smart-id/session/${sessionId}`, {
      'Content-Type': 'application/json',
    });

    if (response.status === 200) {
      return response.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const getSessionReport = async (sessionId) => {
  try {
    const response = await Api.get(`${window._env_.REACT_APP_BFF_URL}/v1/smart-id/report/${sessionId}`, {
      'Content-Type': 'application/json',
    });

    if (response.status === 200) {
      return response.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const getAvailableMeans = async () => {
  try {
    const response = await Api.get(`${window._env_.REACT_APP_BFF_URL}/v1/smart-id/means`, {
      'Content-Type': 'application/json',
    });

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

export default {
  authenticate,
  getSessionResult,
  getSessionReport,
  getAvailableMeans,
};
