const hasExactArrayStringValues = (arr, compareArr) =>
  arr.reduce((bool, item, index) => {
    if (item !== compareArr[index]) {
      return false;
    }

    return bool;
  }, true);

const isSameLocation = (currentLocation = {}, newLocation = {}) => {
  const { entity: currEntity, type: currType, relPaths: currRelPaths } = currentLocation;
  const { entity: newEntity, type: newType, relPaths: newRelPaths } = newLocation;

  // Check if all values exists
  if (!currEntity || !newEntity || !currType || !newType || !currRelPaths || !newRelPaths) {
    return false;
  }

  // Check if entities are the same
  if (currEntity !== newEntity) {
    return false;
  }

  // Check if types are the same
  if (currType !== newType) {
    return false;
  }

  if (currRelPaths.length !== newRelPaths.length) {
    return false;
  }

  // Check if the location is in the same subFolder
  if (!hasExactArrayStringValues(currRelPaths, newRelPaths)) {
    return false;
  }

  return true;
};

export default {
  hasExactArrayStringValues,
  isSameLocation,
};
