import Api from 'utils/Api';
import HttpError from 'utils/errors/HttpError';
import SyntaxError from 'utils/errors/SyntaxError';

const url = `${window._env_.REACT_APP_T1C_URL}:${window._env_.REACT_APP_SS_PORT}`;

const sendDocument = async (name) => {
  try {
    let body = {
      filename: name,
      callback: 'https://localhost:9000/hook', // does not matter in this case
      externalId: '123456', // does not matter in this case
    };
    const response = await Api.post(`${url}/v3/modules/simplesign`, body, {
      'Content-Type': 'application/json',
    });

    if (response.status === 200) {
      return response.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const uploadDocument = async (file, name) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', name);

    const response = await Api.post(`${url}/v3/modules/fileexchange/apps/file/download`, formData, {
      'Content-Type': 'multipart/form-data',
    });

    if (response.status === 200) {
      return response.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const getSimpleSignInfo = async () => {
  try {
    const response = await Api.get(`${url}/v3/modules/simplesign`, undefined, undefined);
    if (response.status === 200) {
      return response.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

export default {
  sendDocument,
  uploadDocument,
  getSimpleSignInfo,
};
