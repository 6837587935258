import React from 'react';
import { clsx } from 'clsx';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';

const Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  padding: ${(props) => props.theme.spacing(1.6, 2.4)};
`;

const DialogFooter = ({ className, children, align }) => {
  const theme = useTheme();

  return (
    <Styled theme={theme} align={align} className={clsx('simpleDialog__footer', className)}>
      {children}
    </Styled>
  );
};

DialogFooter.defaultProps = {
  align: 'flex-end',
};

export default DialogFooter;
