import * as React from 'react';
import { styled } from '@mui/material/styles';

const Svg = styled('svg')({
  verticalAlign: 'bottom',
});

const SvgHamburgerMenu = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 16 16" fill="none" {...props}>
      <rect x={1} y={3.5} width={14} height={1.5} rx={1} fill="#238aba" />
      <rect x={1} y={7.5} width={14} height={1.5} rx={1} fill="#238aba" />
      <rect x={1} y={11.5} width={14} height={1.5} rx={1} fill="#238aba" />
    </Svg>
  );
};
export default SvgHamburgerMenu;
