import React from 'react';
import { useSelector } from 'react-redux';
import { constructMachineReadableStrings } from 'utils/helpers';

const CardBack = () => {
  const biometric = useSelector((state) => state.card.biometric);

  const mrz = React.useMemo(() => constructMachineReadableStrings(biometric), [biometric]);

  return (
    <div className="back">
      <div className="back__top">
        <div className="back__top__left">
          <img className="chip" src="/assets/images/credit-card.svg" alt="Chip" />
        </div>
        <div className="back__top__right">
          <div className="field">
            <div className="field__label">Domicilio</div>
            <div className="field__value no-margin">[TODO Address]</div>
            <div className="field__value no-margin">[TODO City]</div>
            <div className="field__value no-margin">[TODO Region]</div>
          </div>
          <div className="field">
            <div className="field__label">Lugar de nacimiento</div>
            <div className="field__value no-margin">[City]</div>
            <div className="field__value no-margin">[Region]</div>
          </div>

          <div className="field">
            <div className="field__label">Hijo / A de</div>
            <div className="field__value">[TODO DAD] / [TODO MOM]</div>
          </div>
        </div>
      </div>
      <div className="back__bottom">
        {mrz && (
          <div className="machine-readable">
            <div>{mrz[0]}</div>
            <div>{mrz[1]}</div>
            <div>{mrz[2]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardBack;
