import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { AppContainer } from 'components/Layouts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AuthenticationService from '../../infrastructure/services/AuthenticationService/AuthenticationService';
import Authentications from './components/Authentications';
import { useDispatch, useSelector } from 'react-redux';
import CreateAuthenticationDialog from './components/CreateAuthenticationDialog/CreateAuthenticationDialog';
import CreatedAuthentication from './components/CreatedAuthentication';
import { clearAuthentication, setAuthentication } from '../../infrastructure/reducers/authentication';
import Error403 from '../Errors/Error403/Error403';
const Partners = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [authenticationsLoading, setAuthenticationsLoading] = React.useState(false);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [authenticationOpen, setAuthenticationOpen] = React.useState(false);
  const partner = useSelector((state) => state.partner.partner);
  const [authentications, setAuthentications] = React.useState([]);

  const getAuthentications = () => {
    try {
      setAuthenticationsLoading(true);
      AuthenticationService.getDocumentsByPartner(partner.id)
        .then((res) => {
          setAuthentications(res);
          setAuthenticationsLoading(false);
        })
        .catch((e) => setAuthenticationsLoading(false));
    } catch (e) {
      setAuthenticationsLoading(false);
    }
  };

  const handleDialogOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleDialogClose = (data) => {
    if (data) {
      setAuthenticationOpen(true);
    }
    setCreateDialogOpen(false);
  };

  const handleAuthenticationAlertClose = () => {
    dispatch(clearAuthentication());
    setAuthenticationOpen(false);
  };

  useEffect(() => {
    getAuthentications();
  }, []);

  if (partner === null) {
    return (
      <AppContainer>
        <Error403></Error403>
      </AppContainer>
    );
  } else {
    return (
      <AppContainer>
        <CreateAuthenticationDialog open={createDialogOpen} onClose={handleDialogClose} />
        <Box sx={{ mb: 2 }}>
          <Typography variant="h2">
            {t('page.partner.title', 'Partner overview for ')} {partner.name}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {t(
              'page.partner.subtitleDescription',
              'Here you can find an overview of your authentication requests. You can also create a new authentication request.',
            )}
          </Typography>
        </Box>
        <Stack>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4">Create a new authentication request</Typography>
            <Typography variant="p">
              The button below will create a new authentication request that you can use request an authentication from
              a user
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Button
                onClick={handleDialogOpen}
                color="primary"
                size="medium"
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('action.authenticationCreate', 'Create authentication')}
              </Button>
            </Box>

            <Box sx={{ mt: 1 }}>
              <CreatedAuthentication open={authenticationOpen} onClose={handleAuthenticationAlertClose} />
            </Box>
          </Box>
          <Authentications
            getAuthentications={getAuthentications}
            authentications={authentications}
            loading={authenticationsLoading}
          />
        </Stack>
      </AppContainer>
    );
  }
};

export default Partners;
