import React from 'react';
import ListCell from './../ListCell';

const FileSizeCell = (props) => {
  const { size, width, align, ...rest } = props;

  const getContent = () => {
    if (size === 0) {
      return '--';
    }

    // smaller then 1 KB
    if (size < 1000) {
      return `${size} bytes`;
    }

    // smaller then 1 MB
    if (size < 1000000) {
      return `${Math.abs(size / 1000).toFixed(2)} KB`;
    }

    // smaller then 1 GB
    if (size < 1000000000) {
      return `${Math.abs(size / 1000000).toFixed(2)} MB`;
    }

    return `${Math.abs(size / 1000000).toFixed(2)} GB`;
  };

  return (
    <ListCell className="ld__c" width={width} align={align} {...rest}>
      {getContent()}
    </ListCell>
  );
};

FileSizeCell.defaultProps = {
  size: 0,
  width: 15,
  align: 'right',
};

export default FileSizeCell;
