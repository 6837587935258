import React from 'react';
import { useTranslation } from 'react-i18next';
import useConnector from 'infrastructure/hooks/useConnector';
import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from 'components/Loading';
import { AppContainer } from 'components/Layouts';
import ErrorBlock from 'components/ErrorBlock';
import TruststoreTable from './components/TruststoreTable';
import { Stack, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { gte } from 'semver';

const Truststore = () => {
  const { client } = useConnector();
  const { t } = useTranslation();
  const [validVersion, setValidVersion] = React.useState(false);
  const [certs, setCerts] = React.useState();
  const [loadingInfo, setLoadingInfo] = React.useState(true);
  const [error, setError] = React.useState(false);

  const fetchData = async () => {
    setLoadingInfo(true);

    try {
      let info = await client.core().info();
      setValidVersion(gte(info.t1CInfoAPI.version, '3.8.0'));
      let certs = await client.truststore().allCerts();
      setCerts(certs.data);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setLoadingInfo(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onRefresh = async () => {
    await fetchData();
  };

  const renderContent = () => {
    if (loadingInfo) {
      return <Loading />;
    }
    if (!validVersion) {
      return (
        <ErrorBlock
          title={t('errorBlock.truststoreversion.title', 'No Truststore support')}
          subtitle={t(
            'errorBlock.truststoreversion.subtitleDescription',
            'You need at least version 3.8.0 of the Trust1Connector to use the Truststore',
          )}
        />
      );
    }

    if (error) {
      return (
        <ErrorBlock
          title={t('errorBlock.default.title', 'Whoops! Something went wrong')}
          subtitle={t(
            'errorBlock.default.subtitleDescription',
            'Something went wrong while fetching the data, please try again..',
          )}
          actions={
            <Button color="primary" size="medium" variant="outlined" startIcon={<ReplayIcon />} onClick={fetchData}>
              {t('errorBlock.default.submit', 'Try again')}
            </Button>
          }
        />
      );
    }

    return (
      <Box>
        <Stack sx={{ mb: 2 }} direction="row" alignItems="flex-end" justifyContent="space-between">
          <Box>
            <Typography variant="h2">{t('page.truststore.title', 'Truststore')}</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {t('page.truststore.subtitle', 'Please select the certificate you want to use')}
            </Typography>
          </Box>
          <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={onRefresh}>
            {t('action.refresh', 'Refresh')}
          </Button>
        </Stack>
        <TruststoreTable certificates={certs} />
      </Box>
    );
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

export default Truststore;
