import en from './messages/en';
import nl from './messages/nl';
import fr from './messages/fr';

const messages = {
  en: { all: en },
  nl: { all: nl },
  fr: { all: fr },
};

export default messages;
