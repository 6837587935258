import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme, width, align }) => `
  width: ${width}%;

  .lh__c {
    width: 100%;
    padding: ${theme.spacing(0.8, 1.5)};
    color: ${theme.palette.grey[700]};
    font-weight: 400;
    font-size: 12px;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;

    ${
      align === 'center' &&
      `
        justify-content: center;
      `
    }

    ${
      align === 'right' &&
      `
        justify-content: flex-end;
      `
    }
  }
`,
);

export default Styled;
