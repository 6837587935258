import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';

const CardFront = () => {
  const biometric = useSelector((state) => state.card.biometric);
  const picture = useSelector((state) => state.card.picture);

  return (
    <div className="front">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <div className="front__title">ESPAÑA</div>
          <img className="top-icon-shape" src="/assets/images/spain-id-shape.svg" />
        </Stack>
        <div className="front__subtitle">Documento Nacional De Identidad</div>
      </Stack>

      <div className="card-content">
        <div className="card-content__left">
          <div className="picture-wrapper">
            <img
              className="profile-picture"
              src={`data:image/png;base64,${picture?.picture}`}
              alt={`${biometric?.name} ${biometric?.firstNames} ${biometric?.thirdName}`}
            />
            <div className="field dni">
              <div className="field__label">DNI</div>
              <div className="field__value">[TODO]</div>
            </div>
          </div>
        </div>
        <div className="card-content__middle">
          <div className="field">
            <div className="field__label">Apellidos</div>
            <div className="field__value">
              {biometric?.firstNames} {biometric?.thirdName}
            </div>
          </div>
          <div className="field">
            <div className="field__label">Nombre</div>
            <div className="field__value">{biometric?.name}</div>
          </div>
          <div className="field-row">
            <div className="field">
              <div className="field__label small">Sexo / Sexua</div>
              <div className="field__value">{biometric?.sex}</div>
            </div>
            <div className="field">
              <div className="field__label small">Nacionalidad / Nazionalitatea</div>
              <div className="field__value">{biometric?.nationality.toUpperCase()}</div>
            </div>
          </div>
          <div className="field">
            <div className="field__label small">Fecha de nacimiento</div>
            <div className="field__value">{biometric?.birthDate}</div>
          </div>
          <div className="field-row">
            <div className="field">
              <div className="field__label">Num Soporte</div>
              <div className="field__value">
                {`${biometric?.cardNumber.substr(0, 3)}-${biometric?.cardNumber.substr(
                  3,
                  7,
                )}-${biometric?.cardNumber.substr(10, 2)}`}
              </div>
            </div>
            <div className="field">
              <div className="field__label">Validez</div>
              <div className="field__value">{biometric?.cardValidityDateEnd}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFront;
