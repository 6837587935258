import React from 'react';
import Box from '@mui/material/Box';
import BeIdCard from 'components/generic/cards/BeIdCard';
import Address from './components/Address';
import Certificates from './components/Certificates';
import Actions from './components/Actions';

const GenericBeid = () => (
  <Box>
    <BeIdCard />

    <Address />

    <Certificates />

    <Actions />
  </Box>
);

export default GenericBeid;
