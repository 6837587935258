import React from 'react';
import { Trans } from 'react-i18next';
import Button from '@mui/material/Button';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Styled from './ErrorBoundary.style.js';

class ErrorBoundary extends React.Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  handleClick = () => {
    window.location.href = '/';
  };

  render() {
    const { hasError, errorMessage: error, errorInfo } = this.state;

    if (!hasError) {
      // You can render any custom fallback UI
      return this.props.children;
    }

    return (
      // ErrorPage component
      <Styled
        title={<Trans i18nKey="errors.crashed.title">Whoops! :( Something went wrong</Trans>}
        description={
          <Trans i18nKey="errors.crashed.description">
            A client error occurred and your request coudn't be completed.
          </Trans>
        }
        body={
          process.env.NODE_ENV !== 'production' && (
            <Accordion className="error__panel">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Trans i18nKey="field.details">Details</Trans>
              </AccordionSummary>
              <AccordionDetails className="error__panel-details">
                <div>
                  <h2>{error.toString()}</h2>
                  {errorInfo.componentStack}
                </div>
              </AccordionDetails>
            </Accordion>
          )
        }
        actions={
          <>
            <Button onClick={this.handleClick} className="error__action" varaint="contained" color="primary">
              <Trans i18nKey="action.clearCacheAndTryAgain">Clear cache and try again</Trans>
            </Button>
          </>
        }
      />
    );
  }
}

export default ErrorBoundary;
