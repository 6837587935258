import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { styled, alpha } from '@mui/material/styles';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import useClient from 'infrastructure/hooks/useClient';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import PersistScroll from './components/PersistScroll';
import AppNavDrawerItem from './components/AppNavDrawerItem';
import packageJSON from '../../../../package.json';
import { useSelector } from 'react-redux';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  borderColor: 'red',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));

const AppNavPaperComponent = styled('div')(({ theme }) => {
  return {
    width: 'var(--MuiDocs-navDrawer-width)',
    boxShadow: 'none',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('xs')]: {
      borderRadius: '0px 10px 10px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: '0px',
    },
  };
});

const StyledLink = styled(Link)(({ theme }) => ({
  maxWidth: '52px',
  paddingRight: '12px',
  marginRight: '4px',
  textDecoration: 'none',
  borderRight: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#d8d8d8',
  color: theme.palette.text.primary,
}));

// iOS is hosted on high-end devices. We can enable the backdrop transition without
// dropping frames. The performance will be good enough.
// So: <SwipeableDrawer disableBackdropTransition={false} />
const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const AppNavDrawer = (props) => {
  const { client } = useClient();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const partner = useSelector((state) => state.partner.partner);

  const { className, mobileOpen, onClose, onOpen } = props;

  const handleClick = () => {
    onClose();
  };

  const drawer = React.useMemo(() => {
    return (
      <React.Fragment>
        <div className="drawer-nav__top">
          <StyledLink to="/">
            <Box onClick={onClose} aria-label={t('action.goToHome', 'Go to home')}>
              <img src="/assets/images/t1c-logo.png" width={30} />
            </Box>
            <Box
              sx={{
                fontSize: 8,
                textAlign: 'center',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              v {packageJSON.version}
            </Box>
          </StyledLink>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={(theme) => ({
                ml: 1,
                color: theme.palette.grey[600],
                fontSize: theme.typography.pxToRem(11),
                fontWeight: 700,
                textTransform: 'uppercase',
                letterSpacing: '.08rem',
              })}
            >
              {t('nav.title', 'Trust1Connector')}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={(theme) => ({
                  ml: 1,
                  color: theme.palette.grey[600],
                  fontSize: theme.typography.pxToRem(11),
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  letterSpacing: '.08rem',
                })}
              >
                {t('nav.subtitle', 'Read My Cards')}
              </Typography>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: (theme) =>
              theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#d8d8d8',
          }}
        />
        <List sx={{ my: 0.5 }}>
          <AppNavDrawerItem
            linkProps={{ className: 'subHeader__main-title' }}
            depth={0}
            onClick={handleClick}
            title={t('nav.mainNavigation', 'Main Navigation')}
            subheader={true}
          />
          <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.readers', 'Readers')}
            to={`/app/readers`}
            icon={`ModuleIcon`}
            subheader={false}
          />
          <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.truststore', 'Truststore')}
            to={`/app/truststore`}
            icon={`PasswordIcon`}
            subheader={false}
          />
          <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.smartid', 'Authentication')}
            to={`/smart-id`}
            icon={`BadgeIcon`}
            subheader={false}
          />
          <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.simplesign', 'SimpleSign')}
            to={`/app/simplesign`}
            icon={`HistoryEduIcon`}
            subheader={false}
          />
          <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.admin', 'Admin')}
            to={`/app/admin`}
            icon={`DashboardIcon`}
            subheader={false}
          />
          <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.fileExplorer', 'File Explorer')}
            to={`/app/file-explorer`}
            icon={`FolderIcon`}
            subheader={false}
          />
          {client.showContactPage && (
            <AppNavDrawerItem
              depth={0}
              onClick={handleClick}
              title={t('nav.page.contact', 'Contact')}
              to={`/contact`}
              icon={`ContactIcon`}
              subheader={false}
            />
          )}
          {/* <AppNavDrawerItem
            depth={0}
            onClick={handleClick}
            title={t('nav.page.authentications', 'Demo Mobile')}
            to={`/partners`}
            icon={`SmartphoneIcon`}
            subheader={false}
          /> */}
        </List>
      </React.Fragment>
    );
  }, [anchorEl, setAnchorEl, i18n.language]);

  return (
    <nav className={className} aria-label={t('field.mainNavigation', 'Main navigation')}>
      {mobile ? (
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          variant="temporary"
          open={mobileOpen}
          onOpen={onOpen}
          onClose={onClose}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            className: 'algolia-drawer nav-drawer__paper',
            component: AppNavPaperComponent,
          }}
        >
          <PersistScroll slot="swipeable" enabled={mobileOpen}>
            {drawer}
          </PersistScroll>
        </SwipeableDrawer>
      ) : null}
      {mobile ? null : (
        <StyledDrawer
          variant="permanent"
          PaperProps={{
            className: 'nav-drawer__paper',
            component: AppNavPaperComponent,
          }}
          open
        >
          <PersistScroll slot="side" enabled>
            {drawer}
          </PersistScroll>
        </StyledDrawer>
      )}
    </nav>
  );
};

AppNavDrawer.propTypes = {
  className: PropTypes.string,
  mobileOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default AppNavDrawer;
