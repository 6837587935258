import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Styled from './StepItem.style';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loading from 'components/Loading';

const StepItem = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { title, step, currentStep, icon, overrideDone, ...rest } = props;

  const stepStatus = () => {
    if (step < currentStep || (step === currentStep && overrideDone)) {
      return 'step-success';
    }
    if (step === currentStep) {
      return 'step-active';
    }
    if (step > currentStep) {
      return 'step-disabled';
    }
  };

  const isCurrent = () => {
    return step === currentStep;
  };
  const isSuccess = () => {
    if (overrideDone && isCurrent()) {
      return true;
    }
    return step < currentStep;
  };

  return (
    <Styled theme={theme}>
      <Box className={`step-item ${stepStatus()}`}>
        <Box sx={{ marginRight: '5px' }}>{icon}</Box>
        <Box sx={{ marginRight: 2 }}>{title}</Box>
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto',
          }}
        >
          {isSuccess() && (
            <>
              <CheckCircleIcon></CheckCircleIcon>
              Done
            </>
          )}
          {isCurrent() && !overrideDone && (
            <>
              <Loading></Loading>
            </>
          )}
        </Box>
      </Box>
    </Styled>
  );
};

export default StepItem;
