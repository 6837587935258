import React, { useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Alert, AlertTitle, Box, Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SmartIDService from 'infrastructure/services/SmartID';

const ValidationReportComponent = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  React.useEffect(() => {});

  const download = async () => {
    try {
      let report = await SmartIDService.getSessionReport(props.correlationId);
      const dataStr = JSON.stringify(report.data);
      const a = document.createElement('a');
      const file = new Blob([dataStr], { type: 'text/plain' });
      a.href = URL.createObjectURL(file);
      a.download = 'report.json';
      a.click();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack sx={{ marginY: '10px' }}>
      {error && (
        <Box>
          <Alert severity="warning">
            <AlertTitle>{t('smartid.error.title', 'Could not retrieve session information')}</AlertTitle>
            {t('smartid.error.body', 'The session result you are looking for could not be found')}
          </Alert>
        </Box>
      )}

      <Box>
        <Typography variant="h4">{t('smartid.result.validationreport.header', 'Validation report')}</Typography>
        <Typography variant="body2" sx={{ marginTop: '20px' }}>
          {t('smartid.result.validationreport.body', 'You can download your validation report with the button below')}
        </Typography>
        <Button
          onClick={download}
          sx={{ marginTop: '20px' }}
          color="primary"
          size="medium"
          variant="outlined"
          startIcon={<FileDownloadIcon />}
        >
          {t('action.download', 'Download')}
        </Button>
      </Box>
    </Stack>
  );
};

export default ValidationReportComponent;
