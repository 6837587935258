import { useConnector } from 'infrastructure/hooks';

const useReader = () => {
  const { client } = useConnector();

  const getAllCerts = (reader, module, pin, pinType) => {
    return new Promise((resolve) => {
      const c = client.generic(reader, pin, pinType);

      c.allCertsExtended(module, true).then(
        (res) => {
          resolve({ success: true, res: res.data });
        },
        (err) => {
          resolve({ success: false, res: err });
        },
      );
    });
  };

  const getAllData = (reader, module, pin, pinType) => {
    return new Promise((resolve) => {
      const c = client.generic(reader, pin, pinType);

      c.allData(module).then(
        (res) => {
          resolve({ success: true, res: res.data });
        },
        (err) => {
          resolve({ success: false, res: err });
        },
      );
    });
  };

  const getAllPaymentData = (reader, module) => {
    return new Promise((resolve, reject) => {
      const c = client.paymentGeneric(reader);

      c.readApplicationData(module).then(
        (res) => {
          c.readData(module).then(
            ({ data: { applications } }) => {
              if (!applications) {
                return resolve({ applicationData: res.data, applications: [] });
              }

              const totalApplications = applications.length;

              const issuerPublicCertificate = {};
              const ICCPublicCertificate = {};

              if (totalApplications === 0) {
                return resolve({ applicationData: res.data, applications });
              }

              applications.forEach(({ aid }) => {
                c.allCerts(module, aid).then(
                  (allCertsRes) => {
                    issuerPublicCertificate[aid] = allCertsRes.data.issuerPublicCertificate;
                    ICCPublicCertificate[aid] = allCertsRes.data.ICCPublicCertificate;

                    if (
                      Object.keys(issuerPublicCertificate).length === totalApplications &&
                      Object.keys(ICCPublicCertificate).length === totalApplications
                    ) {
                      resolve({
                        applicationData: res.data,
                        applications,
                        certificates: { issuerPublicCertificate, ICCPublicCertificate },
                      });
                    }
                  },
                  (err) => {
                    reject(err);
                  },
                );
              });
            },
            (err) => {
              reject(err);
            },
          );
        },
        (err) => {
          reject(err);
        },
      );
    });
  };

  return {
    getAllCerts,
    getAllData,
    getAllPaymentData,
  };
};

export default useReader;
