// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, addDoc, doc, getDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCEMkYGuUJN2g9wPXH3KPC263tbEHeAIJw',
  authDomain: 'wallid-591a4.firebaseapp.com',
  projectId: 'wallid-591a4',
  storageBucket: 'wallid-591a4.appspot.com',
  messagingSenderId: '626276332436',
  appId: '1:626276332436:web:a6054fdc6c6caeab231581',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const getFirebase = async () => {
  return app;
};

const getFireStore = () => {
  return getFirestore(app);
};

export default {
  getFirebase: getFirebase,
  getFireStore: getFireStore,
};
