import React from 'react';
import useClient from 'infrastructure/hooks/useClient';
import FullLoadingScreen from 'components/FullLoadingScreen';
import ConfigurationService from 'infrastructure/services/Configuration';

const GetClientConfigWrapper = (props) => {
  const { updateClient, getConnectorState } = useClient();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const fetchClientInformation = async () => {
    try {
      setLoading(true);

      const response = await ConfigurationService.getClientInformation();
      updateClient(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  React.useEffect(() => {
    fetchClientInformation();
  }, []);

  if (loading) {
    return <FullLoadingScreen title="Loading client configuration" />;
  }

  const { children } = props;

  return children;
};

export default GetClientConfigWrapper;
