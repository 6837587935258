import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dropzone from 'components/Dropzone';
import Dialog from 'components/dialogs/Dialog';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useFinderActions from '../../hooks/useFinderActions';

const UploadFileDialog = (props) => {
  const { t } = useTranslation();
  const [file, setFile] = React.useState();
  const { notifyError } = useErrorNotify();
  const { enqueueSnackbar } = useSnackbar();
  const { loader, uploadFile, fetchDirectoryContent } = useFinderActions();

  const { open, onClose } = props;

  const onFileAccepted = (file) => {
    setFile(file);
  };

  const onConfirm = async () => {
    try {
      if (!file) {
        return enqueueSnackbar(
          t('notification.error.fileExchangeFileUploadedIsRequired', 'Please upload a file before submitting'),
          {
            variant: 'error',
          },
        );
      }

      await uploadFile(file);
      fetchDirectoryContent();
      onClose();
    } catch (err) {
      notifyError(err);
    }
  };

  return (
    <Dialog max="600px" open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <DialogTitle>
          <FileUploadIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
          {t('fileExplorer.dialogs.uploadFile.title', 'Upload file')}
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        <Dropzone onFileAccepted={onFileAccepted} />
      </DialogContent>
      <DialogFooter>
        <Stack direction="row" spacing={1}>
          <Button size="small" onClick={onClose}>
            {t('action.cancel', 'Cancel')}
          </Button>
          <LoadingButton
            loading={loader?.uploadFile}
            color="primary"
            size="small"
            variant="contained"
            onClick={onConfirm}
          >
            {t('action.confirm', 'Confirm')}
          </LoadingButton>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
};

UploadFileDialog.defaultProps = {
  open: false,
  onClose: () => false,
  onSubmit: () => false,
};

export default UploadFileDialog;
