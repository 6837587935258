import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';

const CardFront = () => {
  const biometric = useSelector((state) => state.card.biometric);
  const picture = useSelector((state) => state.card.picture);

  return (
    <div className="front">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <img className="top-icon-shape" src="/assets/images/lux-id-shape.svg" />
        <Stack direction="column" alignItems="center">
          <div className="title-top">Grand-Duché de</div>
          <div className="title-middle">LUXEMBOURG</div>
          <div className="title-bottom">Carte d'Identité</div>
        </Stack>
        <Stack direction="column" alignItems="center">
          <div className="title-top">Grand Duchy of</div>
          <div className="title-middle">LUXEMBOURG</div>
          <div className="title-bottom">Identity Card</div>
        </Stack>

        <Stack direction="column" alignItems="center">
          <div className="title-top">Grossherzogtum</div>
          <div className="title-middle">LUXEMBURG</div>
          <div className="title-bottom">Personalausweis</div>
        </Stack>

        <Stack direction="column" alignItems="center">
          <div className="title-middle">LETZEBUERG</div>
        </Stack>
      </Stack>

      <div className="card-content">
        <div className="card-content__left">
          <img
            className="profile-picture"
            src={`data:image/jpg;base64,${picture?.picture}`}
            alt={`${biometric?.name} ${biometric?.firstNames}`}
          />

          <div className="field__label small">Signature du situlaire / holder's signature</div>
        </div>
        <div className="card-content__middle">
          <div className="field field-name">
            <div className="field__label">Nom / Name</div>
            <div className="field__value">{biometric?.name}</div>
          </div>
          <div className="field">
            <div className="field__label">Prénoms / Given names</div>
            <div className="field__value">{biometric?.firstNames}</div>
          </div>
          <div className="field-row">
            <div className="field">
              <div className="field__label">Sexe / Sex</div>
              <div className="field__value">{biometric?.sex}</div>
            </div>
            <div className="field">
              <div className="field__label">Nationalité / Nationality</div>
              <div className="field__value">{biometric?.nationality.toUpperCase()}</div>
            </div>
          </div>
          <div className="field">
            <div className="field__label">Date de naissance / Date of birth</div>
            <div className="field__value">{biometric?.birthDate}</div>
          </div>
          <div className="field">
            <div className="field__label">N Carte D'Identité / Identity Card NR</div>
            <div className="field__value">{biometric?.chipNumber}</div>
          </div>
          <div className="field">
            <div className="field__label">Valide Jusqu'au / Valid until</div>
            <div className="field__value">{biometric?.cardValidityDateEnd}</div>
          </div>
        </div>
        <div className="card-content__right">
          <img className="crown-image" src="/assets/images/lux-flag-crown.png" alt="crown flag" />
        </div>
      </div>
    </div>
  );
};

export default CardFront;
