const isRequired = (value) => (value ? undefined : 'Required');
const isValidEmail = (value) =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ? undefined : 'Please enter a valid email';

const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export default {
  composeValidators,
  isRequired,
  isValidEmail,
};
