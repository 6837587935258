import Box from '@mui/material/Box';
import { Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import React from 'react';

const Step2 = (props) => {
  const { downloadLink, stepCompleted } = props;

  const downloadBlob = (blob, name = 'file.txt') => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );

    // Remove link from body
    document.body.removeChild(link);
  };

  React.useEffect(() => {
    setTimeout(() => {
      downloadBlob(downloadLink, downloadLink.name);
    }, 1500);
  }, []);
  return (
    <Box sx={{ minWidth: 300, minHeight: 150, p: 2, textAlign: 'center' }}>
      <Typography>
        <Trans i18nKey="uploadAndSignDocumentAction.stepper.step2.title">
          You have successfully signed your document. Your download will start automatically.
        </Trans>
      </Typography>

      <Button sx={{ mt: 2 }} color="secondary" onClick={stepCompleted} size="small">
        <Trans i18nKey="action.close">Close</Trans>
      </Button>
    </Box>
  );
};

export default Step2;
