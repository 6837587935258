import axios from 'axios';

const request = async (method, url, data, headers) => {
  let response;

  if (method === 'get') {
    response = await axios.request({
      url,
      method,
      params: data,
      validateStatus: (status) => status >= 200 && status < 500,
      headers,
    });
  } else {
    response = await axios.request({
      url,
      method,
      data,
      validateStatus: (status) => status >= 200 && status < 500,
      headers,
    });
  }

  return response;
};

export default {
  get: (endpoint, data, headers = {}) => request('get', endpoint, data, headers),
  post: (endpoint, data, headers = {}) => request('post', endpoint, data, headers),
  put: (endpoint, data, headers = {}) => request('put', endpoint, data, headers),
  del: (endpoint, data, headers = {}) => request('delete', endpoint, data, headers),
};
