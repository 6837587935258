import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Skeleton from '@mui/material/Skeleton';
import ValidationService from 'infrastructure/services/Validation';
import CertificateDetailDialog from './components/CertificateDetailDialog';

const ValidateCertificates = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();
  const [expired, setExpired] = React.useState();
  const [qualified, setQualified] = React.useState();
  const [breakdown, setBreakdown] = React.useState();

  const rootCertificate = useSelector((state) => state.card.rootCertificate);
  const authenticationCertificate = useSelector((state) => state.card.authenticationCertificate);
  const nonRepudiationCertificate = useSelector((state) => state.card.nonRepudiationCertificate);
  const intermediateCertificates = useSelector((state) => state.card.intermediateCertificates);

  const composeCertificates = () => {
    const certificates = [];

    if (authenticationCertificate?.certificates?.length > 0) {
      authenticationCertificate.certificates.forEach((cert) => certificates.push(cert.certificate));
    } else if (nonRepudiationCertificate?.certificates?.length > 0) {
      nonRepudiationCertificate.certificates.forEach((cert) => certificates.push(cert.certificate));
    }

    if (intermediateCertificates?.certificates) {
      intermediateCertificates.certificates.forEach((cert) => certificates.push(cert.certificate));
    } else if (intermediateCertificates?.certificate) {
      certificates.push(intermediateCertificates.certificate);
    }

    if (rootCertificate?.certificates) {
      rootCertificate.certificates.forEach((cert) => certificates.push(cert.certificate));
    } else if (rootCertificate?.certificate) {
      certificates.push(rootCertificate.certificate);
    }

    return certificates;
  };

  const validateChain = async () => {
    try {
      setLoading(true);

      const certificates = composeCertificates();

      const response = await ValidationService.validateChain({ body: { certificates } });

      setExpired(false);
      setQualified(true);
      setBreakdown(response.certificateChainValidationBreakDown.breakdown);

      setLoading(false);
    } catch (err) {
      setExpired(false);
      setQualified(true);
      setBreakdown();
      setLoading(false);
      // setError(err);
    }
  };

  const severity = React.useMemo(() => {
    if (qualified && !expired) {
      return 'success';
    } else if (!qualified && !expired) {
      return 'warning';
    }
    return 'error';
  }, [expired, qualified]);

  const title = React.useMemo(() => {
    if (qualified && !expired) {
      return t('notification.success.validatedCertificateChain', 'Successfully validated certificate chain');
    } else if (!qualified && !expired) {
      return t('notification.warning.validatedCertificateChain', 'Certificate chain validation warning');
    }
    return t('notification.error.validatedCertificateChain', 'Certificate chain is not valid');
  }, [expired, qualified]);

  const description = React.useMemo(
    () => `Certificates are ${expired ? 'expired' : 'not expired'} and ${qualified ? 'qualified' : 'not qualified'}.`,
    [expired, qualified],
  );

  React.useEffect(() => {
    validateChain();
  }, []);

  if (loading) {
    return (
      <Box sx={{ mb: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={60} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mb: 2 }}>
        <Alert severity="error">
          {t(
            'notification.error.notAbleToValidateCertificates',
            'Something went wrong while validating the certificate chain',
          )}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Alert severity={severity} action={<CertificateDetailDialog breakdown={breakdown} />}>
        <AlertTitle>{title}</AlertTitle>
        {description}
      </Alert>
    </Box>
  );
};

export default ValidateCertificates;
