import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Styled from './Step1Content.style';
import { useTheme } from '@mui/material/styles';
import { Button, Chip, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Installer from 'components/Installation/components/Installer';
import SvgWindows from 'components/icons/SvgWindows';
import SvgMacOS from 'components/icons/SvgMacOS';
import SimpleSignService from 'infrastructure/services/SimpleSign';

const Step1Content = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [info, setInfo] = useState();

  useEffect(() => {
    const getSimpleSignInfo = async () => {
      try {
        let info = await SimpleSignService.getSimpleSignInfo();
        setInfo(info.data);
      } catch (error) {
        setInfo();
      }
    };
    let interval = setInterval(getSimpleSignInfo, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  let windows64 = window._env_.REACT_APP_SS_WIN64;
  let macos = window._env_.REACT_APP_SS_MACOS;
  let macosarm = window._env_.REACT_APP_SS_MACOS_ARM;

  const windowsList = [
    {
      os: 'WIN64',
      uri: windows64,
    },
  ];
  const macOSList = [
    {
      os: 'MACOSARM',
      uri: macosarm,
    },
    {
      os: 'MACOS',
      uri: macos,
    },
  ];

  const continueClick = () => {
    props.next(info);
  };
  return (
    <Styled theme={theme}>
      <Box className="">
        <Stack spacing={2}>
          <Box>
            <Typography variant="h2">{t('simplesign.content1.title', 'SimpleSign application')}</Typography>
            <Typography variant="p">
              {t(
                'simplesign.content1.caption',
                'You can download the simple sign application via the link below. When you are finished with installing you can continue to the next step.',
              )}
            </Typography>
          </Box>
          <Box>
            {!info && (
              <Grid sx={{ mt: 2 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Installer
                    icon={<SvgWindows fill={theme.palette.secondary.main} />}
                    title={t('connector.installers.windows.title', 'Windows')}
                    osList={windowsList}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Installer
                    icon={<SvgMacOS fill={theme.palette.secondary.main} />}
                    title={t('connector.installers.macOS.title', 'MacOS')}
                    osList={macOSList}
                  />
                </Grid>
              </Grid>
            )}

            {info && (
              <Stack>
                <Typography variant="p">
                  {t(
                    'simplesign.content1.simplesignversion',
                    'SimpleSign was detected, you can now continue to the next step',
                  )}
                </Typography>
                <Box sx={{ marginTop: 1 }}>
                  <Chip sx={{ marginRight: 1 }} label={`v${info.version}`} />
                  <Chip label={`${info.localFolder}`} />
                </Box>
              </Stack>
            )}
          </Box>
          <Box>
            <Button
              disabled={!info}
              sx={{ marginTop: 2 }}
              color="primary"
              size="medium"
              variant="contained"
              startIcon={<NavigateNextIcon />}
              onClick={continueClick}
            >
              {t('simplesign.content1.next', 'Continue')}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Styled>
  );
};

export default Step1Content;
