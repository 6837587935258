class HttpError extends Error {
  constructor(response) {
    super((response.data?.description ? response.data.description : response.statusText) || response.message);
    this.name = this.constructor.name;
    this.description = response.data?.description || response.response?.data?.description || null;
    this.statusText = response.statusText;
    this.statusCode = response.status;
    this.apiMethod = response.config.method;
    this.baseURL = response.config.baseURL;
    this.apiUrl = response.config.url;
  }
}

export default HttpError;
