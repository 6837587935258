import React from 'react';
import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const Card = () => {
  const applications = useSelector((state) => state.card.applications);

  return (
    <Paper variant="block">
      <Typography sx={{ mb: 2 }} variant="h5">
        <Trans i18nKey="field.applicationDetails">Application details</Trans>
      </Typography>

      {!applications ||
        (applications?.length === 0 && (
          <Typography>
            <Trans i18nKey="templates.applications.nothingFound">No applications found</Trans>
          </Typography>
        ))}

      {applications.map((app) => (
        <Box key={app.aid} sx={{ '&:not(:last-child)': { mb: 2 } }}>
          <Typography sx={{ mb: 0.5 }} color="secondary" variant="subtitle1">
            {app.name}
          </Typography>

          <Stack direction="row">
            <Box>
              <Typography variant="overline">
                <Trans i18nKey="field.aid">Aid</Trans>
              </Typography>
              <Typography variant="body2">{app.aid}</Typography>
            </Box>
            <Box sx={{ ml: 4 }}>
              <Typography variant="overline">
                <Trans i18nKey="field.priority">Priority</Trans>
              </Typography>
              <Typography variant="body2">{app.priority}</Typography>
            </Box>
          </Stack>
        </Box>
      ))}
    </Paper>
  );
};

export default Card;
