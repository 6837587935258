import React, { useState, createContext } from 'react';

export const finderContext = createContext();

const FinderProvider = (props) => {
  const [entity, setEntity] = useState(props.entity);
  const [type, setType] = useState();
  const [types, setTypes] = React.useState([]);
  const [relPaths, setRelPaths] = useState([]);
  const [content, setContent] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(props?.currentLocation || {});
  const [selectedRelPaths, setSelectedRelPaths] = React.useState([]);
  const [selectable, setSelectable] = useState(props.selectable);
  const [editable, setEditable] = useState(props.editable);

  const value = {
    entity,
    setEntity,
    type,
    setType,
    relPaths,
    setRelPaths,
    content,
    setContent,
    types,
    setTypes,
    selectable,
    setSelectable,
    editable,
    setEditable,
    selectedRelPaths,
    setSelectedRelPaths,
    currentLocation,
    setCurrentLocation,
  };

  return <finderContext.Provider value={{ ...value }}>{props.children}</finderContext.Provider>;
};

export default FinderProvider;
