import React from 'react';
import { Trans } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Styled from './AppFooter.style';

const AppFooter = () => {
  const theme = useTheme();

  return (
    <Styled theme={theme}>
      <Divider />
      <div className="app-footer__inner">
        <Typography
          className="app-footer__inner__text"
          align="center"
          component="div"
          id="feedback-message"
          variant="body2"
        >
          <Trans i18nKey="appFooter.license">
            This service is provided free of charge, and is intended for private use only. No personal info is saved
            when using this service.
          </Trans>
        </Typography>
        <Typography
          className="app-footer__inner__text"
          align="center"
          component="div"
          id="feedback-message"
          variant="body2"
        >
          <Trans i18nKey="appFooter.copyright">
            Copyright {{ year: new Date().getFullYear() }} <Link href="https://www.trust1team.com/">Trust1Team</Link> -
            All rights reserved
          </Trans>
        </Typography>
      </div>
    </Styled>
  );
};

export default AppFooter;
