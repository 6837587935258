import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const Styled = styled(Box)`
  .dialog__stepper {
    padding: 25px 15px 20px 15px;
  }
`;

export default Styled;
