import React, { useState, createContext, useContext } from 'react';

const RMC_KEY = 'rmc';

const clientContext = createContext();
const defaultClient = {
  showContactPage: true,
  connector: {
    url: window._env_.REACT_APP_T1C_URL,
    port: window._env_.REACT_APP_T1C_PORT,
  },
};

const getState = () => {
  const state = localStorage.getItem(RMC_KEY);
  if (!state) {
    return null;
  } else {
    return JSON.parse(state);
  }
};

const setState = (data) => {
  localStorage.setItem(RMC_KEY, JSON.stringify(data));
};

// Provider that keeps track of the T1C client configuration
export const ClientProvider = ({ children }) => {
  let state = getState();
  let c = defaultClient;
  if (state) {
    c = state;
  }
  const [client, setClient] = useState(c);

  return <clientContext.Provider value={{ client, setClient }}>{children}</clientContext.Provider>;
};

const useClient = () => {
  const { client, setClient } = useContext(clientContext);

  const updateClient = (c) => {
    setState(c);
    setClient(c);
  };

  const setConnectorState = (s) => {
    let updated = client;
    updated.connector = s;
    updateClient(updated);
  };

  const resetConnectorState = (s) => {
    let updated = client;
    updated.connector = defaultClient.connector;
    updateClient(updated);
  };

  const getConnectorState = () => {
    return client.connector;
  };

  return {
    client,
    setClient,
    updateClient,
    getConnectorState,
    setConnectorState,
    resetConnectorState,
  };
};

export default useClient;
