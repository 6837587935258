import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Certificate from 'components/Certificate';

const NoCertificatesFound = () => {
  const { t } = useTranslation();

  return <Typography>{t('templates.certificates.nothingFound', 'No certificates found')}</Typography>;
};

const Certificates = () => {
  const { t } = useTranslation();
  const applications = useSelector((state) => state.card.applications);
  const issuerPublicCertificate = useSelector((state) => state.card.issuerPublicCertificate);
  const ICCPublicCertificate = useSelector((state) => state.card.ICCPublicCertificate);

  return (
    <Paper variant="block">
      <Typography sx={{ mb: 2 }} variant="h5">
        {t('template.certificates.title', 'Certificate details')}
      </Typography>

      {!applications || (applications?.length === 0 && <NoCertificatesFound />)}

      {applications.map((app) => (
        <Box key={app.aid} sx={{ '&:not(:last-child)': { mb: 3 } }}>
          <Typography sx={{ mb: 0.5 }} color="secondary" variant="subtitle1">
            {app.name}
          </Typography>

          <Certificate
            sx={{ mb: 1 }}
            title={t('template.certificates.ICCPublicCertificate', 'ICC public certificate')}
            certificates={[ICCPublicCertificate[app.aid]]}
          />
          <Certificate
            sx={{ mb: 0.5 }}
            title={t('template.certificates.issuerPublicCertificate', 'Issuer public certificate')}
            certificates={[issuerPublicCertificate[app.aid]]}
          />
        </Box>
      ))}
    </Paper>
  );
};

export default Certificates;
