import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Chip, Divider, List, ListItem, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CorsInfo = (props) => {
  const { t, i18n } = useTranslation();

  const renderCors = () => {
    return props.info.t1CInfoAPI.cors.map((cors) => {
      return <Chip key={cors} sx={{ mr: 0.5, mb: 0.5 }} label={cors} />;
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Stack sx={{ mt: 0.5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('page.admin.title.corsInfo', 'CORS information')}</Typography>
      </Stack>
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
          <Typography>{t('page.admin.corslist', 'CORS list')}</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderCors()}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CorsInfo;
