import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DefaultPaymentCard from 'components/generic/cards/DefaultPaymentCard';
import Applications from './components/Applications';
import Certificates from './components/Certificates';
import Actions from './components/Actions';

const GenericPayment = ({ cardHeader }) => (
  <Box>
    <Grid container spacing={2} sx={{ mb: 4 }}>
      <Grid item xs={12} md="auto">
        {React.cloneElement(cardHeader)}
      </Grid>
      <Grid item xs={12} md>
        <Applications />
      </Grid>
    </Grid>

    <Certificates />

    <Actions />
  </Box>
);

GenericPayment.defaultProps = {
  cardHeader: <DefaultPaymentCard />,
};

export default GenericPayment;
