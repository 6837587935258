import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper, Skeleton, Stack } from '@mui/material';
import { useConnector } from 'infrastructure/hooks';
import LoopIcon from '@mui/icons-material/Loop';
import Button from '@mui/material/Button';
import LibraryConfiguration from './components/LibraryConfiguration';
import Loading from 'components/Loading';
import UpdateLibraryConfiguration from './components/UpdateLibraryConfiguration';

const Pkcs11Config = (props) => {
  const { t, i18n } = useTranslation();
  const { client } = useConnector();
  const [loading, setLoading] = React.useState(true);
  const [pkcs11Config, setPkcs11Config] = React.useState(null);

  const onSubmit = (values) => {
    setLoading(true);
    client
      .pkcs11(null)
      .setConfig(values)
      .then(
        (res) => {
          setPkcs11Config(res);
          fetchData();
        },
        (err) => {
          console.error(err);
        },
      );
    setLoading(false);
  };

  const fetchData = () => {
    setLoading(true);
    client
      .pkcs11(null)
      .getConfig()
      .then(
        (res) => {
          setPkcs11Config(res);
        },
        (err) => {
          console.error(err);
        },
      );
    setLoading(false);
  };

  const reset = () => {
    setLoading(true);
    client
      .pkcs11(null)
      .resetConfig()
      .then(
        (res) => {
          fetchData();
        },
        (err) => {
          console.error(err);
        },
      );
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ mb: 2 }}>
      <Stack sx={{ mt: 0.5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('page.admin.title.pkcs11config', 'Pkcs11 Configuration')}</Typography>
        <Box>
          <Button color="secondary" size="small" variant="outlined" startIcon={<LoopIcon />} onClick={reset}>
            {t('action.reset', 'Reset')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            color="primary"
            size="small"
            variant="contained"
            startIcon={<LoopIcon />}
            onClick={fetchData}
          >
            {t('action.refresh', 'Refresh')}
          </Button>
        </Box>
      </Stack>

      <UpdateLibraryConfiguration onSubmit={onSubmit}></UpdateLibraryConfiguration>

      {loading && <Loading></Loading>}
      {!loading && <LibraryConfiguration config={pkcs11Config?.data}></LibraryConfiguration>}
    </Box>
  );
};

export default Pkcs11Config;
