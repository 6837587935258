import styled from '@emotion/styled';

const Styled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    text-align: center;
  }

  .title {
    display: block;
    margin-top: 10px;
  }
`;

export default Styled;
