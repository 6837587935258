import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';

const IsPinPadReader = ({ no }) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    const toolTipText = no
      ? t('tooltip.noPinPadReader', 'This is not a pinpad reader')
      : t('tooltip.pinpadReader', 'This is a pinpad reader');
    const iconColor = no ? 'error' : 'success';

    return (
      <Tooltip title={toolTipText}>
        <KeyboardAltIcon color={iconColor} />
      </Tooltip>
    );
  });
};
IsPinPadReader.defaultProps = {
  no: false,
};

export default IsPinPadReader;
