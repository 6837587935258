import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import Dialog from 'components/dialogs/Dialog';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Styled from './TruststoreStepperDialog.style';

const steps = ['Sign', 'Download'];

const TruststoreStepperDialog = (props) => {
  const { t } = useTranslation();
  const [downloadLink, setDownloadLink] = React.useState();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [preventInitialTrigger, setPreventInitialTrigger] = React.useState(false);
  const [keyboardTab, setKeyboardTab] = React.useState('numpad');

  const { open, onClose, signResponse } = props;

  const handleClose = () => {
    onClose();
    // cleanup the state here, doing it somewhere else will cause a re-render potentially triggering the os pin dialog.
    setPreventInitialTrigger(true);
    setCurrentStep(0);
  };

  React.useEffect(() => {
    setPreventInitialTrigger(false);

    return () => {
      if (open === false) {
        setKeyboardTab('numpad');
      }
    };
  }, [open]);

  const nextStep = () => setCurrentStep(currentStep + 1);

  const dataURLtoFile = (dataurl, filename) => {
    let bstr = atob(dataurl);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename);
  };

  const onStep1Completed = React.useCallback((response) => {
    const data = dataURLtoFile(response.bytes, response.originalDocumentName);
    setDownloadLink(data);
    setPreventInitialTrigger(false);
    nextStep();
  }, []);

  const handleStep = (stepIndex) => {
    setCurrentStep(stepIndex);
    setPreventInitialTrigger(false);
  };

  const isStepDisabled = (stepIndex) => currentStep < stepIndex;
  const isStepCompleted = (stepIndex) => currentStep > stepIndex;

  const renderStepContent = () => {
    if (currentStep === 1) {
      return <Step2 stepCompleted={handleClose} downloadLink={downloadLink} />;
    }

    return (
      <Step1
        preventInitialTrigger={preventInitialTrigger}
        keyboardTab={keyboardTab}
        onKeyboardTabChanged={setKeyboardTab}
        stepCompleted={onStep1Completed}
        signResponse={signResponse}
      />
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} max={keyboardTab === 'numpad' ? `350px` : `500px`}>
      <Styled>
        <Box className="dialog__stepper" sx={{ width: '100%' }}>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} index={index} completed={isStepCompleted(index)} disabled={isStepDisabled(index)}>
                <StepButton color="inherit" onClick={() => handleStep(index)}>
                  <StepLabel>{t(`uploadAndSignDocumentAction.stepper.step${index + 1}.stepTitle`)}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Divider />

        <Box className="dialog__content">{renderStepContent()}</Box>
      </Styled>
    </Dialog>
  );
};

TruststoreStepperDialog.defaultProps = {
  open: false,
  signResponse: {},
  onClose: () => false,
};

export default TruststoreStepperDialog;
