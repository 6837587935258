import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  /*Ratio is 8/5*/
  width: 300px;
  height: 187.5px;
  padding: 20px 20px 20px 25px;
  border: 1px solid lightgray;
  border-radius: 15px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(160,174,192, 1)' : 'rgb(226, 232, 240)'};
  color: rgba(45, 55, 72, 1);
  display: flex;
  align-items: center;

  .chip {
    width: 45px;
    margin-right: 20px;
  }

  .logo {
    width: 175px;
    fill: #430099;
  }
`,
);

export default Styled;
