import Api from 'utils/Api';
import HttpError from 'utils/errors/HttpError';
import SyntaxError from 'utils/errors/SyntaxError';

const getClientInformation = async () => {
  try {
    const origin = window.location.hostname;

    const response = await Api.get(`${window._env_.REACT_APP_BFF_URL}/v1/configuration/client`, { origin });

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

export default {
  getClientInformation,
};
