import React from 'react';

const TriggerDialogAction = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (btnProps) => {
    if (btnProps.onClick) {
      btnProps.onClick();
    }

    setOpen(true);
  };

  const handleClose = (dialogProps) => {
    if (dialogProps.onClose) {
      dialogProps.onClose();
    }

    setOpen(false);
  };

  const { triggerButton, dialog } = props;

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: () => handleClick(triggerButton.props) })}
      {React.cloneElement(dialog, { open, onClose: () => handleClose(dialog.props) })}
    </>
  );
};

export default TriggerDialogAction;
