import React from 'react';
import Keyboard from 'react-simple-keyboard';

const MobileKeyboard = (props) => {
  const [layoutName, setLayoutName] = React.useState('default');

  const { onChange, onKeyPress, ...rest } = props;

  const handleLayoutChange = (button) => {
    switch (button) {
      case '{shift}':
      case '{shiftactivated}':
      case '{default}':
        setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        break;

      case '{alt}':
      case '{altright}':
        setLayoutName(layoutName === 'alt' ? 'default' : 'alt');
        break;

      case '{smileys}':
        setLayoutName(layoutName === 'smileys' ? 'default' : 'smileys');
        break;

      default:
        break;
    }
  };

  const handleKeyPress = (button) => {
    onKeyPress(button);

    if (button.includes('{') && button.includes('}')) {
      handleLayoutChange(button);
    }
  };

  return (
    <Keyboard
      layoutName={layoutName}
      theme={'hg-theme-default hg-theme-ios myTheme1 mobile-keyboard'}
      physicalKeyboardHighlight
      syncInstanceInputs
      onChange={onChange}
      onKeyPress={handleKeyPress}
      display={{
        '{alt}': '.?123',
        '{smileys}': '\uD83D\uDE03',
        '{shift}': '⇧',
        '{shiftactivated}': '⇧',
        '{return}': 'return',
        '{enter}': 'OK',
        '{bksp}': '⌫',
        '{altright}': '.?123',
        '{downkeyboard}': '🞃',
        '{space}': ' ',
        '{default}': 'ABC',
      }}
      layout={{
        default: [
          'q w e r t y u i o p {bksp}',
          'a s d f g h j k l {return}',
          '{shift} z x c v b n m , . {shift}',
          '{alt} {smileys} {space} {enter}',
        ],
        shift: [
          'Q W E R T Y U I O P {bksp}',
          'A S D F G H J K L {return}',
          '{shiftactivated} Z X C V B N M , . {shiftactivated}',
          '{alt} {smileys} {space} {enter}',
        ],
        alt: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          `@ # $ & * ( ) ' " {return}`,
          '{shift} % - + = / ; : ! ? {shift}',
          '{default} {smileys} {space} {enter}',
        ],
        smileys: [
          '😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}',
          `😏 😬 😭 😓 😱 😪 😬 😴 😯 {return}`,
          '😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}',
          '{default} {smileys} {space} {enter}',
        ],
      }}
      buttonTheme={[
        { class: 'red', buttons: '{bksp}' },
        { class: 'green', buttons: '{enter}' },
      ]}
      {...rest}
    />
  );
};

export default MobileKeyboard;
