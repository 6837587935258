import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import useConnector from 'infrastructure/hooks/useConnector';
import useClient from 'infrastructure/hooks/useClient';
import { DistributionService } from 'infrastructure/services';
import { setSkipVersion, setModules } from 'infrastructure/reducers/config';
import Installation from 'components/Installation';
import Loading from 'components/Loading';
import { AppContainer } from 'components/Layouts';
import ErrorBlock from 'components/ErrorBlock';
import { useErrorNotify } from 'infrastructure/hooks';

const GiveConsent = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { notifyError } = useErrorNotify();
  const { t } = useTranslation();
  const skippedVersion = useSelector((state) => state.config.skipVersion);
  const { client: c } = useClient();
  const { client } = useConnector();
  const { getConnectorState } = useClient();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [version, setVersion] = React.useState();

  const checkVersion = () => {
    try {
      setLoading(true);

      client
        .core()
        .info()
        .then(async (info) => {
          dispatch(setModules(info?.t1CInfoAPI?.modules || []));
          try {
            const latestVersion = c.connectorVersions.find((v) => v.latest);

            if (info.t1CInfoAPI.version < latestVersion.version) {
              const current = info.t1CInfoAPI.version;

              if (latestVersion.mandatory) {
                setVersion({ type: 'mandatory', new: latestVersion.version, current, uris: latestVersion.uris });
              } else if (latestVersion.recommended) {
                setVersion({ type: 'recommended', new: latestVersion.version, current, uris: latestVersion.uris });
              } else if (latestVersion.allowed) {
                setVersion({ type: 'allowed', new: latestVersion.version, current, uris: latestVersion.uris });
              }
            }
            setError(null);
            setLoading(false);
          } catch (err) {
            setLoading(false);
            // setError(err.message);
            setError(null);
            notifyError({
              message: t(
                'errorBlock.connector.checkVersion.subtitleDescription',
                'Something went wrong while checking the connector version, please try again later or contact support.',
              ),
            });
          }
        });
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const skipVersion = () => {
    dispatch(setSkipVersion(true));
  };

  React.useEffect(() => {
    checkVersion();
  }, []);

  if (loading) {
    return (
      <AppContainer>
        <Loading />
      </AppContainer>
    );
  }

  if (error) {
    return (
      <AppContainer>
        <ErrorBlock
          title={t('errorBlock.connector.checkVersion.title', 'Something went wrong')}
          subtitle={t(
            'errorBlock.connector.checkVersion.subtitleDescription',
            'Something went wrong while checking the connector version, please try again later or contact support.',
          )}
          actions={
            <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={checkVersion}>
              {t('errorBlock.connector.checkVersion.button', 'Try again')}
            </Button>
          }
        />
      </AppContainer>
    );
  }

  // VersionCheck

  if (version?.new && version?.type && version?.current) {
    // When the new version isn't mandatory and skipped version is true, continue the rendering of the tree.
    if (version.type !== 'mandatory' && skippedVersion) {
      return children;
    }

    return (
      <AppContainer>
        <Paper variant="block" elevation={2}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="h2">
              {t('connector.outdatedVersion.title', 'Your connector version is outdated!')}
            </Typography>
            {version.type === 'mandatory' && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                <Trans i18nKey="connector.outdatedVersion.mandatoryBody">
                  New version available <b>{{ newVersion: version.new }}</b>, you have{' '}
                  <b>{{ currentVersion: version.current }}</b>, this version is{' '}
                  <Box sx={{ color: theme.palette.error.main }} component="b">
                    mandatory
                  </Box>
                </Trans>
              </Typography>
            )}
            {version.type === 'recommended' && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                <Trans i18nKey="connector.outdatedVersion.recommendedBody">
                  New version available <b>{{ newVersion: version.new }}</b>, you have{' '}
                  <b>{{ currentVersion: version.current }}</b>, this version is{' '}
                  <Box sx={{ color: theme.palette.success.main }} component="b">
                    recommended
                  </Box>
                </Trans>
              </Typography>
            )}
            {version.type === 'allowed' && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                <Trans i18nKey="connector.outdatedVersion.allowedBody">
                  New version available <b>{{ newVersion: version.new }}</b>, you have{' '}
                  <b>{{ currentVersion: version.current }}</b>
                </Trans>
              </Typography>
            )}

            {version.type !== 'mandatory' && (
              <Button
                sx={{ mt: 2 }}
                color="primary"
                size="medium"
                variant="outlined"
                startIcon={<SkipNextIcon />}
                onClick={skipVersion}
              >
                {t('action.skipThisVersion', 'Skip this version')}
              </Button>
            )}
          </Box>
        </Paper>
        <Box sx={{ mt: 2 }}>
          <Installation uris={version.uris} newVersion={version.new} />
        </Box>
      </AppContainer>
    );
  }

  return children;
};

export default GiveConsent;
