import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useErrorNotify = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getErrorMessage = (err) => {
    if (err?.code && err?.description) {
      return `Error ${err.code}: ${err.description}`;
    }

    if (err?.message) {
      return `Error: ${err.message}`;
    }

    return t('notification.error.default', 'Error: Something went wrong');
  };

  const notifyError = (err, variant = 'error') => {
    const message = getErrorMessage(err);
    enqueueSnackbar(message, { variant });
  };

  return {
    getErrorMessage,
    notifyError,
  };
};

export default useErrorNotify;
