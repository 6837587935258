import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import configReducer from 'infrastructure/reducers/config';
import authenticationReducer from 'infrastructure/reducers/authentication';
import partnerReducer from 'infrastructure/reducers/partner';
import cardReducer from 'infrastructure/reducers/card';
import fileExchangeReducer from 'infrastructure/reducers/fileExchange';
import truststoreReducer from 'infrastructure/reducers/truststore';

export const rootPersistConfig = {
  key: 'root',
  blacklist: [],
  storage,
};

const createRootReducer = () =>
  combineReducers({
    config: configReducer,
    authentication: authenticationReducer,
    partner: partnerReducer,
    card: cardReducer,
    truststore: truststoreReducer,
    fileExchange: fileExchangeReducer,
  });

export default createRootReducer;
