import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

const Styled = styled.div(
  ({ theme }) => `

  .header-box {
    width: 100%;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    background-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

`,
);

export default Styled;
