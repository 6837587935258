import Api from 'utils/Api';
import HttpError from 'utils/errors/HttpError';
import SyntaxError from 'utils/errors/SyntaxError';

const uploadFile = async ({ body = {} } = {}) => {
  try {
    const { file, nonRepudiationCertificate, certificateChain, algorithm } = body;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('signingCertificate', nonRepudiationCertificate);
    for (let cert of certificateChain) {
      formData.append('certificateChain', cert);
    }
    formData.append('algorithm', algorithm);

    const response = await Api.post(`${window._env_.REACT_APP_BFF_URL}/v1/sign/upload`, formData, {
      'Content-Type': 'multipart/form-data',
    });

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const sign = async ({ body = {} } = {}) => {
  try {
    const {
      signingCertificate,
      certificateChain,
      digestAlgorithm,
      bytesToSign,
      signedBytes,
      signatureDate,
      documentName,
    } = body;

    const data = {
      signingCertificate,
      certificateChain,
      digestAlgorithm,
      bytesToSign,
      signedBytes,
      documentName,
      signatureDate,
    };

    const response = await Api.post(`${window._env_.REACT_APP_BFF_URL}/v1/sign/sign-document`, data);

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

export default {
  uploadFile,
  sign,
};
