import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // isRead
  isRead: false,

  // Reader
  reader: {
    pinpad: false,
    forcePinpad: false,
  },
  currentModule: null,
  pkcsConfigSet: false,

  // Token
  biometric: null,
  address: null,
  picture: null,
  rootCertificate: {},
  authenticationCertificate: {},
  intermediateCertificates: {},
  nonRepudiationCertificate: {},
  encryptionCertificate: {},
  issuerCertificate: {},
  smartIDCertificate: {},

  // Payment
  applicationData: {},
  applications: [],
  issuerPublicCertificate: {},
  ICCPublicCertificate: {},
};

export const counterSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setReader: (state, action) => {
      state.reader = action.payload;
    },
    setPkcs11ConfigSet: (state, action) => {
      state.pkcsConfigSet = action.payload;
    },
    setCurrentModule: (state, action) => {
      state.currentModule = action.payload;
    },
    setAllData: (state, action) => {
      state.isRead = true;
      state.biometric = action.payload.biometric;
      state.address = action.payload.address;
      state.picture = action.payload.picture;
    },
    setApplicationData: (state, action) => {
      state.isRead = true;
      state.applicationData = action.payload;
    },
    setApplications: (state, action) => {
      state.isRead = true;
      state.applications = action.payload;
    },
    setPaymentCertificates: (state, action) => {
      state.isRead = true;
      state.issuerPublicCertificate = action.payload.issuerPublicCertificate;
      state.ICCPublicCertificate = action.payload.ICCPublicCertificate;
    },
    setAllCertificates: (state, action) => {
      state.isRead = true;
      state.rootCertificate = action.payload.rootCertificate;
      state.intermediateCertificates = action.payload.intermediateCertificates;
      state.authenticationCertificate = action.payload.authenticationCertificate;
      state.nonRepudiationCertificate = action.payload.nonRepudiationCertificate;
      state.encryptionCertificate = action.payload.encryptionCertificate;
      state.issuerCertificate = action.payload.issuerCertificate;
    },
    setSmartIDCertificate: (state, action) => {
      state.smartIDCertificate = action.payload;
    },
    overrulePinpad: (state, action) => {
      state.reader.forcePinpad = action.payload;
    },
    clear: (state) => {
      state.isRead = false;

      state.reader = {
        pinpad: false,
        forcePinpad: false,
      };
      state.currentModule = {};
      state.pkcsConfigSet = false;

      state.biometric = null;
      state.address = null;
      state.picture = null;
      state.rootCertificate = {};
      state.authenticationCertificate = {};
      state.intermediateCertificates = {};
      state.nonRepudiationCertificate = {};
      state.encryptionCertificate = {};
      state.issuerCertificate = {};
      state.smartIDCertificate = {};

      state.applicationData = {};
      state.applications = [];
      state.issuerPublicCertificate = {};
      state.ICCPublicCertificate = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setReader,
  setPkcs11ConfigSet,
  setCurrentModule,
  setAllData,
  setApplicationData,
  setApplications,
  setPaymentCertificates,
  setAllCertificates,
  setSmartIDCertificate,
  overrulePinpad,
  clear,
} = counterSlice.actions;

export default counterSlice.reducer;
