import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Styled from './StepHeader.style';
import { useTheme } from '@mui/material/styles';

const StepHeader = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { title, step, totalSteps, ...rest } = props;

  const progress = () => {
    return Math.round((step / totalSteps) * 100);
  };

  return (
    <Styled theme={theme}>
      <Box className="header-box">
        <Box>{title}</Box>
        <Box>{progress()} %</Box>
      </Box>
    </Styled>
  );
};

export default StepHeader;
