import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Styled from './style';

const ErrorPage = ({ title, description, body, actions, logoPosition, ...rest }) => {
  const hasHeader = !!title || !!description;

  return (
    <Styled {...rest}>
      <div className="error__bg"></div>
      <div className="error__container">
        {logoPosition === 'top' && <img src="/assets/images/t1c-logo.png" className="error__logo error__logo--top" />}
        <div className="error__content">
          <Paper className="error__paper">
            {hasHeader && (
              <div className="error__content__header">
                {title && (
                  <Typography className="error__title" variant="h2">
                    {title}
                  </Typography>
                )}
                {description && <p className="error__description">{description}</p>}
              </div>
            )}
            {body && <div className="error__content__body">{body}</div>}
            {actions && <div className="error__content__actions">{actions}</div>}
          </Paper>
        </div>
        {logoPosition === 'bottom' && (
          <img src="/assets/images/t1c-logo.png" className="error__logo error__logo--bottom" />
        )}
      </div>
    </Styled>
  );
};

ErrorPage.defaultProps = {
  logoPosition: 'bottom',
};

export default ErrorPage;
