import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  font-family: 'Balthazar', serif;

  .with-border-top {
    border-top: 1px solid #91A5BC;
  }

  .with-border-right {
    border-right: 1px solid #91A5BC;
  }

  .field {
    &.withMarginBottom {
      margin-bottom: 15px;
    }
  }

  .flex1 {
    flex: 1;
  }

  .field-row {
    display: flex;

    &.with-border-top {
      border-top: 1px solid #91A5BC;
    }
  }

  .field-item {
    padding: 5px;
  }

  .field__label {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    color: #347DB8;

    &.small {
      font-size: 9px;
    }
  }

  .field__value {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .front {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: ${theme.palette.mode === 'dark' ? 'rgba(160,174,192, 1)' : 'rgb(226, 232, 240)'};
    color: rgba(45, 55, 72, 1);
    display: flex;

    .front__left {

    }

    .front__left__icon {
      display: block;
      width: 35px;
    }

    .chip {
      margin-top: 50px;
      margin-left: 15px;
      display: flex;
      align-items: center;
    }

    .chip__triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 18px 10px 18px 0;
      border-color: transparent #666161 transparent transparent;
      margin-right: 5px;
    }

    .chip__image {
      display: block;
      width: 65px;
    }


    .front__right {
      flex: 1;
      margin-left: 15px;
    }

    .chip__right__header {
      display: flex;
      justify-content: space-between;
    }

    .chip__right__header__title {
      line-height: 1.1;
      font-weight: bold;
      color: #546076;
      text-transform: uppercase;
      font-size: 12px;
    }

    .country {
      margin-top: -5px;

      hr {
        border-color: #6B7783;
        align-self: center;
        height: 9px;
      }
    }

    .portugal {
      margin-top: -5px;
    }

    .country__title {
      font-size: 30px;
      line-height: 1;
      font-weight: bold;
      color: #347DB8;
      text-transform: uppercase;
      font-family: 'Lora', serif;
      text-align: right;
    }

    .country__subtitle {
      color: #6B7783;
      font-size: 12px;
      white-space: nowrap;
    }

    .chip__right__content {
      margin-top: 15px;
    }

    .content-wrapper {}

    .content-wrapper__left {
      flex: 1;
    }

    .content-wrapper__right {
      display: flex;
      align-items: center;
    }

    .profile-picture {
      height: 100px;
    }
  }

  .back {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 10px 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: ${theme.palette.mode === 'dark' ? 'rgba(160,174,192, 1)' : 'rgb(226, 232, 240)'};
    color: rgba(45, 55, 72, 1);
    display: flex;
    flex-direction: column;

    .back__top {
      flex: 1;
      max-width: 80%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .back__top__logo {
      text-transform: uppercase;
      font-family: 'Lora', serif;
      color: #6B7783;
      margin-bottom: 15px;
    }

    .back__top__content {
      margin-bottom: 15px;
    }

    .back__bottom {
      flex: 1;
      position: relative;
    }

    .machine-readable {
      font-size: 1.5rem;
      line-height: 1;
      font-family: 'OCR-B', monospace;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`,
);

export default Styled;
