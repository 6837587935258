import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import createRootReducer, { rootPersistConfig } from './createRootReducer';

const createStore = (history) => {
  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk, promise],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default createStore;
