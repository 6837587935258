import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import MSimpleDialog from '@mui/material/Dialog';

const StyledDialog = styled(MSimpleDialog, {
  shouldForwardProp: (prop) => prop !== 'max',
})(
  ({ max }) => `
  .simpleDialog__paper {
    width: 100%;

    ${
      max &&
      `
        max-width: ${max};
      `
    }
  }
`,
);

const Dialog = ({ open, onClose, className, max, children, ...rest }) => (
  <StyledDialog
    open={open}
    onClose={onClose}
    max={max}
    classes={{ paper: clsx('simpleDialog__paper', className) }}
    {...rest}
  >
    {children}
  </StyledDialog>
);

Dialog.defaultProps = {
  open: false,
  onClose: () => false,
};

export default Dialog;
