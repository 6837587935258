import React from 'react';
import Keyboard from 'react-simple-keyboard';

const NumericKeyboard = (props) => {
  const { onChange, onKeyPress, ...rest } = props;

  return (
    <Keyboard
      theme={'hg-theme-default hg-layout-numeric numeric-theme myTheme1 numeric-keyboard'}
      physicalKeyboardHighlight
      syncInstanceInputs
      onChange={onChange}
      onKeyPress={onKeyPress}
      display={{ '{bksp}': '⌫', '{enter}': 'OK' }}
      layout={{ default: ['7 8 9', '4 5 6', '1 2 3', '{bksp} 0 {enter}'] }}
      buttonTheme={[
        { class: 'red', buttons: '{bksp}' },
        { class: 'green', buttons: '{enter}' },
      ]}
      {...rest}
    />
  );
};

export default NumericKeyboard;
