import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import PinPad from 'components/PinPad';
import { useTranslation } from 'react-i18next';
import { useReader, useErrorNotify } from 'infrastructure/hooks';
import { setAllData, setAllCertificates } from 'infrastructure/reducers/card';
import Loading from 'components/Loading';

const Step2 = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyError } = useErrorNotify();
  const [loading, setLoading] = React.useState();
  const { getAllCerts, getAllData } = useReader();
  const reader = useSelector((state) => state.card.reader);
  const currentModule = useSelector((state) => state.card.currentModule);

  const { type, keyboardTab, onKeyboardTabChanged } = props;

  const onPinPadSubmit = async (pin) => {
    try {
      setLoading(true);

      const [tokenData, certificates] = await Promise.all([
        getAllData(reader.id, currentModule, pin, type),
        getAllCerts(reader.id, currentModule, pin, type),
      ]);

      // Show error notifications for both failed promises.
      if (!tokenData.success && !certificates.success) {
        notifyError(tokenData.res);
        notifyError(certificates.res);
        return;
      }

      if (tokenData.success) {
        dispatch(setAllData(tokenData.res));
      }

      if (certificates.success) {
        dispatch(setAllCertificates(certificates.res));
      }

      navigate('/app/card-info');
      setLoading(false);
    } catch (err) {
      notifyError(err);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ minWidth: 350, minHeight: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
        <Button sx={{ borderRadius: 0 }} onClick={() => onKeyboardTabChanged('numpad')}>
          {t('keyboards.tab.numpad', 'NUMPAD')}
        </Button>
        <Button sx={{ borderRadius: 0 }} onClick={() => onKeyboardTabChanged('mobile')}>
          {t('keyboards.tab.mobile', 'MOBILE')}
        </Button>
      </ButtonGroup>
      <PinPad
        layout={keyboardTab}
        title={t('uploadAndSignDocumentAction.stepper.step2.hint', 'Please enter your {{type}} code', {
          type: type.toLowerCase(),
        })}
        onSubmit={onPinPadSubmit}
      />
    </Box>
  );
};

Step2.defaultProps = {
  signResponse: {},
};

export default Step2;
