import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `

  .step-item {
    padding: 10px;
    display: flex;
    justify-content: 'flex-start';
  }

  .step-active {
    color: unset;
  }
  .step-disabled {
    color: lightgray;
  }
  .step-success{
    color: green;
  }
`,
);

export default Styled;
