import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TruststoreUploadAndSignDocument from './components/TruststoreUploadAndSignDocument';
import VerifyPinCode from './components/VerifyPinCode';
import { useConnector } from 'infrastructure/hooks';
import { Card } from '@mui/material';
import InfoCard from 'components/InfoCard';

const TruststoreActions = () => {
  const { t } = useTranslation();
  const [osDialog, setOsDialog] = React.useState(false);
  const [disabledToggle, setDisabledToggle] = React.useState(false);
  const { client } = useConnector();

  const handleToggleChange = (e) => {
    setOsDialog(e.target.checked);
  };

  const getInfo = async () => {
    try {
      let info = await client.core().info();
      if (info.t1CInfoOS.os === 'macos') {
        setOsDialog(true);
        setDisabledToggle(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getInfo();
  }, []);

  return (
    <Paper sx={{ mt: 4 }} variant="block">
      <Typography sx={{ mb: 2 }} variant="h5">
        <Trans i18nKey="field.actions">Actions</Trans>
      </Typography>

      {disabledToggle && (
        <Box sx={{ my: 1 }}>
          <InfoCard
            body={t(
              'truststore.pindialog.info',
              'We force the pin dialog because most drivers enforce this from a driver level',
            )}
          />
        </Box>
      )}

      <Box sx={{ mb: 2 }}>
        <FormControlLabel
          sx={{ ml: 0 }}
          onChange={handleToggleChange}
          control={<Switch disabled={disabledToggle} checked={osDialog} sx={{ ml: 0, mr: 1 }} />}
          label={t('toggle.useOperatingSystemDialog', 'Use operating system pin dialog')}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TruststoreUploadAndSignDocument />
        </Grid>
        <Grid item xs={12} md="auto">
          <VerifyPinCode osDialog={osDialog} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TruststoreActions;
