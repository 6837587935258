import Paper from '@mui/material/Paper';
import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import { copyTextToClipboard } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const TextBlock = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const copy = (text) => {
    copyTextToClipboard(text);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'), {
      variant: 'info',
    });
  };

  if (props.body) {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">{props.title}</Typography>
        <Paper square={false} sx={{ padding: 1, mt: 0.5 }} variant="outlined">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', lineBreak: 'anywhere', mr: 1 }}
              variant="body2"
            >
              {props.body}
            </Typography>
            <IconButton onClick={() => copy(props.body)} variant="outlined" size="small">
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Paper>
      </Box>
    );
  }
};

export default TextBlock;
