import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Styled from './Step4Content.style';
import { useTheme } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const Step4Content = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const continueClick = async () => {
    props.next();
  };
  const backClick = async () => {
    props.back();
  };

  return (
    <Styled theme={theme}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h2">{t('simplesign.content4.title', 'Sign your document')}</Typography>
          <Typography variant="p">{t('simplesign.content4.caption', 'Sign your document in SimpleSign')}</Typography>
        </Box>
        <Box>
          <Typography variant="p">
            {t(
              'simplesign.content4.sign',
              'Your document should appear in SimpleSign for you to sign. When you are done signing, come back to this page to see the result',
            )}
          </Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button
            color="primary"
            size="medium"
            variant="contained"
            startIcon={<NavigateNextIcon />}
            onClick={continueClick}
          >
            {t('simplesign.content4.next', 'Continue')}
          </Button>
          <Button
            color="primary"
            size="medium"
            variant="outlined"
            startIcon={<KeyboardReturnIcon />}
            onClick={backClick}
          >
            {t('simplesign.content4.back', 'Return')}
          </Button>
        </Stack>
      </Stack>
    </Styled>
  );
};

export default Step4Content;
