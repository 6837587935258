import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const IsCardInReader = ({ no }) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    const toolTipText = no
      ? t('tooltip.noCardInserted', 'No card inserted')
      : t('tooltip.cardInserted', 'Card inserted');
    const iconColor = no ? 'error' : 'success';

    return (
      <Tooltip title={toolTipText}>
        <CreditCardIcon color={iconColor} />
      </Tooltip>
    );
  });
};

IsCardInReader.defaultProps = {
  no: false,
};

export default IsCardInReader;
