import React, { useState, createContext, useContext } from 'react';
import { T1CClient, T1CConfig, T1CConfigOptions } from 't1c-sdk-js';

const connectorContext = createContext();

// T1C SDK provider, keeps track of the T1C sdk client and its various properties.
export const ConnectorProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [errorClient, setErrorClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <connectorContext.Provider
      value={{ client, setClient, errorClient, setErrorClient, loading, setLoading, error, setError }}
    >
      {children}
    </connectorContext.Provider>
  );
};

const useConnector = () => {
  const { client, setClient, errorClient, setErrorClient, loading, setLoading, error, setError } =
    useContext(connectorContext);

  const init = (url, port) => {
    const configOpts = new T1CConfigOptions(url, port, null, location.hostname, undefined, undefined);
    const config = new T1CConfig(configOpts);

    T1CClient.initialize(config).then(
      (newClient) => {
        setClient(newClient);
        setLoading(false);
        setError(null);
      },
      (err) => {
        // (new) Consent is required
        if (err.code == 814500 || err.code == 814501) {
          setErrorClient(err.client);
          setError(err);
          setLoading(false);
          return;
        }

        setError(err);
        setLoading(false);
      },
    );
  };

  const updateClient = (newClient) => {
    setErrorClient(null);
    setError(null);
    setClient(newClient);
  };

  return {
    init,
    client,
    loading,
    error,
    setError,
    errorClient,
    setClient: updateClient,
    setErrorClient,
  };
};

export default useConnector;
