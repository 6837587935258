import { css } from '@emotion/react';

const GlobalStyles = css`
  :root {
    --MuiDocs-navDrawer-width: 250px;
    --MuiDocs-header-height: 64px;
  }

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  @font-face {
    font-family: 'Libre Barcode 39';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/librebarcode39/v14/-nFnOHM08vwC6h8Li1eQnP_AHzI2G_Bx0vrx52g.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  .subHeader__main-title {
    padding-left: 0px !important;
    margin-top: 0px !important;
  }

  .nav-drawer__paper {
    border-right: '#ddd';
  }

  .drawer-nav__top {
    padding: 14.5px 20px;
    padding-right: 0;
    height: 68px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .connector-submit {
    margin: 0 !important;
    margin-top: 10px !important;
  }

  .connector-reset {
    margin: 0 !important;
    margin-top: 10px !important;
    margin-left: 10px !important;
  }
  .popover__paper {
    background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)) !important;
  }

  .fa-secondary {
    opacity: 0.4;
  }
`;

export default GlobalStyles;
