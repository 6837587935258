import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const Card = () => {
  const { t } = useTranslation();
  const address = useSelector((state) => state.card.address);

  if (!address) {
    return null;
  }

  return (
    <Paper variant="block" sx={{ mt: 4, mb: 4 }}>
      <Typography sx={{ mb: 2 }} variant="h5">
        {t('field.addressDetails', 'Address details')}
      </Typography>

      {!hasData && <Typography>{t('templates.address.nothingFound', 'No address data found')}</Typography>}

      {hasData && (
        <Stack spacing={1}>
          <Box>
            <Typography variant="overline">{t('field.address', 'Address')}</Typography>
            <Typography variant="body2">{address?.streetAndNumber}</Typography>
          </Box>
          <Box>
            <Typography variant="overline">{t('field.city', 'City')}</Typography>
            <Typography variant="body2">{address?.municipality}</Typography>
          </Box>
          <Box>
            <Typography variant="overline">{t('field.postalCode', 'Postal code')}</Typography>
            <Typography variant="body2">{address?.zipcode}</Typography>
          </Box>
        </Stack>
      )}
    </Paper>
  );
};

export default Card;
