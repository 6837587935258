import { createSlice } from '@reduxjs/toolkit';
import config from 'client/config';

const initialState = {
  entity: config.fileExchangeDefaultEntity,
};

export const counterSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setEntity: (state, action) => {
      state.entity = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEntity } = counterSlice.actions;

export default counterSlice.reducer;
