import React from 'react';
import { deepmerge } from '@mui/utils';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDesignTokens, getThemedComponents } from './Branding';

const CustomThemeProvider = ({ children }) => {
  let mode = useSelector((state) => state.config.colorSchemeMode);

  if (mode === 'system') {
    mode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  const theme = React.useMemo(() => {
    const designTokens = getDesignTokens(mode);
    let newTheme = createTheme(designTokens);
    newTheme = deepmerge(newTheme, getThemedComponents(newTheme));

    return newTheme;
  }, [mode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
