import React from 'react';

import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { useSelector } from 'react-redux';
import Link from '@mui/material/Link';

const CreatedAuthentication = (props) => {
  const { t } = useTranslation();
  const { open, onClose } = props;
  const authentication = useSelector((state) => state.authentication.authentication);

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        Authentication requested with id{' '}
        <Link href={'https://wallid.t1t.io?code=' + authentication?.id} target="_blank">
          {authentication?.id}{' '}
        </Link>{' '}
        and return address {authentication?.callbackUrl}
      </Alert>
    </Collapse>
  );
};

export default CreatedAuthentication;
