import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  /*Ratio is 8/5*/
  width: 400px;
  height: 250px;
  padding: 20px 5px 5px 25px;
  border: 1px solid lightgray;
  border-radius: 15px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(160,174,192, 1)' : 'rgb(226, 232, 240)'};
  color: rgba(45, 55, 72, 1);

  .card-payment-logo {
    display: block;
    margin-bottom: 30px;
  }

  .chip {
    width: 50px;
    margin-bottom: 20px;
  }

  .card-row {
    display: flex;
    line-height: 1;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .text-title {
    width: 125px;
  }

  .text-value {
    margin-left: 10px;
    font-weight: 700;
  }
`,
);

export default Styled;
