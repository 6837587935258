import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selectable',
})(
  ({ theme, selectable }) => `
  .fx__paper {
    padding: 0;
    border-width: 0;
    border-style: solid;
    border-width: thin;
    border-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

  .fx__paper__top {
    border-width: 0;
    border-style: solid;
    border-bottom-width: thin;
    border-bottom-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

  .fx__paper__top__left,
  .fx__paper__top__right {
    font-weight: 500;
    padding: ${theme.spacing(0.5, 1.5)};
    color: ${theme.palette.grey[500]};
  }

  .fx__paper__top__left {
    width: 200px;
    border-width: 0;
    border-style: solid;
    border-right-width: thin;
    border-right-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

  .fx__paper__container {
    display: flex;
    min-height: 400px;
  }

  .fx__content__data {
    max-height: 500px;
    overflow-y: scroll;
  }

  .fx__paper__nav {
    display: flex;
    min-width: 200px;
    max-width: 200px;
    flex-direction: column;
    border-width: 0;
    border-style: solid;
    border-right-width: thin;
    border-right-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

  .fx__paper__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .fx__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(1, 1.5)};
    border-width: 0;
    border-style: solid;
    border-bottom-width: thin;
    border-bottom-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
    height: 55px;
  }

  .btn-default {
    background-color: ${theme.palette.mode === 'dark' ? '#858585' : '#e0e0e0'} !important;
  }

  .header__actions {
    button {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fx__entity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(0.5, 1.5)};
    border-width: 0;
    border-style: solid;
    border-bottom-width: thin;
    border-bottom-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

  .entity__info {
    flex: 1;
    max-width: 125px;
    margin-right: ${theme.spacing(2)};
  }

  .entity__info__title {
    font-size: 12px;
    line-height: 1;
    text-transform: initial;
  }

  .entity__info__value {
    font-size: 16px;
    margin-top: 4px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .entity__change {
    width: initial;
    height: initial;
    padding: 5px;

    svg {
      font-size: 12px;
    }
  }

  .fx__types {
    padding: ${theme.spacing(0.5, 1.5, 1, 1.5)};
  }

  .types__title {
    color: #757575;
    text-transform: initial;
  }

  .type {
    width: 100%;
    display: flex;
    padding: ${theme.spacing(0.3, 0.4)};
    justify-content: flex-start;
    align-items: center;
    color: ${theme.palette.text.secondary};
    font-weight: ${theme.typography.fontWeightMedium};
    border-radius: ${theme.spacing(0.5)};

    &:hover {
      background-color: ${theme.palette.action.hover};
    }

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &.selected {
      background-color: ${theme.palette.action.selected};
    }
  }

  .type__icon {
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing(1)};

    svg {
      font-size: 16px;
    }
  }

  .type__text {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .lh {
    display: flex;
    margin-bottom: ${theme.spacing(0.5)};
    border-width: 0;
    border-style: solid;
    border-bottom-width: thin;
    border-bottom-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
  }

  .ld {
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: ${theme.spacing(0, 0.5)};
    cursor: pointer;

    &:nth-of-type(odd) {
      background-color: ${
        selectable ? 'transparent' : theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.08)' : theme.palette.grey[50]
      };
    }

    &.selected {
      background-color: ${theme.palette.mode === 'dark' ? '#464646' : theme.palette.grey[300]};
    }

    .ld__c {
      &:last-child {
        button {
          padding: 4px;
        }

        svg {
          font-size: 16px;
        }
      }

      &:not(::last-child) {
        margin-bottom: ${theme.spacing(0.2)};
      }
    }
  }

  .fx__paper__content__top {
    flex: 1;
  }

  .fx__paper__content__actions {
    margin: 10px 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .disabled-content-row {
    background-color: transparent !important;
    color: ${theme.palette.action.disabled} !important;
    cursor: initial;
  }
`,
);

export default Styled;
