import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  .simple-sign-steps {
    border: 1px solid lightgray;
    border-radius: 10px;
  }

  .border-b {
    border-bottom: 1px solid lightgray;
    border-radius: 0;
  }

  .step-success {
  }
`,
);

export default Styled;
