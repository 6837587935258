import React from 'react';

const userLoader = (defaultState = {}) => {
  const [loader, setLoader] = React.useState(defaultState);

  const showLoader = (key) => {
    setLoader({ ...loader, [key]: true });
  };

  const hideLoader = (key) => {
    setLoader({ ...loader, [key]: false });
  };

  const toggleLoader = (key) => {
    setLoader({ ...loader, [key]: !loader[key] });
  };

  const updateLoader = (key, value) => {
    setLoader({ ...loader, [key]: value });
  };

  return {
    loader,
    updateLoader,
    toggleLoader,
    showLoader,
    hideLoader,
  };
};

export default userLoader;
