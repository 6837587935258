import React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CardFront from './components/Front';
import CardBack from './components/Back';
import Styled from './LuxIdCard.style';

const BeIdCard = () => {
  const theme = useTheme();

  return (
    <Styled theme={theme}>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} md="auto">
          <CardFront />
        </Grid>
        <Grid item xs={12} md="auto">
          <CardBack />
        </Grid>
      </Grid>
    </Styled>
  );
};

export default BeIdCard;
