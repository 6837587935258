import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useConnector from 'infrastructure/hooks/useConnector';
import ReplayIcon from '@mui/icons-material/Replay';
import MouseIcon from '@mui/icons-material/Mouse';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Loading from 'components/Loading';
import ErrorBlock from 'components/ErrorBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import { setCertificate } from 'infrastructure/reducers/truststore';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { copyTextToClipboard } from 'utils/helpers';
import { Chip, IconButton, Stack } from '@mui/material';

const TruststoreTable = (props) => {
  const { client } = useConnector();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [certificates, setCertificates] = React.useState([]);
  const [loadingInfo, setLoadingInfo] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    const certs = props.certificates;
    setLoadingInfo(true);
    let updatedCerts = [];
    if (certs.authenticationCertificate && certs.authenticationCertificate.certificates) {
      certs.authenticationCertificate.certificates.forEach((element) => {
        updatedCerts.push(element);
      });
    }

    if (certs.intermediateCertificates && certs.intermediateCertificates.certificates) {
      certs.intermediateCertificates.certificates.forEach((element) => {
        updatedCerts.push(element);
      });
    }

    if (certs.nonRepudiationCertificate && certs.nonRepudiationCertificate.certificates) {
      certs.nonRepudiationCertificate.certificates.forEach((element) => {
        updatedCerts.push(element);
      });
    }

    if (certs.rootCertificate && certs.rootCertificate.certificates) {
      certs.rootCertificate.certificates.forEach((element) => {
        updatedCerts.push(element);
      });
    }

    if (certs.encryptionCertificate && certs.encryptionCertificate.certificates) {
      certs.encryptionCertificate.certificates.forEach((element) => {
        updatedCerts.push(element);
      });
    }

    if (certs.issuerCertificate && certs.issuerCertificate.certificates) {
      certs.issuerCertificate.certificates.forEach((element) => {
        updatedCerts.push(element);
      });
    }
    setCertificates(updatedCerts);
    setLoadingInfo(false);
  };

  const copy = (text) => {
    copyTextToClipboard(text);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'), {
      variant: 'info',
    });
  };

  React.useEffect(() => {
    fetchData();
  }, [props.certificates]);

  const onCertificateSelect = async (c) => {
    setLoading(true);
    try {
      let response = await client.truststore().getCertificate(c.id);
      dispatch(setCertificate(response.data));
      navigate('/app/truststore/certificate');
    } catch (error) {
      enqueueSnackbar(
        t('errorBlock.default.subtitleDescription', 'Something went wrong while fetching the data, please try again..'),
        {
          variant: 'error',
        },
      );
    }
    setLoading(false);
  };

  if (loadingInfo) {
    return <Loading />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('truststore.table.id', 'Id')}</TableCell>
            <TableCell>{t('truststore.table.name', 'Name')}</TableCell>
            <TableCell>{t('truststore.table.type', 'Type')}</TableCell>
            <TableCell>{t('truststore.table.actions', 'Action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certificates.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {row.id}
                  <IconButton onClick={() => copy(row.id)} variant="outlined" size="small">
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </TableCell>
              <TableCell>{row.subject}</TableCell>
              <TableCell>
                <Chip label={row.certificateType} />
              </TableCell>
              <TableCell>
                <LoadingButton
                  loading={loading}
                  color="primary"
                  size="small"
                  variant="outlined"
                  startIcon={<MouseIcon />}
                  onClick={() => onCertificateSelect(row)}
                >
                  {t('action.select', 'Select')}
                </LoadingButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TruststoreTable;
