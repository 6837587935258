import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import InfoBlock from 'components/InfoBlock';

const UserInfo = ({ info }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h2">{t('page.admin.title.userInformation', 'User information')}</Typography>

      <Grid sx={{ mt: 0.5 }} container spacing={2}>
        {/* First row */}
        <Grid item xs={6} md={4}>
          <InfoBlock
            icon={<PersonIcon color="secondary" />}
            title={info?.t1CInfoUser?.name}
            subtitle={t('page.admin.info.name', 'Name')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InfoBlock
            icon={<PersonIcon color="secondary" />}
            title={info?.t1CInfoUser?.username}
            subtitle={t('page.admin.info.username', 'Username')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InfoBlock
            icon={<HomeIcon color="secondary" />}
            title={info?.t1CInfoUser?.home}
            subtitle={t('page.admin.info.homeDirectory', 'Home Directory')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfo;
