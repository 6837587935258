import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { copyTextToClipboard } from 'utils/helpers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const InfoBlock = ({ icon, title, subtitle, showCopy }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const copy = (text) => {
    copyTextToClipboard(text);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'), {
      variant: 'info',
    });
  };

  return (
    <Paper variant="block" elevation={2}>
      <Box>{icon}</Box>

      <Typography sx={{ mt: 0.5, overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
        {subtitle}
      </Typography>
      {showCopy ? (
        <>
          <Typography sx={{ mb: 1, lineBreak: 'anywhere' }} variant="overline">
            {title}
          </Typography>
          <IconButton onClick={() => copy(title)} variant="outlined" size="small">
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </>
      ) : (
        <Typography sx={{ mb: 1, lineBreak: 'anywhere' }} variant="overline">
          {title}
        </Typography>
      )}
    </Paper>
  );
};

InfoBlock.defaultProps = {
  osList: [],
};

export default InfoBlock;
