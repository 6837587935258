import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authentication: null,
};

export const counterSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.authentication = action.payload;
    },
    clearAuthentication: (state) => {
      state.authentication = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthentication, clearAuthentication } = counterSlice.actions;

export default counterSlice.reducer;
