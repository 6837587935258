import React, { useState } from 'react';

import { Box, Typography, Chip, Tooltip, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';

const TrackerDetails = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);

  React.useEffect(() => {});

  const copyText = (txt) => {
    copy(txt);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'));
  };

  return (
    <Box sx={{ marginY: '10px' }}>
      <Typography variant="h4">{t('smartid.result.trackerdetails.header', 'Tracker details')}</Typography>

      <Box>
        <Chip
          sx={{ margin: '5px' }}
          label="Cancelled"
          variant="outlined"
          color={props.tracker.cancel_state ? 'success' : 'error'}
          icon={props.tracker.cancel_state ? <CheckCircleOutlineIcon /> : <CheckCircleOutlineIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Errored"
          variant="outlined"
          color={props.tracker.error_state ? 'success' : 'error'}
          icon={props.tracker.error_state ? <CheckCircleOutlineIcon /> : <CheckCircleOutlineIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Connector installed"
          variant="outlined"
          color={props.tracker.connector_installed ? 'success' : 'error'}
          icon={props.tracker.connector_installed ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Phone number entered"
          variant="outlined"
          color={props.tracker.gsm_input ? 'success' : 'error'}
          icon={props.tracker.gsm_input ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="OTP verification"
          variant="outlined"
          color={props.tracker.gsm_otp_input ? 'success' : 'error'}
          icon={props.tracker.gsm_otp_input ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Identity found"
          variant="outlined"
          color={props.tracker.identity_found ? 'success' : 'error'}
          icon={props.tracker.identity_found ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Certificate valid"
          variant="outlined"
          color={props.tracker.mean_cert_valid ? 'success' : 'error'}
          icon={props.tracker.mean_cert_valid ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Pin code valid"
          variant="outlined"
          color={props.tracker.mean_pin_result ? 'success' : 'error'}
          icon={props.tracker.mean_pin_result ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Reader connected"
          variant="outlined"
          color={props.tracker.reader_connected ? 'success' : 'error'}
          icon={props.tracker.reader_connected ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Token issued"
          variant="outlined"
          color={props.tracker.token_issued ? 'success' : 'error'}
          icon={props.tracker.token_issued ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
        <Chip
          sx={{ margin: '5px' }}
          label="Token validated"
          variant="outlined"
          color={props.tracker.token_validated ? 'success' : 'error'}
          icon={props.tracker.token_validated ? <CheckCircleOutlineIcon /> : <ErrorIcon />}
        />
      </Box>

      <Box sx={{ marginTop: '15px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Digest
                </TableCell>
                <TableCell>
                  <Stack direction="horizontal" spacing={8} justifyContent="space-between" alignItems="center">
                    <Box sx={{ width: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {props.tracker.auth_digest}
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Tooltip title={t('action.copy', 'Copy')}>
                        <IconButton aria-label="Copy" size="small" onClick={() => copyText(props.tracker.auth_digest)}>
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Signed digest
                </TableCell>
                <TableCell>
                  <Stack direction="horizontal" spacing={8} justifyContent="space-between" alignItems="center">
                    <Box sx={{ width: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {props.tracker.auth_digest_signed}
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Tooltip title={t('action.copy', 'Copy')}>
                        <IconButton
                          aria-label="Copy"
                          size="small"
                          onClick={() => copyText(props.tracker.auth_digest_signed)}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Phone number
                </TableCell>
                <TableCell>{props.tracker.gsm_nr}</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Means used
                </TableCell>
                <TableCell>{props.tracker.mean_used}</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Updated at
                </TableCell>
                <TableCell>{props.tracker.updated_at}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TrackerDetails;
