import React from 'react';
import Box from '@mui/material/Box';
import DefaultChipCard from 'components/generic/cards/DefaultChipCard';
import Address from './components/Address';
import Certificates from './components/Certificates';
import Actions from './components/Actions';

const GenericToken = ({ cardHeader }) => (
  <Box>
    <Box sx={{ mb: 4 }}>{React.cloneElement(cardHeader)}</Box>

    <Address />

    <Certificates />

    <Actions />
  </Box>
);

GenericToken.defaultProps = {
  cardHeader: <DefaultChipCard />,
};

export default GenericToken;
