import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { setColorSchemeMode } from 'infrastructure/reducers/config';
import { TextInput } from 'components/Inputs';
import validators from 'utils/validators';
import useClient from 'infrastructure/hooks/useClient';
import useConnector from 'infrastructure/hooks/useConnector';

const LANGUAGES_LABEL = [
  {
    code: 'en',
    i18nKey: 'english',
  },
  {
    code: 'nl',
    i18nKey: 'dutch',
  },
  {
    code: 'fr',
    i18nKey: 'french',
  },
];

const Heading = styled(Typography)(({ theme }) => ({
  margin: '20px 0 10px',
  color: theme.palette.grey[600],
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(11),
  textTransform: 'uppercase',
  letterSpacing: '.08rem',
}));

const IconToggleButton = styled(ToggleButton)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& > *': {
    marginRight: '8px',
  },
});

const AppSettingsDrawer = (props) => {
  const { getConnectorState, setConnectorState, resetConnectorState } = useClient();
  const { init } = useConnector();
  const { enqueueSnackbar } = useSnackbar();
  const mode = useSelector((state) => state.config.colorSchemeMode);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { onClose, open = false, ...other } = props;

  const handleChangeThemeMode = (event, paletteMode) => {
    if (paletteMode === null) {
      return;
    }

    dispatch(setColorSchemeMode(paletteMode));
  };

  const handleLanguageClick = (language) => () => {
    i18n.changeLanguage(language.code);
    moment.locale(language.code);
    onClose();
  };

  const reset = () => {
    resetConnectorState();
    let s = getConnectorState();
    init(s.url, s.port);
    enqueueSnackbar(t('notification.info.connectorReinitialized', 'Trust1Connector re-initialized!'), {
      variant: 'info',
    });
    onClose();
  };

  const onSubmit = ({ url, port }) => {
    let s = getConnectorState();
    s.url = url;
    s.port = port;
    setConnectorState(s);
    init(url, port);
    enqueueSnackbar(t('notification.info.connectorReinitialized', 'Trust1Connector re-initialized!'), {
      variant: 'info',
    });
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: { width: { xs: 310, sm: 360 }, borderRadius: '10px 0px 0px 10px' },
      }}
      {...other}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Typography variant="body1" fontWeight="500">
          {t('field.settings', 'Settings')}
        </Typography>
        <IconButton color="inherit" onClick={onClose} edge="end">
          <CloseIcon color="primary" fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ pl: 2, pr: 2 }}>
        <Heading gutterBottom id="settings-mode">
          {t('settings.theme.mode', 'Mode')}
        </Heading>
        <ToggleButtonGroup
          exclusive
          value={mode}
          color="primary"
          onChange={handleChangeThemeMode}
          aria-labelledby={t('settings.theme.mode', 'Mode')}
          fullWidth
        >
          <IconToggleButton value="light" aria-label={t('settings.theme.light', 'Light')}>
            <LightModeIcon fontSize="small" />
            {t('settings.theme.light', 'Light')}
          </IconToggleButton>
          <IconToggleButton value="system" aria-label={t('settings.theme.system', 'System')}>
            <SettingsBrightnessIcon fontSize="small" />
            {t('settings.theme.system', 'System')}
          </IconToggleButton>
          <IconToggleButton value="dark" aria-label={t('settings.theme.dark', 'Dark')}>
            <DarkModeOutlinedIcon fontSize="small" />
            {t('settings.theme.dark', 'Dark')}
          </IconToggleButton>
        </ToggleButtonGroup>
        <Heading gutterBottom>{t('field.language', 'Language')}</Heading>
        <List>
          {LANGUAGES_LABEL.map((language) => (
            <ListItemButton
              component="a"
              divider
              key={language.code}
              onClick={handleLanguageClick(language)}
              selected={i18n.language === language.code}
              lang={language.code}
              hrefLang={language.code}
            >
              {t(`locale.${language.i18nKey}`)}
            </ListItemButton>
          ))}
        </List>
        <Heading gutterBottom>{t('field.trust1connector', 'Trust1Connector')}</Heading>
        <Form className="page-contact__form" onSubmit={onSubmit} initialValues={getConnectorState()}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={1}>
                <TextInput
                  label={t('field.url', 'Url')}
                  name="url"
                  size="small"
                  margin="dense"
                  validate={validators.isRequired}
                />
                <TextInput
                  label={t('field.port', 'Port')}
                  name="port"
                  size="small"
                  margin="dense"
                  validate={validators.isRequired}
                />
              </Stack>
              <Button
                type="submit"
                className="connector-submit"
                size="small"
                color="primary"
                variant="contained"
                startIcon={<AutoRenewIcon />}
              >
                {t('action.reInitialize', 'Re-initialize')}
              </Button>
              <Button
                className="connector-reset"
                size="small"
                color="primary"
                variant="outlined"
                onClick={reset}
                startIcon={<RemoveCircleOutlineIcon />}
              >
                {t('action.reset', 'Reset')}
              </Button>
            </form>
          )}
        </Form>
      </Box>
    </Drawer>
  );
};

AppSettingsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default AppSettingsDrawer;
