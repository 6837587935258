import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from 'components/dialogs/Dialog';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Form } from 'react-final-form';
import { TextInput } from '../../../../components/Inputs';
import validators from '../../../../utils/validators';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticationService from '../../../../infrastructure/services/AuthenticationService/AuthenticationService';
import { setAuthentication } from '../../../../infrastructure/reducers/authentication';

const CreateAuthenticationDialog = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const partner = useSelector((state) => state.partner.partner);

  const onFormSubmit = (values) => {
    AuthenticationService.createDocument(values.callback, partner.id).then((res) => {
      dispatch(setAuthentication(res.data));
      onClose(res.data);
    });
  };

  const cancel = () => onClose(null);

  return (
    <Dialog open={open} onClose={cancel}>
      <DialogHeader onClose={cancel}>
        <DialogTitle>
          <AddCircleOutlineIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
          {t('dialog.authentication.title', 'Create authentication')}
        </DialogTitle>
      </DialogHeader>
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText sx={{ fontSize: 14, mb: 1.6 }}>
                {t(
                  'dialog.authentication.content',
                  'Fill in a callback URL to where the authentication needs to return to',
                )}
              </DialogContentText>
              <TextInput
                autoFocus
                fullWidth
                label={t('field.callback', 'Callback URL')}
                name="callback"
                size="small"
                margin="dense"
                validate={validators.isRequired}
              />
            </DialogContent>
            <DialogFooter>
              <Stack direction="row" spacing={1}>
                <Button size="small" onClick={cancel}>
                  {t('action.cancel', 'Cancel')}
                </Button>
                <LoadingButton
                  loading={false}
                  disabled={!valid}
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  {t('action.confirm', 'Confirm')}
                </LoadingButton>
              </Stack>
            </DialogFooter>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default CreateAuthenticationDialog;
