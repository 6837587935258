import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Styled from './DefaultPaymentCard.style';

const DefaultPaymentCard = () => {
  const theme = useTheme();
  const applicationData = useSelector((state) => state.card.applicationData);

  const cardNumber = React.useMemo(() => {
    let strRes = '';

    if (!applicationData?.pan) {
      return null;
    }

    [...applicationData.pan].forEach((comp, idx) => {
      idx % 4 === 0 ? (strRes += ' ' + comp) : (strRes += comp);
    });

    return strRes;
  }, [applicationData.pan]);

  return (
    <Styled theme={theme}>
      <img src="/assets/images/credit-card.svg" alt="Credit card chip" />
      {cardNumber && (
        <div className="card-row">
          <i className="text-title">
            <Trans i18nKey="field.cardNumber">Card Number</Trans>
          </i>
          <div className="text-value">{cardNumber}</div>
        </div>
      )}
      {applicationData.name && (
        <div className="card-row">
          <i className="text-title">
            <Trans i18nKey="field.name">Name</Trans>
          </i>
          <div className="text-value">{applicationData.name}</div>
        </div>
      )}
      {applicationData?.expirationDate && (
        <div className="card-row">
          <i className="text-title">
            <Trans i18nKey="field.expires">Expires</Trans>
          </i>
          <div className="text-value">{moment(applicationData.expirationDate, 'YYMMDD').format('DD/MM/YY')}</div>
        </div>
      )}
    </Styled>
  );
};

export default DefaultPaymentCard;
