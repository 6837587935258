import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import StepHeader from '../StepHeader';
import Styled from './SimpleSignSteps.style';
import { useTheme } from '@mui/material/styles';
import StepItem from '../StepItem';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import TelegramIcon from '@mui/icons-material/Telegram';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const SimpleSignSteps = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { currentStep, totalSteps, next, ...rest } = props;

  return (
    <Styled theme={theme}>
      <Box className="simple-sign-steps">
        <StepHeader title={t('simplesign.stepper.title', 'SimpleSign')} step={currentStep} totalSteps={totalSteps} />
        <Box className="border-b" />
        <StepItem
          icon={<DownloadIcon />}
          title={t('simplesign.stepper.step1', 'Download SimpleSign application')}
          currentStep={currentStep}
          step={1}
        />
        <Box className="border-b" />
        <StepItem
          icon={<DriveFolderUploadIcon />}
          title={t('simplesign.stepper.step2', 'Upload a document')}
          currentStep={currentStep}
          step={2}
        />
        <Box className="border-b" />
        <StepItem
          icon={<TelegramIcon />}
          title={t('simplesign.stepper.step3', 'Send to SimpleSign')}
          currentStep={currentStep}
          step={3}
        />
        <Box className="border-b" />
        <StepItem
          icon={<DriveFileRenameOutlineIcon />}
          title={t('simplesign.stepper.step4', 'Sign the uploaded document')}
          currentStep={currentStep}
          step={4}
        />
        <Box className="border-b" />
        <StepItem
          icon={<FileOpenIcon />}
          title={t('simplesign.stepper.step5', 'View the signed document')}
          currentStep={currentStep}
          overrideDone
          step={5}
        />
      </Box>
    </Styled>
  );
};

export default SimpleSignSteps;
