import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  .card-dongle-logo {
    width: 175px;
    fill: ${theme.palette.mode === 'dark' ? 'white' : 'rgb(97, 97, 97)'};
  }
`,
);

export default Styled;
