import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import validators from 'utils/validators';
import SendIcon from '@mui/icons-material/Send';
import { AppContainer } from 'components/Layouts';
import { TextInput, RadioGroup } from 'components/Inputs';

const Contact = () => {
  const { t } = useTranslation();

  const onSubmit = (values) => {
    // TODO
    console.log('TODO Implementation');
    console.log(values);
  };

  return (
    <AppContainer>
      <Paper variant="block" elevation={2}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h2">{t('page.contact.title', 'Contact')}</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {t(
              'page.contact.subtitleDescription',
              'Do you need more information about the Trust1Connector? Do you have a problem with the application? Tell us about it!',
            )}
          </Typography>
        </Box>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2}>
                  <TextInput
                    fullWidth
                    label={t('field.name', 'Name')}
                    name="name"
                    variant="outlined"
                    size="small"
                    validate={validators.isRequired}
                  />
                  <TextInput
                    fullWidth
                    label={t('field.company', 'Company')}
                    name="company"
                    variant="outlined"
                    size="small"
                    validate={validators.isRequired}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextInput
                    fullWidth
                    label={t('field.emailAddress', 'Email Address')}
                    name="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    validate={validators.composeValidators(validators.isRequired, validators.isValidEmail)}
                  />
                  <TextInput
                    fullWidth
                    label={t('field.phoneNumber', 'Phone Number')}
                    name="phone"
                    type="tel"
                    variant="outlined"
                    size="small"
                    validate={validators.isRequired}
                  />
                </Stack>
                <TextInput
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  name="message"
                  label={t('field.yourMessage', 'Your message')}
                  variant="outlined"
                  size="small"
                  validate={validators.isRequired}
                />
              </Stack>
              <RadioGroup
                name="typeOfQuestion"
                type="radio"
                label={t('field.typeOfQuestion.title', 'Type of question')}
                sx={{ mt: 1 }}
                validate={validators.isRequired}
              >
                <FormControlLabel
                  value="moreInformation"
                  control={<Radio size="small" color="secondary" />}
                  label={t('field.typeOfQuestion.moreInformation', 'I want more information')}
                />
                <FormControlLabel
                  value="technicalIssue"
                  control={<Radio size="small" color="secondary" />}
                  label={t('field.typeOfQuestion.technicalIssue', 'I have a technical issue')}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio size="small" color="secondary" />}
                  label={t('field.typeOfQuestion.other', 'Other')}
                />
              </RadioGroup>
              <Box sx={{ mt: 1 }}>
                <Button type="submit" color="primary" size="medium" variant="outlined" startIcon={<SendIcon />}>
                  {t('action.send', 'Send')}
                </Button>
              </Box>
            </form>
          )}
        </Form>
      </Paper>
    </AppContainer>
  );
};

export default Contact;
