import React from 'react';
import Styled from './ListCell.style';

const ListCellActions = (props) => {
  const { width, align, children, ...rest } = props;

  return (
    <Styled className="ld__c" width={width} align={align} {...rest}>
      {children}
    </Styled>
  );
};

ListCellActions.defaultProps = {
  // ASC, DESC
  align: 'left',
  sort: false,
  width: 10,
};

export default ListCellActions;
