import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const CardFront = ({ hasNoData, biometric, picture }) => (
  <div className="front">
    <Stack direction="row" justifyContent="space-between">
      <div>
        <div className="title">BELGIE</div>
        <div className="sub-title">IDENTITEITSKAART</div>
      </div>
      <div>
        <div className="title">BELGIQUE</div>
        <div className="sub-title">CARD D'IDENTITE</div>
      </div>

      <div>
        <div className="title">BELGIEN</div>
        <div className="sub-title">PERSONALAUSWEIS</div>
      </div>

      <div>
        <div className="title">BELGIUM</div>
        <div className="sub-title">IDENTITY CARD</div>
      </div>
    </Stack>

    <div className="card-content">
      <div className="chip">
        <img src="/assets/images/credit-card.svg" alt="Credit card chip" />
      </div>
      <div className="card-row">
        <div>
          <div className="italic">Naam/Name</div>
          <div className="italic">Voornamen/Given names</div>
        </div>
        <Box sx={{ ml: 1 }}>
          <div className="bold">{biometric?.name || 'Specimen'}</div>
          <div className="bold">{!hasNoData ? `${biometric.firstNames} ${biometric.thirdName}` : `Specimen`}</div>
        </Box>
      </div>
      <div className="card-row-2 chip-spacing">
        <div>
          <div className="italic">Geboorteplaats en -datum/Place and date of birth</div>
          <div className="bold">
            {!hasNoData ? `${biometric.birthLocation} ${biometric.birthDate}` : `Brussel 01 JAN 1990`}
          </div>
        </div>
        <div>
          <div className="italic text-right">Geslacht/Sex</div>
          <div className="bold text-right">{biometric?.sex || 'M'}</div>
        </div>
      </div>
      <div className="card-row chip-spacing">
        <div>
          <div className="italic">Nationaliteit</div>
          <div className="italic">Nationality</div>
        </div>
        <div className="bold text-spacing align-end">{!hasNoData ? biometric?.nationality.toUpperCase() : 'BEL'}</div>
      </div>
      <div className="card-row chip-spacing">
        <div>
          <div className="italic">Kaartnr. / Card No</div>
          <div className="bold">
            {!hasNoData
              ? `${biometric?.cardNumber.substr(0, 3)}-${biometric?.cardNumber.substr(
                  3,
                  7,
                )}-${biometric?.cardNumber.substr(10, 2)}`
              : `000-0000000-00`}
          </div>
        </div>
      </div>

      <div className="valid-from-to mt-5">
        <div className="italic">Geldig van - tot / Valid from - until</div>
        <div className="bold">
          {!hasNoData
            ? `${biometric?.cardValidityDateBegin} - ${biometric?.cardValidityDateEnd}`
            : `01.10.2014 - 01.10.2024`}
        </div>
      </div>

      <div className="signature mt-5">
        <div className="italic">Handtekening van de houder / Holder's signature</div>
      </div>

      <div>
        <img className="image" src={`data:image/png;base64,${picture?.picture}`} alt="Image" />
      </div>
    </div>
  </div>
);

export default CardFront;
