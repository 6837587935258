import styled from '@emotion/styled';

const Styled = styled.footer(
  ({ theme }) => `
  font-size: 12px;

  .app-footer__inner {
    margin: ${theme.spacing(1.5, 0, 1.5)};
    text-align: center;
  }

  .app-footer__inner__text {
    margin: ${theme.spacing(0, 2)};
    font-size: 11px;
  }
`,
);

export default Styled;
