import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/Dropdown';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PrintIcon from '@mui/icons-material/Print';
import MoveFileDialog from '../../dialogs/MoveFileDialog';
import RenameFileDialog from '../../dialogs/RenameFileDialog';
import CopyFileDialog from '../../dialogs/CopyFileDialog';
import PrintFileDialog from '../../dialogs/PrintFileDialog';
import TriggerDialogAction from '../TriggerDialogAction';
import DownloadAction from '../../actions/DownloadAction';

const MemoDownloadAction = React.memo(DownloadAction);

const ListCellActions = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { record } = props;
  const open = Boolean(anchorEl);
  const isDirectory = record?.isDir;

  return (
    !isDirectory && (
      <>
        <IconButton variant="text" size="small" color="inherit" onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MoreHorizIcon />
        </IconButton>
        <Dropdown anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <MemoDownloadAction fileName={record.name} onClose={handleClose} />

          <TriggerDialogAction
            triggerButton={
              <MenuItem disableRipple>
                <PrintIcon />
                {t('action.print', 'Print')}
              </MenuItem>
            }
            dialog={<PrintFileDialog fileName={record.name} onClose={() => setAnchorEl(null)} />}
          />

          <Divider sx={{ my: '0px !important' }} />

          <TriggerDialogAction
            triggerButton={
              <MenuItem disableRipple>
                <DriveFileRenameOutlineIcon />
                {t('action.rename', 'Rename')}
              </MenuItem>
            }
            dialog={<RenameFileDialog fileName={record.name} onClose={() => setAnchorEl(null)} />}
          />

          <TriggerDialogAction
            triggerButton={
              <MenuItem disableRipple>
                <OpenWithIcon />
                {t('action.move', 'Move')}
              </MenuItem>
            }
            dialog={<MoveFileDialog fileName={record.name} onClose={() => setAnchorEl(null)} />}
          />

          <TriggerDialogAction
            triggerButton={
              <MenuItem disableRipple>
                <ContentCopyIcon />
                {t('action.copy', 'Copy')}
              </MenuItem>
            }
            dialog={<CopyFileDialog fileName={record.name} onClose={() => setAnchorEl(null)} />}
          />
        </Dropdown>
      </>
    )
  );
};

ListCellActions.defaultProps = {};

export default ListCellActions;
