import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ErrorPage from 'components/ErrorPage';

const Error404 = () => (
  <ErrorPage
    title={<Trans i18nKey="errors.403.title">Access Denied</Trans>}
    description={<Trans i18nKey="errors.403.description">You don't have permissions to access this page.</Trans>}
    actions={
      <>
        <Button className="error__action" varaint="contained" color="primary" to={'/'} component={Link}>
          <Trans i18nKey="action.backToHome">Back to home</Trans>
        </Button>
      </>
    }
  />
);

export default Error404;
