import React from 'react';
import { T1CClient } from 't1c-sdk-js';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { copyTextToClipboard } from 'utils/helpers';
import useConnector from 'infrastructure/hooks/useConnector';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import ErrorBlock from 'components/ErrorBlock';

const GiveConsent = () => {
  const { t } = useTranslation();
  const { errorClient, setClient, setError } = useConnector();
  const { getErrorMessage } = useErrorNotify();

  const giveConsent = () => {
    const token = T1CClient.generateConsentToken();
    copyTextToClipboard(token);

    errorClient
      .core()
      .getImplicitConsent(token)
      .then(
        (newClient) => {
          setClient(newClient);
        },
        (err) => {
          const errorMessage = getErrorMessage(err);

          setError(errorMessage);
        },
      );
  };

  return (
    <ErrorBlock
      title={t('errorBlock.consentRequired.title', 'Consent is required')}
      subtitle={t(
        'errorBlock.consentRequired.subtitleDescription',
        'To use the Read My Cards application we need your consent. Please click on the button below to provide your consent to continue.',
      )}
      actions={
        <Button color="primary" size="medium" variant="outlined" startIcon={<ThumbUpIcon />} onClick={giveConsent}>
          {t('errorBlock.consentRequired.submit', 'Give consent')}
        </Button>
      }
    />
  );
};

export default GiveConsent;
