import React from 'react';
import { Field } from 'react-final-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const DefaultSelect = (props) => {
  const { label, name, value, onChange, onBlur, onFocus, error, options, ...rest } = props;

  return (
    <Autocomplete
      disablePortal
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      name={name}
      error={error}
      {...rest}
      renderInput={(params) => <TextField {...params} name={name} error={error} label={label} />}
    />
  );
};

DefaultSelect.defaultProps = {
  type: 'text',
  options: [],
  onBlur: () => false,
  onFocus: () => false,
  onChange: () => false,
};

const TextInputWithField = ({ name, validate, ...rest }) => {
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta: { touched, error } }) => (
        <DefaultSelect error={touched && error ? error : false} {...input} {...rest} />
      )}
    />
  );
};

export default TextInputWithField;
