import React, { useState } from 'react';

import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SessionContext = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);

  React.useEffect(() => {});

  const copyText = (txt) => {
    copy(txt);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'));
  };

  return (
    <Box sx={{ marginY: '10px' }}>
      <Typography variant="h4">{t('smartid.result.sessioncontext.header', 'Session context')}</Typography>

      <Box sx={{ marginTop: '15px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Correlation ID
                </TableCell>
                <TableCell>
                  <Stack direction="horizontal" spacing={8} justifyContent="space-between" alignItems="center">
                    <Box sx={{ width: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {props.session.correlation_id}
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Tooltip title={t('action.copy', 'Copy')}>
                        <IconButton
                          aria-label="Copy"
                          size="small"
                          onClick={() => copyText(props.session.correlation_id)}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Organisation
                </TableCell>
                <TableCell>{props.session.org_name}</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Means
                </TableCell>
                <TableCell>{props.session.means}</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Use-case
                </TableCell>
                <TableCell>{props.session.use_case}</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Session timeout
                </TableCell>
                <TableCell>{props.session.session_time_in_minutes} minutes</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Session started
                </TableCell>
                <TableCell>{props.session.created_at}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SessionContext;
