import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Global } from '@emotion/react';
import globalStyles from './style';

const GlobalStyle = ({ children }) => (
  <>
    <Global styles={globalStyles} />
    <CssBaseline />
    {children}
  </>
);

export default GlobalStyle;
