import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { Form } from 'react-final-form';
import { TextInput } from 'components/Inputs';
import SendIcon from '@mui/icons-material/Send';

const Pkcs11Config = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h3">{t('page.admin.title.setpkcs11configinfo', 'Update configuration')}</Typography>
      <Box sx={{ my: 2 }}>
        <Paper variant="block" elevation={2}>
          <Form onSubmit={props.onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={1}>
                  <TextInput fullWidth label="MacOS library path" name="macos" variant="outlined" size="small" />
                  <TextInput fullWidth label="Windows library path" name="win" variant="outlined" size="small" />
                  <TextInput fullWidth label="Linux library path" name="linux" variant="outlined" size="small" />
                </Stack>
                <Box sx={{ mt: 1 }}>
                  <Button type="submit" color="primary" size="medium" variant="outlined" startIcon={<SendIcon />}>
                    {t('action.update', 'Update')}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </Paper>
      </Box>
    </Box>
  );
};

export default Pkcs11Config;
