import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import LoginIcon from '@mui/icons-material/LoginOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import SvgHamburgerMenu from 'components/icons/SvgHamburgerMenu';
import { AppNavDrawer, AppSettingsDrawer } from 'components/Layouts';
import Styled from './AppLayout.style.js';
import { useDispatch, useSelector } from 'react-redux';
import { clearPartner } from '../../../infrastructure/reducers/partner';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Button from '@mui/material/Button';

const AppLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const partner = useSelector((state) => state.partner.partner);

  const goToLogin = () => {
    navigate('login');
    dispatch(clearPartner());
  };

  const logout = () => {
    navigate('/');
    dispatch(clearPartner());
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Styled theme={theme} className="app-layout">
      <AppBar className="app-layout__appBar">
        <Toolbar variant="dense" disableGutters>
          <Tooltip title={t('action.openNavigation', 'Open navigation')} enterDelay={300}>
            <IconButton
              edge="start"
              color="primary"
              aria-label={t('action.openNavigation', 'Open navigation')}
              className="nav-toggle-icon-button"
              onClick={() => setMobileOpen(true)}
              sx={{ ml: '1px' }}
            >
              <SvgHamburgerMenu />
            </IconButton>
          </Tooltip>
          <Link to="/">
            <Box
              aria-label={t('action.goToHomePage', 'Go to homepage')}
              sx={{ display: ['flex', null, null, 'none'], ml: 2, alignItems: 'center' }}
            >
              <img src="/assets/images/t1c-logo.png" width={30} />
            </Box>
          </Link>
          <Button
            color="primary"
            size="medium"
            variant="outlined"
            startIcon={<LightbulbOutlinedIcon />}
            onClick={() => openInNewTab('https://t1t.gitbook.io/readmycards/')}
          >
            {t('btn.documentation', 'Documentation')}
          </Button>
          <div className="growing-div" />
          <Stack direction="row" spacing={1.3}>
            {partner?.name === undefined ? (
              <Tooltip title={t('action.login', 'Login')} enterDelay={300}>
                <IconButton color="secondary" onClick={() => goToLogin()} sx={{ px: '8px' }}>
                  <LoginIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <Chip sx={{ mt: 0.5 }} label={partner?.name} />
                <Tooltip title={t('action.logout', 'Logout')} enterDelay={300}>
                  <IconButton color="secondary" onClick={() => logout()} sx={{ px: '8px' }}>
                    <LogoutIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <Tooltip title={t('action.openSettings', 'Open settings')} enterDelay={300}>
              <IconButton color="secondary" onClick={() => setSettingsOpen(true)} sx={{ px: '8px' }}>
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      <AppNavDrawer
        className="app-layout__nav-drawer"
        onClose={() => setMobileOpen(false)}
        onOpen={() => setMobileOpen(true)}
        mobileOpen={mobileOpen}
      />
      <main className="app-layout__main">
        <Outlet />
      </main>
      <AppSettingsDrawer onClose={() => setSettingsOpen(false)} open={settingsOpen} />
    </Styled>
  );
};

export default AppLayout;
