import React from 'react';
import { useSelector } from 'react-redux';
import { constructMachineReadableStrings } from 'utils/helpers';

const CardBack = () => {
  const biometric = useSelector((state) => state.card.biometric);

  const mrz = React.useMemo(() => constructMachineReadableStrings(biometric), [biometric]);

  return (
    <div className="back">
      <div className="back__top">
        <div className="back__top__logo">Portugal</div>

        <div className="back__top__content">
          <div className="field">
            <div className="field__label">Parents</div>
            <div className="field__value">John doe</div>
            <div className="field__value">John doe</div>
          </div>
        </div>

        <div className="field-row with-border-top">
          <div className="field field-item flex1 with-border-right">
            <div className="field__label">
              Colum 1 <br /> Columa 1
            </div>
            <div className="field__value">23473247324</div>
          </div>
          <div className="field field-item flex1 with-border-right">
            <div className="field__label">
              Colum 2 <br /> Columa 2
            </div>
            <div className="field__value">23473247324</div>
          </div>
          <div className="field field-item flex1">
            <div className="field__label">
              Colum 3 <br /> Columa 3
            </div>
            <div className="field__value">23473247324</div>
          </div>
        </div>
      </div>
      <div className="back__bottom">
        {mrz && (
          <div className="machine-readable">
            <div>{mrz[0]}</div>
            <div>{mrz[1]}</div>
            <div>{mrz[2]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardBack;
