import styled from '@emotion/styled';
import ErrorPage from 'components/ErrorPage';

const Styled = styled(ErrorPage)`
  .error__panel {
    margin-top: 1em;
  }

  .error__panel-details {
    white-space: 'pre-wrap';
  }
`;

export default Styled;
