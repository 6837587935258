import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import SvgUsbIcon from 'components/icons/SvgUsb';
import Styled from './DongleIcon.style';

const DongleIcon = ({ iconLabel }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Styled theme={theme}>
      <div className="icon-wrapper">
        <SvgUsbIcon />
        {iconLabel && <div className="icon-wrapper__text">{iconLabel}</div>}
      </div>
      <Typography variant="h6" className="dongle-icon__title">
        {t('field.dongle', 'Dongle')}
      </Typography>
    </Styled>
  );
};

export default DongleIcon;
