import React from 'react';
import { clsx } from 'clsx';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import MLabelIcon from '@mui/icons-material/Label';
import useFinder from '../hooks/useFinder';
import useFinderActions from '../hooks/useFinderActions';

const Type = ({ selected, label, icon: LabelIcon, ...rest }) => (
  <Button className={clsx({ type: true, selected })} {...rest}>
    <div className="type__icon">
      <Box component={LabelIcon} />
    </div>
    <div className="type__text">{label}</div>
  </Button>
);

const Types = () => {
  const { type, setType, types } = useFinder();
  const { loader } = useFinderActions();

  const renderContent = () => {
    if (loader.fetchTypes) {
      return <Skeleton variant="rectangular" width={`100%`} height={25} />;
    }

    return types.map((item, index) => (
      <Type
        key={`${item.type}-${index}`}
        selected={item.type === type}
        label={item.type}
        icon={MLabelIcon}
        onClick={() => setType(item.type)}
      />
    ));
  };

  return (
    <Box className="fx__types">
      <Typography className="types__title" variant="overline">
        Types
      </Typography>

      <Box className="types__list"> {renderContent()} </Box>
    </Box>
  );
};

Types.defaultProps = {};

export default Types;
