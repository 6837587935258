import React from 'react';
import { useTheme } from '@mui/material/styles';
import Styled from './DefaultChipCard.style';

const DefaultChipCard = () => {
  const theme = useTheme();

  return (
    <Styled theme={theme}>
      <img src="/assets/images/credit-card.svg" alt="Chip" />
    </Styled>
  );
};

export default DefaultChipCard;
