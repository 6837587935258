import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import LoopIcon from '@mui/icons-material/Loop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Tooltip, IconButton, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const AtrInfo = (props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyText = (txt) => {
    copy(txt);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'));
  };

  const update = async () => {
    props.refresh();
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Stack sx={{ mt: 0.5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('page.admin.atrlist.title', 'ATR information')}</Typography>
        <Box>
          <Button
            sx={{ ml: 1 }}
            color="primary"
            size="small"
            variant="contained"
            startIcon={<LoopIcon />}
            onClick={update}
          >
            {t('action.refresh', 'Refresh')}
          </Button>
        </Box>
      </Stack>
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
          <Typography>{t('page.admin.atrlist.accordeon', 'ATR list')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack sx={{ mt: 0.5 }} direction="row" justifyContent="space-between" alignItems="center">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('page.admin.atrlist.atr', 'ATR')}</TableCell>
                    <TableCell>{t('page.admin.atrlist.module', 'Module')}</TableCell>
                    <TableCell>{t('page.admin.atrlist.description', 'Description')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data?.map((row) => (
                    <TableRow key={row.atr} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.atr}
                        <Tooltip title={t('action.copy', 'Copy')}>
                          <IconButton aria-label="Copy" size="small" onClick={() => copyText(row.atr)}>
                            <ContentCopyIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{row.module}</TableCell>
                      <TableCell>{row.moduleDescription}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AtrInfo;
