import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

const AuthenticationList = (props) => {
  const { loading, authentications } = props;

  if (loading) {
    return (
      <Box sx={{ my: 2 }}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  } else {
    return (
      <TableContainer sx={{ my: 2 }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 400 }}>ID</TableCell>
              <TableCell sx={{ width: 200 }}>Status</TableCell>
              <TableCell>Callback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {authentications.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ width: 400 }}>
                  <Link href={'https://wallid.t1t.io?code=' + row.id} target="_blank">
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell sx={{ width: 200 }}>{row.status}</TableCell>
                <TableCell>{row.callbackUrl}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default AuthenticationList;
