import React from 'react';

const useError = (defaultState = {}) => {
  const [error, setError] = React.useState(defaultState);

  const updateError = (key, value) => {
    setError({ ...error, [key]: value });
  };

  return {
    error,
    updateError,
  };
};

export default useError;
