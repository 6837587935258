import React from 'react';
import { useSelector } from 'react-redux';
import { constructMachineReadableStrings } from 'utils/helpers';

const CardBack = () => {
  const biometric = useSelector((state) => state.card.biometric);

  const mrz = React.useMemo(() => constructMachineReadableStrings(biometric), [biometric]);

  return (
    <div className="back">
      <div className="back__top">
        <div className="back__top__content">
          <div className="field">
            <div className="field__label">Date et lieu de déliverance / date and place of issue</div>
            <div className="field__value">09.07.2021 Luxembourg</div>
          </div>
          <div className="field">
            <div className="field__label">Autorité / Authority</div>
          </div>

          <div className="signature-container">
            <div className="signature-image"></div>
            <div className="signature-content">
              <div>Taina Bofferding</div>
              <div>Ministre de l'intérieur</div>
            </div>
          </div>
        </div>
      </div>
      <div className="back__bottom">
        {mrz && (
          <div className="machine-readable">
            <div>{mrz[0]}</div>
            <div>{mrz[1]}</div>
            <div>{mrz[2]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardBack;
