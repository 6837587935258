import React from 'react';
import { Trans } from 'react-i18next';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const ExampleCardAlert = ({ title, description }) => (
  <Alert severity={`warning`} sx={{ mb: 2 }}>
    <AlertTitle>{title}</AlertTitle>
    {description}
  </Alert>
);

ExampleCardAlert.defaultProps = {
  title: <Trans i18nKey={`alert.info.exampleCard.title`}>No data found on this card!</Trans>,
  description: (
    <Trans i18nKey={`alert.info.exampleCard.title`}>
      The card below is shown as an example with <b>FAKE</b> data.
    </Trans>
  ),
};

export default ExampleCardAlert;
