import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Chip, Stack } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ConfigurationService from 'infrastructure/services/Configuration';
import Loading from 'components/Loading';
import { useClient } from 'infrastructure/hooks';

const DownloadInfo = () => {
  const { t, i18n } = useTranslation();
  const { client } = useClient();
  const [loading, setLoading] = React.useState();
  const [versions, setVersions] = React.useState();

  const formattedDate = (input) => {
    let date = new Date(input);
    return date.toLocaleDateString(i18n.language + '-BE', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const downloadVersion = (uri, popupState) => {
    window.open(uri.uri, '_blank');
    popupState.close();
  };

  const formatOs = (input) => {
    if (input.toLowerCase() == 'win64') return 'Windows x64';
    else if (input.toLowerCase() == 'win32') return 'Windows x86';
    else if (input.toLowerCase() == 'macos') return 'MacOS Intel chip';
    else if (input.toLowerCase() == 'macosarm') return 'MacOS Apple chip';
    else return 'Unknown';
  };

  const loadAll = async () => {
    setLoading(true);
    try {
      setVersions(client.connectorVersions);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadAll();
  }, []);

  return (
    <Box sx={{ mb: 2 }}>
      <Stack sx={{ mt: 0.5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('page.admin.title.downloadInfo', 'Download information')}</Typography>
        <Button color="primary" size="small" variant="contained" startIcon={<LoopIcon />} onClick={loadAll}>
          {t('action.refresh', 'Refresh')}
        </Button>
      </Stack>

      {loading && <Loading></Loading>}

      {!loading && (
        <Stack sx={{ mt: 0.5 }} direction="row" justifyContent="space-between" alignItems="center">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('admin.downloadinfo.version', 'Version')}</TableCell>
                  <TableCell>{t('admin.downloadinfo.releasedate', 'Release date')}</TableCell>
                  <TableCell>{t('admin.downloadinfo.actions', 'Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {versions?.map((row) => (
                  <TableRow key={row.version} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      v{row.version} {row.latest ? <Chip sx={{ ml: 2 }} label="Latest" /> : <></>}{' '}
                      {row.recommended ? <Chip color="success" sx={{ ml: 1 }} label="Recommended" /> : <></>}{' '}
                      {row.mandatory ? <Chip color="error" sx={{ ml: 1 }} label="Mandatory" /> : <></>}
                    </TableCell>
                    <TableCell>{formattedDate(row.updatedOn)}</TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                              {t('admin.downloadinfo.download', 'Downloads')}
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                              {row.uris.map((uri) => (
                                <MenuItem
                                  key={uri.os}
                                  onClick={() => {
                                    downloadVersion(uri, popupState);
                                  }}
                                >
                                  {formatOs(uri.os)}
                                </MenuItem>
                              ))}
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </Box>
  );
};

export default DownloadInfo;
