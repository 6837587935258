import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Styled from './Step3Content.style';
import { useTheme } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SimpleSignService from 'infrastructure/services/SimpleSign';
import { useErrorNotify } from 'infrastructure/hooks';

const Step3Content = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { notifyError } = useErrorNotify();

  const { file, ...rest } = props;

  const continueClick = async () => {
    try {
      await SimpleSignService.sendDocument(props.file.name);
      props.next();
    } catch (error) {
      notifyError({
        message: t(
          'simplesign.content3.senderror',
          'Something went wrong while sending the document to SimpleSign, please try again',
        ),
      });
      console.error(error);
    }
  };
  const backClick = async () => {
    props.back();
  };

  return (
    <Styled theme={theme}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h2">{t('simplesign.content3.title', 'Send your document to SimpleSign')}</Typography>
          <Typography variant="p">
            {t(
              'simplesign.content3.caption',
              'Now we will send the document to your SimpleSign application for you to sign',
            )}
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">
            {t(
              'simplesign.content3.send',
              'Click the button below to send the document to SimpleSign. Then open SimpleSign and you should see the document in your Inbox.',
            )}
          </Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button
            color="primary"
            size="medium"
            variant="contained"
            startIcon={<NavigateNextIcon />}
            onClick={continueClick}
          >
            {t('simplesign.content3.next', 'Send')}
          </Button>
          <Button
            color="secondary"
            size="medium"
            variant="outlined"
            startIcon={<KeyboardReturnIcon />}
            onClick={backClick}
          >
            {t('simplesign.content3.back', 'Return')}
          </Button>
        </Stack>
      </Stack>
    </Styled>
  );
};

export default Step3Content;
