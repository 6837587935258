import styled from '@emotion/styled';

const Styled = styled.div`
  .field {
    margin-bottom: 2.5px;
  }

  .field-row {
    display: flex;

    .field {
      margin-right: 10px;
    }
  }

  .field__label {
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    line-height: 1.2;

    &.small {
      font-size: 8px;
    }
  }

  .field__value {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 5px;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  .front {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 15px 10px 5px 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ebe3d3;
    color: rgba(45, 55, 72, 1);

    .top-icon-shape {
      width: 35px;
    }

    .front__title {
      color: #243a9e;
      font-size: 25px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 2px;
      letter-spacing: -0.5px;
    }

    .front__subtitle {
      color: #992126;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1;
      font-weight: bold;
    }

    .card-content {
      display: flex;
      margin-top: 10px;
    }

    .card-content__left {
      margin-right: 20px;
      width: 140px;
    }

    .picture-wrapper {
      position: absolute;
      left: 10px;
      bottom: 5px;
    }

    .profile-picture {
      height: 175px;
      width: 123px;
      font-size: 8px;
    }

    .card-content__middle {
      flex: 1;
      margin-top: 20px;
    }

    .dni {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .field__label {
        font-size: 11px;
        margin-right: 10px;
      }

      .field__value {
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 5px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }

  .back {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 15px 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ebe3d3;
    color: rgba(45, 55, 72, 1);
    display: flex;
    flex-direction: column;

    .back__top {
      flex: 1;
      max-width: 85%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    .back__top__left {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    .chip {
      width: 60px;
    }

    .back__top__right {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .back__bottom {
      position: relative;
      height: 75px;
    }

    .signature-container {
      text-align: center;
      font-size: 14px;
      line-height: 1.1;
      margin-top: 15px;
    }

    .machine-readable {
      font-size: 1.5rem;
      line-height: 1;
      font-family: 'OCR-B', monospace;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;

export default Styled;
