import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ErrorPage from 'components/ErrorPage';

const Error404 = () => (
  <ErrorPage
    title={<Trans i18nKey="errors.404.title">404: The page you are looking for isn’t here</Trans>}
    description={
      <Trans i18nKey="errors.404.description">
        You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation
      </Trans>
    }
    actions={
      <>
        <Button to={'/'} className="error__action" varaint="contained" color="primary" component={Link}>
          <Trans i18nKey="action.backToHome">Back to home</Trans>
        </Button>
      </>
    }
  />
);

export default Error404;
