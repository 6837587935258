import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from 'react-redux';
import VerifyPinCode from 'components/VerifyPinCode';
import UploadAndSignDocument from 'components/UploadAndSignDocument';
import { overrulePinpad } from 'infrastructure/reducers/card';

const Actions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPinPad = useSelector((state) => state.card.reader.pinpad);
  const isPinpadForced = useSelector((state) => state.card.reader.forcePinpad);

  const handleToggleChange = (e) => {
    dispatch(overrulePinpad(e.target.checked));
  };

  return (
    <Paper sx={{ mt: 4 }} variant="block">
      <Typography sx={{ mb: 2 }} variant="h5">
        <Trans i18nKey="field.actions">Actions</Trans>
      </Typography>

      {!isPinPad && (
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            sx={{ ml: 0 }}
            onChange={handleToggleChange}
            control={<Switch checked={isPinpadForced} sx={{ ml: 0, mr: 1 }} />}
            label={t('toggle.useOperatingSystemDialog', 'Use operating system pin dialog')}
          />
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <UploadAndSignDocument />
        </Grid>
        <Grid item xs={12} md="auto">
          <VerifyPinCode />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Actions;
