import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // dark | system | light
  // default system
  colorSchemeMode: 'system',
  skipVersion: false,
  modules: [],
};

export const counterSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setColorSchemeMode: (state, action) => {
      state.colorSchemeMode = action.payload;
    },
    setSkipVersion: (state, action) => {
      state.skipVersion = action.payload;
    },
    setModules: (state, action) => {
      state.modules = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setColorSchemeMode, setSkipVersion, setModules } = counterSlice.actions;

export default counterSlice.reducer;
