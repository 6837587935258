import React from 'react';
import Link from '@mui/material/Link';
import Dropdown from 'components/Dropdown';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TriggerDialogAction from '../components/TriggerDialogAction';
import CreateTypeDialog from '../dialogs/CreateTypeDialog';
import DeleteTypeDialog from '../dialogs/DeleteTypeDialog';
import CreateFolderDialog from '../dialogs/CreateFolderDialog';
import UploadFileDialog from '../dialogs/UploadFileDialog';
import useFinder from '../hooks/useFinder';

const Header = () => {
  const { t } = useTranslation();
  const { editable, entity, type, setType, relPaths, setRelPaths, selectedRelPaths, setSelectedRelPaths } = useFinder();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onRelPathClicked = (path, index) => {
    if (relPaths.length === index + 1) {
      return;
    }

    const newPaths = [...relPaths].slice(relPaths.length - index);
    const newSelectedRelPaths = [...selectedRelPaths].slice(0, index + 1);
    setRelPaths(newPaths);
    setSelectedRelPaths(newSelectedRelPaths);
  };

  const renderNoTypesActions = () => (
    <>
      <TriggerDialogAction
        triggerButton={
          <Button
            size="small"
            color="inherit"
            variant="contained"
            className="btn-default"
            startIcon={<CreateNewFolderIcon />}
          >
            {t('action.createTypeAndAssignLocation', 'Create type and assign location')}
          </Button>
        }
        dialog={<CreateTypeDialog />}
      />
    </>
  );

  const renderDefaultActions = () => {
    const open = Boolean(anchorEl);

    return (
      <>
        <TriggerDialogAction
          triggerButton={
            <Button size="small" color="primary" variant="contained" startIcon={<FileUploadIcon />}>
              {t('action.uploadFile', 'Upload file')}
            </Button>
          }
          dialog={<UploadFileDialog />}
        />
        <TriggerDialogAction
          triggerButton={
            <Button
              size="small"
              color="inherit"
              variant="contained"
              className="btn-default"
              startIcon={<CreateNewFolderIcon />}
            >
              {t('action.createFolder', 'Create folder')}
            </Button>
          }
          dialog={<CreateFolderDialog />}
        />

        <Divider orientation="vertical" flexItem />

        <Button
          variant="contained"
          size="small"
          className="btn-default"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {t('action.moreActions', 'More actions')}
        </Button>

        <Dropdown anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <TriggerDialogAction
            triggerButton={
              <MenuItem disableRipple>
                <AddIcon />
                {t('action.createType', 'Create type')}
              </MenuItem>
            }
            dialog={<CreateTypeDialog onClose={() => setAnchorEl(null)} />}
          />

          <Divider sx={{ my: '0 !important' }} />

          <TriggerDialogAction
            triggerButton={
              <MenuItem disableRipple>
                <DeleteOutlineIcon />
                {t('action.deleteType', 'Delete type')}
              </MenuItem>
            }
            dialog={<DeleteTypeDialog onClose={() => setAnchorEl(null)} />}
          />
        </Dropdown>
      </>
    );
  };

  const renderActions = () => {
    if (!type) {
      return renderNoTypesActions();
    }

    return renderDefaultActions();
  };

  return (
    <Box className="fx__header">
      <Breadcrumbs aria-label="breadcrumb" maxItems={4}>
        <Typography color="text.primary" sx={{ fontSize: 14 }}>
          {entity}
        </Typography>
        {type && (
          <Link
            underline="hover"
            color="inherit"
            sx={{ fontSize: 14, cursor: 'pointer' }}
            onClick={() => setType(type)}
          >
            {type}
          </Link>
        )}
        {relPaths.map((path, index) => (
          <Link
            key={path}
            underline={relPaths.length === index + 1 ? 'none' : 'hover'}
            color="inherit"
            sx={{ fontSize: 14, cursor: 'pointer' }}
            onClick={() => onRelPathClicked(path, index)}
          >
            {path}
          </Link>
        ))}
      </Breadcrumbs>
      <Stack className="header__actions" direction="row" spacing={1}>
        {editable && renderActions()}
      </Stack>
    </Box>
  );
};

Header.defaultProps = {};

export default Header;
