import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoBlock from 'components/InfoBlock';

const LibraryConfiguration = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h3">{t('page.admin.title.pkcs11configinfo', 'Current configuration')}</Typography>
      <Box sx={{ mt: 1 }}>
        {props.config?.macos && (
          <InfoBlock title={props.config?.macos} subtitle="MacOS library path" showCopy={props.config?.macos} />
        )}
        {!props.config?.macos && (
          <InfoBlock title="Not specified" subtitle="MacOS library path" showCopy={props.config?.macos} />
        )}
      </Box>
      <Box sx={{ mt: 1 }}>
        {props.config?.win && (
          <InfoBlock title={props.config?.win} subtitle="Windows library path" showCopy={props.config?.win} />
        )}
        {!props.config?.win && (
          <InfoBlock title="Not specified" subtitle="Windows library path" showCopy={props.config?.win} />
        )}
      </Box>
      <Box sx={{ mt: 1 }}>
        {props.config?.linux && (
          <InfoBlock title={props.config?.linux} subtitle="Linux library path" showCopy={props.config?.linux} />
        )}
        {!props.config?.linux && (
          <InfoBlock title="Not specified" subtitle="Linux library path" showCopy={props.config?.linux} />
        )}
      </Box>
    </Box>
  );
};

export default LibraryConfiguration;
