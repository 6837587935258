import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LabelIcon from '@mui/icons-material/Label';
import Dialog from 'components/dialogs/Dialog';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import { TextInput } from 'components/Inputs';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import validators from 'utils/validators';
import useFinder from '../../hooks/useFinder';

const ChangeEntityDialog = (props) => {
  const { t } = useTranslation();
  const { setEntity } = useFinder();
  const { open, onClose } = props;

  const onFormSubmit = (values) => {
    setEntity(values.entity);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <DialogTitle>
          <LabelIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
          {t('fileExplorer.dialogs.changeEntity.title', 'Change Entity')}
        </DialogTitle>
      </DialogHeader>
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText sx={{ fontSize: 14, mb: 1.6 }}>
                {t(
                  'fileExplorer.dialogs.changeEntity.subtitleDescription',
                  'The entity is a key where the types, files and folders are linked to. Changing this will result into loading a different configuration. Please make sure the correct key is configured.',
                )}
              </DialogContentText>
              <TextInput
                autoFocus
                fullWidth
                label={t('field.name', 'Name')}
                name="entity"
                size="small"
                margin="dense"
                validate={validators.isRequired}
              />
            </DialogContent>
            <DialogFooter>
              <Stack direction="row" spacing={1}>
                <Button size="small" onClick={onClose}>
                  {t('action.cancel', 'Cancel')}
                </Button>
                <LoadingButton
                  loading={false}
                  disabled={!valid}
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  {t('action.confirm', 'Confirm')}
                </LoadingButton>
              </Stack>
            </DialogFooter>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

ChangeEntityDialog.defaultProps = {
  open: false,
  onClose: () => false,
};

export default ChangeEntityDialog;
