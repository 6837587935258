import React from 'react';
import { useTheme } from '@mui/material/styles';
import FinderProvider from './Finder.context';
import FinderView from './FinderView';
import Styled from './Finder.style';

const Finder = (props) => {
  const theme = useTheme();

  const { selectable, editable, entity, onEntityChanged, currentLocation, onLocationSelected } = props;

  return (
    <FinderProvider selectable={selectable} editable={editable} entity={entity} currentLocation={currentLocation}>
      <Styled selectable={selectable} theme={theme}>
        <FinderView onEntityChanged={onEntityChanged} onLocationSelected={onLocationSelected} />
      </Styled>
    </FinderProvider>
  );
};

Finder.defaultProps = {
  selectable: false,
  editable: false,
  onEntityChanged: () => false,
};

export default Finder;
