import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SignService from 'infrastructure/services/Sign';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import Dropzone from 'components/Dropzone';
import StepperDialog from './components/StepperDialog';
import { useConnector } from 'infrastructure/hooks';

const UploadAndSignDocument = () => {
  const { t } = useTranslation();
  const { notifyError } = useErrorNotify();
  const { client } = useConnector();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState();
  const [signResponse, setSignResponse] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const rootCertificate = useSelector((state) => state.card.rootCertificate);
  const intermediateCertificate = useSelector((state) => state.card.intermediateCertificates);
  const nonRepudiationCertificate = useSelector((state) => state.card.nonRepudiationCertificate);

  const reader = useSelector((state) => state.card.reader);
  const currentModule = useSelector((state) => state.card.currentModule);

  const toggleDialog = () => {
    setOpen(!open);
  };

  const onFileAccepted = (file) => {
    setFile(file);
  };

  const getCertificate = (cert) => (cert?.certificates ? cert.certificates[0].certificate : cert?.certificate);

  // TODO Expand this to include all variants
  const getAlgorithm = (input) => {
    if (input.includes('sha512')) return 'SHA512';
    else if (input.includes('sha384')) return 'SHA384';
    else if (input.includes('sha256')) return 'SHA256';
    else return 'SHA256';
  };

  const onSign = async () => {
    setLoading(true);
    const c = client.generic(reader.id);
    c.allAlgoRefs(currentModule).then(
      async (res) => {
        let algo = getAlgorithm(res.data.ref);
        let certificateChain = [];
        for (let cert of nonRepudiationCertificate.certificates) {
          certificateChain.push(cert.certificate);
        }
        for (let cert of intermediateCertificate.certificates) {
          certificateChain.push(cert.certificate);
        }
        for (let cert of rootCertificate.certificates) {
          certificateChain.push(cert.certificate);
        }

        const body = {
          file,
          nonRepudiationCertificate: getCertificate(nonRepudiationCertificate),
          certificateChain: certificateChain,
          algorithm: algo,
        };

        const response = await SignService.uploadFile({ body });
        setSignResponse(response);
        toggleDialog();

        setLoading(false);
      },
      (err) => {
        setLoading(false);
        notifyError(err);
      },
    );
  };

  return (
    <Box>
      <Typography sx={{ mb: 1 }} variant="h6">
        {t('uploadAndSignDocumentAction.title', 'Upload and sign document')}
      </Typography>

      <Dropzone onFileAccepted={onFileAccepted} />

      <LoadingButton
        sx={{ mt: 1 }}
        loading={loading}
        disabled={!file}
        variant="contained"
        startIcon={<AssignmentTurnedInIcon />}
        onClick={onSign}
      >
        {t('uploadAndSignDocumentAction.button', 'Sign uploaded document')}
      </LoadingButton>

      <StepperDialog open={open} signResponse={signResponse} onClose={toggleDialog} />
    </Box>
  );
};

UploadAndSignDocument.defaultProps = {};

export default UploadAndSignDocument;
