import React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import DongleIcon from 'components/generic/components/DongleIcon';
import Styled from './Chambersign.style';

const ChambersignCard = () => {
  const theme = useTheme();

  return (
    <Styled theme={theme}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} alignItems="center" spacing={5}>
        <DongleIcon />
        <img className="card-dongle-logo" src="/assets/images/card-logos/chambersign.svg" alt="ChambersignCard" />
      </Stack>
    </Styled>
  );
};

export default ChambersignCard;
