import React from 'react';
import { useTheme } from '@mui/material/styles';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListCell from '../ListCell';

const FileNameCell = (props) => {
  const theme = useTheme();

  const { label, isDirectory, width, align, ...rest } = props;
  const Component = isDirectory ? FolderIcon : InsertDriveFileIcon;

  const style = {
    mr: 0.5,
    fontSize: 16,
  };

  if (isDirectory) {
    style.color = theme.palette.mode === 'dark' ? theme.palette.primary[300] : theme.palette.primary[600];
  }

  return (
    <ListCell className="ld__c" width={width} align={align} {...rest}>
      <Component sx={style} color="action" /> {label}
    </ListCell>
  );
};

FileNameCell.defaultProps = {
  width: 15,
  align: 'left',
};

export default FileNameCell;
