import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import validators from 'utils/validators';
import SendIcon from '@mui/icons-material/Send';
import { AppContainer } from 'components/Layouts';
import { TextInput, RadioGroup } from 'components/Inputs';
import PasswordInput from '../../components/Inputs/PasswordInput/PasswordInput';
import PartnerService from '../../infrastructure/services/PartnerService/PartnerService';
import { useDispatch } from 'react-redux';
import { setPartner } from '../../infrastructure/reducers/partner';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const findPartner = async (name, password) => {
    return await PartnerService.findPartner(name, password);
  };
  const onSubmit = async (values) => {
    findPartner(values.name, values.password).then((res) => {
      if (res !== null) {
        setError(null);

        dispatch(setPartner(res));
        navigate('/partners');
      } else {
        setError(t('login.error', 'Invalid credentials! Please try again'));
      }
    });
  };

  return (
    <AppContainer>
      <Paper variant="block" elevation={2}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h2">{t('page.login.title', 'Partner login')}</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {t(
              'page.login.subtitleDescription',
              'Here you can login with your partner credentials to request an authentication',
            )}
          </Typography>
        </Box>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={1}>
                <Stack spacing={2}>
                  <TextInput
                    fullWidth
                    label={t('field.name', 'Name')}
                    name="name"
                    variant="outlined"
                    size="small"
                    validate={validators.isRequired}
                  />
                  <PasswordInput
                    fullWidth
                    label={t('field.password', 'Password')}
                    name="password"
                    variant="outlined"
                    size="small"
                    validate={validators.isRequired}
                  />
                </Stack>
              </Stack>
              <Box sx={{ mt: 1 }}>
                <Button type="submit" color="primary" size="medium" variant="outlined" startIcon={<SendIcon />}>
                  {t('action.login', 'Login')}
                </Button>
              </Box>
            </form>
          )}
        </Form>
        {error && (
          <Alert sx={{ mt: 2 }} severity="error">
            {error}
          </Alert>
        )}
      </Paper>
    </AppContainer>
  );
};

export default Login;
