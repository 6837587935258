import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 3,
  backgroundColor:
    theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.05) : alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.1) : alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginBottom: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    fontSize: 20,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.6, 1, 0.6, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    width: '100%',
  },
}));

const CustomSearch = (props) => {
  const { t } = useTranslation();

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        fullWidth
        size="small"
        placeholder={t('placeholder.search...', 'Search...')}
        inputProps={{ 'aria-label': 'search' }}
        {...props}
      />
    </Search>
  );
};

CustomSearch.defaultProps = {};

export default CustomSearch;
