import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  certificate: null,
};

export const truststoreSlice = createSlice({
  name: 'truststore',
  initialState,
  reducers: {
    setCertificate: (state, action) => {
      state.certificate = action.payload;
    },
    clear: (state) => {
      state.certificate = null;
    },
  },
});

export const { setCertificate, clear } = truststoreSlice.actions;

export default truststoreSlice.reducer;
