import React from 'react';
import Box from '@mui/material/Box';
import { Trans } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import { Route } from 'react-router-dom';
import Contact from '../../../../pages/Contact';

const Installer = ({ icon, title, description, newVersion, osList }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const downloadInstaller = (item) => {
    window.open(item.uri, '_blank');
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasList = osList.length > 0;

  return (
    <Paper sx={{ flex: 1 }} variant="block" elevation={2}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        {icon} <Typography variant="h5">{title}</Typography>
      </Stack>

      <Typography variant="body2">{description}</Typography>

      <Button
        sx={{ mt: 2, borderRadius: '3px' }}
        fullWidth
        disabled={!hasList}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color="primary"
        size="medium"
        variant="contained"
        startIcon={hasList && <DownloadIcon />}
        endIcon={hasList && <ArrowDropDownRoundedIcon fontSize="small" sx={{ ml: -0.5 }} />}
        onClick={handleClick}
      >
        {hasList ? (
          <Trans i18nKey="action.download">Download</Trans>
        ) : (
          <Trans i18nKey="field.notSupported">Not supported</Trans>
        )}
      </Button>
      {hasList && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: { xs: 340, sm: 'auto' },
            },
          }}
        >
          {osList.map((item) => (
            <MenuItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: theme.spacing(1.2, 1.8),
              }}
              key={item.os}
              onClick={() => downloadInstaller(item)}
            >
              <Box sx={{ mr: theme.spacing(4) }} component="span">
                <Trans i18nKey={`field.osInstaller.${item.os.toLowerCase()}`} />
              </Box>
              <Box component="span">{newVersion}</Box>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Paper>
  );
};

Installer.defaultProps = {
  osList: [],
};

export default Installer;
