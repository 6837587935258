import React from 'react';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from 'components/dialogs/Dialog';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogFooter from 'components/dialogs/DialogFooter';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import PrintIcon from '@mui/icons-material/Print';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MouseIcon from '@mui/icons-material/Mouse';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import Search from 'components/Search';
import { blobToBase64 } from 'utils/helpers';
import useFinderActions from '../../hooks/useFinderActions';

const PrintFileDialog = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { notifyError } = useErrorNotify();
  const { downloadFile, listPrinters, printData } = useFinderActions();
  const [printers, setPrinters] = React.useState({ all: [], searched: [] });

  const onSearch = debounce((event) => {
    const { value } = event.target;

    const newList = printers.all.filter((item) => t(item.toLowerCase()).includes(value.toLowerCase()));

    setPrinters({ ...printers, searched: newList });
  }, 250);

  const onPrinterSelect = async (selectedPrinter) => {
    try {
      const { fileName } = props;

      const blob = await downloadFile(fileName);
      const rawData = await blobToBase64(blob);

      const response = await printData(selectedPrinter, rawData.substr(rawData.indexOf(',') + 1));

      // File should be printed
      if (response) {
        enqueueSnackbar(t('notification.success.filePrinted', 'Printing {{file}}', { file: fileName }), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          t('notification.success.filePrinted', 'Something went wrong wile printing {{file}}', { file: fileName }),
          { variant: 'error' },
        );
      }

      onClose();
    } catch (err) {
      notifyError(err);
    }
  };

  const fetchPrinters = async () => {
    try {
      const response = await listPrinters();
      setPrinters({ all: response, searched: response });
    } catch (err) {
      notifyError(err);
    }
  };

  React.useEffect(() => {
    fetchPrinters();
  }, []);

  const { open, onClose, fileName } = props;

  return (
    <Dialog max="600px" open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <DialogTitle>
          <PrintIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
          {t('fileExplorer.dialogs.printFile.title', 'Print {{ file }}', { file: fileName })}
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14, mb: 1.6 }}>
          {t(
            'fileExplorer.dialogs.changeEntity.subtitleDescription',
            'Please choose the printer that should print the file.',
          )}
        </DialogContentText>
        <Search fullWidth autoFocus onChange={onSearch} />
        <TableContainer sx={{ maxHeight: 400, minHeight: 300 }}>
          <Table stickyHeader size="small" aria-label="Modules">
            <TableHead>
              <TableRow>
                <TableCell>{t('field.name', 'Name')}</TableCell>
                <TableCell align="right">{t('field.actions', 'Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {printers.searched.map((printer) => (
                <TableRow key={printer} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {printer}
                  </TableCell>
                  <TableCell align="right">
                    <LoadingButton
                      loading={false}
                      color="secondary"
                      size="small"
                      variant="outlined"
                      startIcon={<MouseIcon />}
                      onClick={() => onPrinterSelect(printer)}
                      sx={{ fontSize: 10, 'svg:nth-of-type(1)': { fontSize: 14 } }}
                    >
                      {t('action.select', 'Select')}
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogFooter>
        <Button onClick={onClose}>{t('action.cancel', 'Cancel')}</Button>
      </DialogFooter>
    </Dialog>
  );
};

PrintFileDialog.defaultProps = {
  open: false,
  onClose: () => false,
  onSubmit: () => false,
};

export default PrintFileDialog;
