import React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Certificate from 'components/Certificate';
import ValidateCertificates from 'components/generic/components/ValidateCertificates';

const Certificates = () => {
  const { t } = useTranslation();
  const rootCertificate = useSelector((state) => state.card.rootCertificate);
  const authenticationCertificate = useSelector((state) => state.card.authenticationCertificate);
  const intermediateCertificates = useSelector((state) => state.card.intermediateCertificates);
  const nonRepudiationCertificate = useSelector((state) => state.card.nonRepudiationCertificate);
  const encryptionCertificate = useSelector((state) => state.card.encryptionCertificate);
  const issuerCertificate = useSelector((state) => state.card.issuerCertificate);
  const smartIDCertificate = useSelector((state) => state.card.smartIDCertificate);

  return (
    <Paper variant="block">
      <Typography sx={{ mb: 2 }} variant="h5">
        {t('template.certificates.title', 'Certificate details')}
      </Typography>

      <ValidateCertificates />

      <Certificate
        sx={{ mb: 2 }}
        title={t('template.certificates.nonRepudiationCertificate', 'Non repudiation certificate')}
        certificates={nonRepudiationCertificate?.certificates}
      />

      <Certificate
        sx={{ mb: 2 }}
        title={t('template.certificates.authenticationCertificate', 'Authentication certificate')}
        certificates={authenticationCertificate?.certificates}
      />

      <Certificate
        sx={{ mb: 2 }}
        title={t('template.certificates.encryptionCertificate', 'Encryption certificate')}
        certificates={encryptionCertificate?.certificates}
      />

      <Certificate
        sx={{ mb: 2 }}
        title={t('template.certificates.issuerCertificate', 'Issuer certificate')}
        certificates={issuerCertificate?.certificates}
      />

      <Certificate
        sx={{ mb: 2 }}
        title={t('template.certificates.intermediateCertificate', 'Intermediate certificate')}
        certificates={intermediateCertificates?.certificates}
      />

      <Certificate
        title={t('template.certificates.rootCertificate', 'Root certificate')}
        certificates={rootCertificate?.certificates}
      />
    </Paper>
  );
};

export default Certificates;
