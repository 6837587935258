import styled from '@emotion/styled';

const Styled = styled.div`
  .field {
    margin-bottom: 2.5px;
  }

  .field-row {
    display: flex;
  }

  .field__label {
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    line-height: 1.2;

    &.small {
      font-size: 8px;
    }
  }

  .field__value {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .front {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 5px 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #f8f9e6;
    color: rgba(45, 55, 72, 1);

    .top-icon-shape {
      width: 35px;
    }

    .title-top {
      font-size: 8px;
    }

    .title-middle {
      font-weight: 700;
      font-size: 10px;
      letter-spacing: -0.5px;
    }

    .title-bottom {
      font-size: 7px;
    }

    .card-content {
      display: flex;
      margin-top: 10px;
    }

    .card-content__left {
      margin-right: 20px;
    }

    .profile-picture {
      margin-bottom: 5px;
      font-size: 8px;
      max-height: 80%;
    }

    .card-content__middle {
      flex: 1;
    }

    .card-content__right {
      display: flex;
      align-items: flex-end;
    }

    .field-name {
      margin-bottom: 15px;
    }

    .crown-image {
      margin-bottom: 10px;
    }
  }

  .back {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 25px 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #f8f9e6;
    color: rgba(45, 55, 72, 1);
    display: flex;
    flex-direction: column;

    .back__top {
      flex: 1;
      max-width: 65%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .back__bottom {
      flex: 1;
      position: relative;
    }

    .signature-container {
      text-align: center;
      font-size: 14px;
      line-height: 1.1;
      margin-top: 15px;
    }

    .machine-readable {
      font-size: 1.5rem;
      line-height: 1;
      font-family: 'OCR-B', monospace;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;

export default Styled;
