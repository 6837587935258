import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-wrapper {
    position: relative;
  }

  .icon-wrapper__text {
    position: absolute;
    left: 9px;
    top: 28px;
    line-height: 1;
    font-size: 13px;
    font-weight: bold;
    color: ${theme.palette.mode === 'light' ? 'white' : 'rgb(97, 97, 97)'};
  }

  svg {
    width: 90px;
    fill: ${theme.palette.mode === 'dark' ? 'white' : 'rgb(97, 97, 97)'};
  }

  .dongle-icon__title {
    font-size: 16px;
  }
`,
);

export default Styled;
