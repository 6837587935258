import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme, isDragAccept, isDragReject, isFocused }) => `
    flex: 1;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${theme.palette.mode === 'light' ? '#fafafa' : 'rgba(225, 225, 225, 0.2)'};
    border-style: dashed;
    background-color: ${theme.palette.mode === 'light' ? '#fafafa' : 'transparent'};
    outline: none;
    transition: border 0.24s ease-in-out;

    .preview-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ${
      isDragAccept &&
      `
      border-color: ${
        theme.palette.mode === 'light' ? theme.palette.success['500'] : theme.palette.success['700']
      } !important;
    `
    }

    ${
      isDragReject &&
      `
      border-color: ${
        theme.palette.mode === 'light' ? theme.palette.error['500'] : theme.palette.error['700']
      } !important;
    `
    }

    ${
      isFocused &&
      `
      border-color: #2196f3;
    `
    }
`,
);

export default Styled;
