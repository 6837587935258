import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `

  .header-box {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    justify-content: space-between
  }

`,
);

export default Styled;
