import React from 'react';
import config from 'client/config';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider as MuiSnackbarProvider, useSnackbar } from 'notistack';

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton size="small" aria-label="delete" onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  );
};

const SnackbarProvider = ({ children }) => {
  const SnackbarCloseButtonAction = React.useCallback((key) => <SnackbarCloseButton snackbarKey={key} />, []);

  return (
    <MuiSnackbarProvider
      maxSnack={config.maxNotificationsStack}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={(key) => SnackbarCloseButtonAction(key)}
    >
      {children}
    </MuiSnackbarProvider>
  );
};

export default SnackbarProvider;
