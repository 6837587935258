import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Stack } from '@mui/material';

const InfoCard = ({ icon, body }) => {
  return (
    <Card variant="outlined" sx={{ borderColor: '#FFF4CB', backgroundColor: '#FFFCF5' }}>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Box>{icon}</Box>
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1">
            {body}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
