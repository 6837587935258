import 'react-simple-keyboard/build/css/index.css';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import NumericKeyboard from './components/NumericKeyboard';
import MobileKeyboard from './components/MobileKeyboard';
import Styled from './PinPad.style';

const PinPad = (props) => {
  const theme = useTheme();
  const keyboard = React.createRef();
  const [input, setInput] = React.useState();
  const [showPassword, setShowPassword] = React.useState(false);

  const { title, onSubmit, layout } = props;

  const onChange = (input) => {
    setInput(input);
  };

  const onChangeInput = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  const onKeyPress = (button) => {
    if (button === '{bksp}') {
      setInput(input.slice(0, input.length - 1));
    } else if (button === '{enter}' || button === '{return}') {
      onSubmit(input);
      setInput(null);
    }
  };

  const KeyboardComponent = layout === 'numpad' ? NumericKeyboard : MobileKeyboard;

  return (
    <Styled theme={theme}>
      <div className="keyboard__header">
        {title && (
          <Typography sx={{ fontSize: 10, mb: 1, fontWeight: 'light' }} variant="caption">
            {title}
          </Typography>
        )}

        <InputBase
          autoFocus
          autoComplete="off"
          className="calc-input"
          value={input}
          type={showPassword ? 'text' : 'password'}
          onChange={onChangeInput}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <KeyboardComponent onChange={onChange} onKeyPress={onKeyPress} />
    </Styled>
  );
};

PinPad.defaultProps = {
  layout: 'numpad',
  onSubmit: () => false,
};

export default PinPad;
