import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Styled from './DiplatCard.style';

const CrelanPaymentCard = () => {
  const theme = useTheme();

  const biometric = useSelector((state) => state.card.biometric);
  const picture = useSelector((state) => state.card.picture);

  let lawyerImage = '/assets/images/lawyer-person.png';

  if (picture?.picture) {
    lawyerImage = `data:image/png;base64,${picture?.picture}`;
  }

  return (
    <Styled theme={theme}>
      <Stack direction="row" spacing={1}>
        <img className="flag-be" src="/assets/images/belgium-flag.jpg" alt="Belgium Flag" />
        <Stack className="titles" direction="row" justifyContent="space-between">
          <div className="title">ADVOCAAT</div>
          <div className="title">AVOCAT</div>
          <div className="title">RECHTSANWALT</div>
          <div className="title">LAWYER</div>
        </Stack>
      </Stack>

      <div className="card-content">
        <div className="card-row">
          <div>
            <div className="italic">Naam/Name</div>
            <div className="italic">Voornamen/Given name</div>
          </div>
          <Box sx={{ ml: 1 }}>
            <div className="bold">{biometric?.name || 'De Maeyer'}</div>
            <div className="bold">
              {/* {biometric?.firstNames} {biometric?.thirdName} */}
              Joren
            </div>
          </Box>
        </div>

        <Stack className="chip" direction="row" spacing={1}>
          <div className="chip__triangle" />
          <img className="chip__img" src="/assets/images/credit-card.svg" alt="Credit card chip" />
        </Stack>

        <div className="valid-from-to">
          <div className="italic">Geldig tot / Valid until</div>
          <div className="bold">
            {biometric?.cardValidityDateBegin &&
              biometric?.cardValidityDateEnd &&
              `${biometric?.cardValidityDateBegin} - ${biometric?.cardValidityDateEnd}`}
          </div>
        </div>

        <div className="lawyer-icon">
          <img className="lawyer-icon__img" src="/assets/images/belgium-lawyer-icon.svg" alt="Lawyer icon" />
          <div className="lawyer-icon__content">
            <div className="content-text">Orde van vlaamse balies</div>
            <div className="content-text">Staatsbladstraat 8</div>
            <div className="content-text">B-1000 Brussel</div>
          </div>
        </div>

        <img
          className="profile-image"
          src={lawyerImage}
          alt={`${biometric?.name} ${biometric?.firstNames} ${biometric?.thirdName}`}
        />
      </div>
    </Styled>
  );
};

export default CrelanPaymentCard;
