import React from 'react';
import Typography from '@mui/material/Typography';

// TODO
// Improvement/NiceToHave => if the modules.length > 0, use a select.
const ProposedModules = (props) => {
  const { modules, descriptions } = props;

  // if (modules.length === 1) {
  return (
    <>
      <Typography sx={{ fontSize: 12, lineHeight: 1 }} variant="h5">
        {modules[0]}
      </Typography>
      <Typography variant="overline">{descriptions[0]}</Typography>
    </>
  );
  // }
};

ProposedModules.defaultProps = {
  modules: [],
  descriptions: [],
};

export default ProposedModules;
