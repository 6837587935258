import React, { useState } from 'react';

import { Alert, AlertTitle, Box, Button, CircularProgress, Link, Stack, Typography } from '@mui/material';
import SmartIDService from 'infrastructure/services/SmartID';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CodeBlock, dracula } from 'react-code-blocks';
import ValidationReportComponent from './ValidationReportComponent';
import SessionContext from './SessionContext';
import TrackerDetails from './TrackerDetails';

const SmartIDComponent = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [auth, setAuth] = useState(null);
  const [session, setSession] = useState(null);

  React.useEffect(() => {
    fetchData();
  }, [props.correlationId]);

  const fetchData = async () => {
    setAuth(null);
    setError(null);
    setLoading(true);
    try {
      let out = await SmartIDService.getSessionResult(props.correlationId);
      let session = out.data;
      setSession(session);
      setAuth(JSON.stringify(session, null, 4));
    } catch (error) {
      setTimeout(() => {
        setError(null);
        props.onBack();
      }, 2000);
      if (error.statusCode) {
        setError(error);
      } else {
        setError(error);
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return (
      <Box>
        <Alert severity="warning">
          <AlertTitle>{t('smartid.error.title', 'Could not retrieve session information')}</AlertTitle>
          {t('smartid.error.body', 'The session result you are looking for could not be found')}
        </Alert>
      </Box>
    );
  }
  return (
    <Stack sx={{ marginY: '10px' }}>
      {session != null && (
        <>
          <Box>
            <Typography variant="body1">
              {t(
                'smartid.result.title',
                'This is the result of the requested authentication. Below you will find a detailed report.',
              )}
            </Typography>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            {session && session.isSuccess && (
              <Alert severity="success">
                {t(
                  'smartid.result.success',
                  'The authentication request was successfull. You can find the digest and signed digest in the information below',
                )}
              </Alert>
            )}
            {session && !session.isSuccess && (
              <Alert severity="error">{t('smartid.result.failed', 'The authentication request failed.')}</Alert>
            )}
          </Box>

          <Box>
            {session && (
              <ValidationReportComponent
                correlationId={session.sessionContext.correlation_id}
              ></ValidationReportComponent>
            )}
          </Box>

          <Box>
            <SessionContext session={session.sessionContext}></SessionContext>
          </Box>

          <Box>
            <TrackerDetails tracker={session.sessionTracker}></TrackerDetails>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default SmartIDComponent;
