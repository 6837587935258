import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useFinder from './hooks/useFinder';
import useFinderActions from './hooks/useFinderActions';
import Entity from './views/Entity';
import Types from './views/Types';
import Header from './views/Header';
import Content from './views/Content';
import helpers from './utils/helpers';

const FinderView = (props) => {
  const { t } = useTranslation();
  const {
    selectable,
    entity,
    type,
    setType,
    types,
    setTypes,
    relPaths,
    setContent,
    currentLocation,
    selectedRelPaths,
  } = useFinder();
  const { fetchTypes, fetchDirectoryContent } = useFinderActions();

  const handleSelectConfirm = () => {
    const { onLocationSelected } = props;

    onLocationSelected({ entity, type, relPaths: selectedRelPaths });
  };

  React.useEffect(() => {
    props.onEntityChanged(entity);
  }, [entity]);

  React.useEffect(() => {
    fetchTypes();
  }, [entity]);

  React.useEffect(() => {
    if (!type && types.length > 0) {
      setType(types[0].type);
    }
  }, [type, types]);

  React.useEffect(() => {
    if (!type) {
      setType(null);
      setTypes([]);
      setContent([]);
      return;
    }

    fetchDirectoryContent();
  }, [type, relPaths]);

  const isDisabled = React.useMemo(() => {
    if (!selectable) {
      return false;
    }

    const newLocation = {
      entity,
      type,
      relPaths: selectedRelPaths,
    };

    if (helpers.isSameLocation(currentLocation, newLocation)) {
      return true;
    }

    return false;
  });

  return (
    <Paper variant="block" className="fx__paper">
      <Stack className="fx__paper__top" direction="row" justifyContent="space-between">
        <Typography className="fx__paper__top__left" variant="caption">
          {t('field.applicationId', 'Application ID')}
        </Typography>
        <Typography className="fx__paper__top__right" variant="caption">
          {window.location.origin}
        </Typography>
      </Stack>
      <Box className="fx__paper__container">
        <Box className="fx__paper__nav">
          <Entity />

          <Types />
        </Box>
        <Box className="fx__paper__content">
          <div className="fx__paper__content__top">
            <Header />

            <Content />
          </div>

          {selectable && (
            <Box className="fx__paper__content__actions">
              <Button
                disabled={isDisabled}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSelectConfirm}
              >
                {t('action.select', 'Select')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

FinderView.defaultProps = {
  onLocationSelected: () => false,
};

export default FinderView;
