import React from 'react';
import { constructMachineReadableStrings } from 'utils/helpers';

const CardBack = ({ hasNoData, biometric }) => {
  const mrz = React.useMemo(() => constructMachineReadableStrings(biometric), [biometric]);

  // 96.05-247.26
  // 96080524726814383991
  // 00000000000000000000

  return (
    <div className="back">
      <div className="card-content">
        <div className="card-row-2">
          <div>
            <div className="italic">Identificatienummer van het Rijksregister</div>
            <div className="italic">Identification number of the National Register</div>
            <div className="bold">
              {!hasNoData
                ? `${biometric?.nationalNumber.substr(0, 2)}.${biometric?.nationalNumber.substr(
                    4,
                    2,
                  )}-${biometric?.nationalNumber.substr(6, 3)}.${biometric?.nationalNumber.substr(9, 2)}`
                : `00.00-000.00`}
            </div>
          </div>
          <div>
            <div className="barcode">
              {!hasNoData ? `${biometric?.nationalNumber}${biometric?.cardNumber.substr(3)}` : `00000000000000000000`}
            </div>
          </div>
        </div>
        <div className="card-row-2 mt-5">
          <div>
            <div className="italic">Plaats van afgifte / Place of issue</div>
            <div className="bold">{biometric?.cardDeliveryMunicipality || 'Brussel'}</div>
          </div>
        </div>
        <div className="card-row-2 mt-5">
          <div>
            <div className="italic">Overheid / Authority</div>
          </div>
        </div>
      </div>

      <div className="machine-readable">
        <div>{mrz[0]}</div>
        <div>{mrz[1]}</div>
        <div>{mrz[2]}</div>
      </div>
    </div>
  );
};

export default CardBack;
