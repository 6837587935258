import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CardFront from './components/Front';
import CardBack from './components/Back';
import Styled from './PortugalIdCard.style';

const PortugalIdCard = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Balthazar&family=Lora&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <Styled theme={theme}>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} md="auto">
            <CardFront />
          </Grid>
          <Grid item xs={12} md="auto">
            <CardBack />
          </Grid>
        </Grid>
      </Styled>
    </>
  );
};

export default PortugalIdCard;
