import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import MouseIcon from '@mui/icons-material/Mouse';
import Typography from '@mui/material/Typography';
import IsPinPadReader from 'components/icons/IsPinPadReader';
import IsCardInReader from 'components/icons/IsCardInReader';
import { copyTextToClipboard } from 'utils/helpers';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  setReader,
  setCurrentModule,
  setApplicationData,
  setApplications,
  setPaymentCertificates,
  setAllData,
  setAllCertificates,
} from 'infrastructure/reducers/card';
import { useReader, useErrorNotify } from 'infrastructure/hooks';
import ProposedModules from './components/ProposedModules';
import Modules from './components/Modules';
import PinPadStepperDialog from './components/PinPadStepperDialog';

const ReaderItem = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifyError } = useErrorNotify();
  const { getAllCerts, getAllData, getAllPaymentData } = useReader();
  const [overruledModule, setOverruledModule] = React.useState(null);
  const [showPinPad, setShowPinPad] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const pkcs11ConfigSet = useSelector((state) => state.card.pkcs11ConfigSet);

  const copy = (text) => {
    copyTextToClipboard(text);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'), {
      variant: 'info',
    });
  };

  const isPaymentModule = (currentModule) => currentModule === 'emv' || currentModule === 'crelan';
  const isPaceModule = (currentModule) => currentModule === 'luxeid';

  const getPaymentData = async (reader, module) => {
    const { applicationData, applications, certificates } = await getAllPaymentData(reader.id, module);

    dispatch(setApplicationData(applicationData));
    dispatch(setApplications(applications));

    if (certificates) {
      dispatch(setPaymentCertificates(certificates));
    }

    navigate('/app/card-info');
  };

  // We only need one promise to resolve in order to continue.
  // With Promise.All its all or nothing, thats why we always resolve the promise and handle the errors inside here.
  // This way its much faster since the promises are executed synchronously.
  const getDefaultTokenData = async (reader, module) => {
    const [tokenData, certificates] = await Promise.all([
      getAllData(reader.id, module),
      getAllCerts(reader.id, module),
    ]);

    // Show error notifications for both failed promises.
    if (!tokenData.success && !certificates.success) {
      notifyError(tokenData.res);
      notifyError(certificates.res);
      return;
    }

    if (tokenData.success) {
      dispatch(setAllData(tokenData.res));
    }

    if (certificates.success) {
      dispatch(setAllCertificates(certificates.res));
    }

    navigate('/app/card-info');
  };

  const handlePinPadCancel = () => {
    setShowPinPad(false);
    dispatch(setReader(null));
    dispatch(setCurrentModule(null));
  };

  const onReaderSelect = async (reader) => {
    try {
      setLoading(true);
      const module = overruledModule || reader.card.modules[0];

      dispatch(setReader(reader));
      dispatch(setCurrentModule(module));

      if (isPaymentModule(module)) {
        await getPaymentData(reader, module);
      } else if (isPaceModule(module)) {
        setShowPinPad(true);
      } else {
        await getDefaultTokenData(reader, module);
      }

      setLoading(false);
    } catch (err) {
      notifyError(err);
      setLoading(false);
    }
  };

  const onModuleSelected = (m) => {
    setOverruledModule(m);
  };

  const { reader } = props;
  const isCardInserted = reader?.card;

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          <Typography sx={{ fontSize: 14, lineHeight: 1 }} variant="h5">
            {reader?.name}
          </Typography>
          <Typography sx={{ whiteSpace: 'normal' }} variant="overline">
            {reader?.id}
          </Typography>
          <IconButton onClick={() => copy(reader?.id)} variant="outlined" size="small">
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </TableCell>
        <TableCell>
          <Modules disabled={!isCardInserted} onModuleSelected={onModuleSelected} />
        </TableCell>
        <TableCell>
          {isCardInserted && <ProposedModules modules={reader.card.modules} descriptions={reader.card.description} />}
        </TableCell>
        <TableCell align="center">
          <IsPinPadReader no={!reader.pinpad} />
        </TableCell>
        <TableCell align="center">
          <IsCardInReader no={!isCardInserted} />
        </TableCell>
        <TableCell align="right">
          <LoadingButton
            loading={loading}
            disabled={!isCardInserted && overruledModule == 'pkcs11' && pkcs11ConfigSet}
            color="primary"
            size="small"
            variant="outlined"
            startIcon={<MouseIcon />}
            onClick={() => onReaderSelect(reader)}
          >
            {t('action.select', 'Select')}
          </LoadingButton>
        </TableCell>
      </TableRow>
      <PinPadStepperDialog open={showPinPad} onClose={handlePinPadCancel} />
    </>
  );
};

export default ReaderItem;
