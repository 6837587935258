import 'core-js';
import 'raf/polyfill';
import 'moment/locale/nl';
import 'moment/locale/fr';

import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import createStore from 'infrastructure/store/createStore';
import { PersistGate } from 'redux-persist/integration/react';
import ThemeProvider from 'infrastructure/providers/Theme';
import SnackBarProvider from 'infrastructure/providers/SnackBar';
import GetClientConfigWrapper from 'components/GetClientConfigWrapper';
import { ClientProvider } from 'infrastructure/hooks/useClient/useClient';
import { ConnectorProvider } from 'infrastructure/hooks/useConnector';
import GlobalStyle from 'components/GlobalStyle';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import pkgJson from '../package.json';
import i18n from './i18n';
import App from './app';

const history = createBrowserHistory();

const Setup = () => {
  const { store, persistor } = createStore(history);

  // React works like a shell with different layers, each layer(provider) can have
  // its state(memory), each layer adds its own context and functionality to the application
  //
  // Be aware that each layer can potentially use a "Hook" which can trigger re-renders, be careful
  // to not make it so that you can have a infinite re-render loop(comparable to circular dependency).
  // the provider/redux thing, stores all application state (see RootReducer)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <GlobalStyle>
              <SnackBarProvider>
                <ClientProvider>
                  <GetClientConfigWrapper>
                    <ConnectorProvider>
                      <App />
                    </ConnectorProvider>
                  </GetClientConfigWrapper>
                </ClientProvider>
              </SnackBarProvider>
            </GlobalStyle>
          </ThemeProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Setup />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
