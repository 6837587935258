import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppContainer } from 'components/Layouts';
import GenericPaymentTemplate from 'components/generic/templates/GenericPayment';
import BeIdTemplate from 'components/generic/templates/BeId';
import GenericTokenTemplate from 'components/generic/templates/GenericToken';
import DefaultChipCard from 'components/generic/cards/DefaultChipCard';
import CamerfirmaCard from 'components/generic/cards/CamerfirmaCard';
import CertignaCard from 'components/generic/cards/CertignaCard';
import CertinomisCard from 'components/generic/cards/CertinomisCard';
import ChambersignCard from 'components/generic/cards/ChambersignCard';
import CrelanPaymentCard from 'components/generic/cards/CrelanPaymentCard';
import EHerkenningCard from 'components/generic/cards/EHerkenningCard';
import IdemiaCard from 'components/generic/cards/IdemiaCard';
import IsabelPaymentCard from 'components/generic/cards/IsabelPaymentCard';
import OberthurCard from 'components/generic/cards/OberthurCard';
import LuxtrustCard from 'components/generic/cards/LuxtrustCard';
import SafenetCard from 'components/generic/cards/SafenetCard';
import YubikeyCard from 'components/generic/cards/YubikeyCard';
import DiplatCard from 'components/generic/cards/DiplatCard';
import LuxIdCard from 'components/generic/cards/LuxIdCard';
import PortugalIdCard from 'components/generic/cards/PortugalIdCard';
import DNI4Card from 'components/generic/cards/DNI4Card';
import DNI3Card from 'components/generic/cards/DNI3Card';
import { clear } from 'infrastructure/reducers/card';

const CardInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRead = useSelector((state) => state.card.isRead);
  const currentModule = useSelector((state) => state.card.currentModule);

  const renderPageContent = React.useMemo(() => {
    if (!isRead) {
      return (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body1">
            {t(
              'errorBlock.connector.noCardRead.subtitleDescription',
              'Please read a card first before trying to access this page.',
            )}
          </Typography>
        </Box>
      );
    }

    switch (currentModule) {
      case 'beid': {
        return <BeIdTemplate />;
      }

      case 'emv': {
        return <GenericPaymentTemplate />;
      }

      case 'crelan': {
        return <GenericPaymentTemplate cardHeader={<CrelanPaymentCard />} />;
      }

      case 'airbus': {
        return <GenericTokenTemplate cardHeader={<DefaultChipCard />} />;
      }

      case 'aventra': {
        return <GenericTokenTemplate cardHeader={<DefaultChipCard />} />;
      }

      case 'camerfirma': {
        return <GenericTokenTemplate cardHeader={<CamerfirmaCard />} />;
      }

      case 'certigna': {
        return <GenericTokenTemplate cardHeader={<CertignaCard />} />;
      }

      case 'certinomis': {
        return <GenericTokenTemplate cardHeader={<CertinomisCard />} />;
      }

      case 'chambersign': {
        return <GenericTokenTemplate cardHeader={<ChambersignCard />} />;
      }

      case 'eherkenning': {
        return <GenericTokenTemplate cardHeader={<EHerkenningCard />} />;
      }

      case 'idemia': {
        return <GenericPaymentTemplate cardHeader={<IdemiaCard />} />;
      }

      case 'isabel': {
        return <GenericPaymentTemplate cardHeader={<IsabelPaymentCard />} />;
      }

      case 'jcop3': {
        return <GenericTokenTemplate cardHeader={<DefaultChipCard />} />;
      }

      case 'luxtrust': {
        return <GenericTokenTemplate cardHeader={<LuxtrustCard />} />;
      }

      case 'oberthur': {
        return <GenericTokenTemplate cardHeader={<OberthurCard />} />;
      }

      case 'safenet': {
        return <GenericTokenTemplate cardHeader={<SafenetCard />} />;
      }

      case 'yubikey': {
        return <GenericTokenTemplate cardHeader={<YubikeyCard />} />;
      }

      case 'diplad': {
        return <GenericTokenTemplate cardHeader={<DiplatCard />} />;
      }

      case 'luxeid': {
        return <GenericTokenTemplate cardHeader={<LuxIdCard />} />;
      }

      case 'dni-3.0': {
        return <GenericTokenTemplate cardHeader={<DNI3Card />} />;
      }

      case 'dni-4.0': {
        return <GenericTokenTemplate cardHeader={<DNI4Card />} />;
      }

      case 'portugese': {
        return <GenericTokenTemplate cardHeader={<PortugalIdCard />} />;
      }

      default: {
        return <GenericTokenTemplate />;
      }
    }
  }, [isRead, currentModule]);

  React.useEffect(() => {
    // componentDidUnMount, clean the reducer.
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <AppContainer>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
        <Typography variant="h2">{t('page.cardInfo.title.cardInformation', 'Card information')}</Typography>
        <Button variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          {t('action.goBack', 'Go back')}
        </Button>
      </Stack>
      {renderPageContent}
    </AppContainer>
  );
};

export default CardInfo;
