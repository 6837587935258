import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

const Styled = styled.div(
  ({ theme }) => `
  display: flex;
  min-height: 100vh;
  background: ${theme.palette.mode === 'dark' && theme.palette.primaryDark[900]};

  .app-layout__appBar {
    padding: ${theme.spacing(1, 2)};
    transition: ${theme.transitions.create('width')};
    box-shadow: none;
    backdrop-filter: blur(20px);
    border-style: solid;
    border-color: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primary[100], 0.08) : '#ddd'};
    border-width: 0;
    border-bottom-width: thin;
    border-radius: 0;
    background: ${theme.palette.mode === 'dark' ? alpha(theme.palette.primaryDark[900], 0.7) : 'rgba(255,255,255,0.7)'};
    color: ${theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[800]};

    ${theme.breakpoints.up('lg')} {
      width: calc(100% - var(--MuiDocs-navDrawer-width));
    }
  }

  .nav-toggle-icon-button {
    ${theme.breakpoints.up('lg')} {
      display: none;
    }
  }

  .growing-div {
    flex: 1 1 auto;
  }

  .app-layout__nav-drawer {
    ${theme.breakpoints.up('lg')} {
      flex-shrink: 0;
      width: var(--MuiDocs-navDrawer-width);
    }
  }

  .app-layout__main {
    display: flex;
    width: 100%;
  }

  .app-layout__container__inner {
    flex: 1;
  }
`,
);

export default Styled;
