import React from 'react';
import { Field } from 'react-final-form';
import TextField from '@mui/material/TextField';

export const DefaultPasswordInput = (props) => {
  const { type, name, value, onChange, onBlur, onFocus, error, helperText, ...rest } = props;

  return (
    <TextField
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={!!error}
      helperText={error || helperText}
      {...rest}
    />
  );
};

DefaultPasswordInput.defaultProps = {
  type: 'password',
  onBlur: () => false,
  onFocus: () => false,
  onChange: () => false,
  helperText: ' ',
};

const PasswordInputWithField = ({ name, validate, type, ...rest }) => {
  return (
    <Field
      name={name}
      type={type}
      validate={validate}
      render={({ input, meta: { touched, error } }) => (
        <DefaultPasswordInput type={type} error={touched && error ? error : false} {...input} {...rest} />
      )}
    />
  );
};

export default PasswordInputWithField;
