import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* IE 11 Support */
  padding: 5vh 0;
  min-height: 100%;

  .error__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#E0E3E7'};
  }

  .error__container {
    width: 100%;
    max-width: 520px;
    position: relative;

    ${theme.breakpoints.down('sm')} {
      padding: 0 16px;
    }
  }

  .error__content {
    max-height: 75vh;
    position: relative;
    overflow: scroll;
  }

  .error__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 40px;
  }

  .error__logo--top {
    margin-bottom: 25px;
  }

  .error__logo--bottom {
    margin-top: 25px;
  }

  .error__paper {
    padding: 25px 30px;
    text-align: center;
    overflow: scroll;
  }

  .error__title {
  }

  .error__description {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .error__content__header {
    margin-bottom: 25px;
  }

  .error__content__body {
    margin-bottom: 30px;
  }
`,
);

export default Styled;
