import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const CardFront = () => {
  const biometric = useSelector((state) => state.card.biometric);
  const picture = useSelector((state) => state.card.picture);

  return (
    <div className="front">
      <div className="front__left">
        <img className="front__left__icon" src="/assets/images/portugal-shape-icon.svg" alt="portugal-shape-icon" />

        <div className="chip">
          <div className="chip__triangle" />
          <img className="chip__image" src="/assets/images/credit-card.svg" alt="chip-icon" />
        </div>
      </div>
      <div className="front__right">
        <div className="chip__right__header">
          <div className="chip__right__header__title">
            Cartāo de cidadào <br />
            Citizen card
          </div>
          <div className="country">
            <div className="country__title">portugal</div>
            <Stack
              className="portugal"
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={0.5}
            >
              <div className="country__subtitle">republica portuguesa</div>
              <div className="country__subtitle">portuguese republic</div>
            </Stack>
          </div>
        </div>
        <div className="chip__right__content">
          <div className="field withMarginBottom with-border-top">
            <div className="field__label">APELIDO(S) Surname</div>
            <div className="field__value">{biometric?.name}</div>
          </div>
          <div className="field withMarginBottom with-border-top">
            <div className="field__label">Nome(S) Given names</div>
            <div className="field__value">
              {biometric?.firstNames} {biometric?.thirdName}
            </div>
          </div>

          <Stack className="content-wrapper" direction="row" spacing={1.5}>
            <div className="content-wrapper__left">
              <div className="field-row with-border-top">
                <div className="field field-item with-border-right">
                  <div className="field__label small">
                    Sexo <br /> Sex
                  </div>
                  <div className="field__value">{biometric?.sex}</div>
                </div>
                <div className="field field-item with-border-right">
                  <div className="field__label small">
                    Altura <br /> Height
                  </div>
                  <div className="field__value">1,64</div>
                </div>
                <div className="field field-item with-border-right">
                  <div className="field__label small">
                    Nacionalidade <br /> Nationality
                  </div>
                  <div className="field__value">{biometric?.nationality.toUpperCase()}</div>
                </div>
                <div className="field field-item">
                  <div className="field__label small">Data de nascimento Date of birth</div>
                  <div className="field__value">27 08 1990</div>
                </div>
              </div>

              <div className="field-row with-border-top">
                <div className="field field-item with-border-right">
                  <div className="field__label small">
                    N documento Document No <br /> N. ID civil | Civil ID No.
                  </div>
                  <div className="field__value">
                    {`${biometric?.cardNumber.substr(0, 3)}-${biometric?.cardNumber.substr(
                      3,
                      7,
                    )}-${biometric?.cardNumber.substr(10, 2)}`}
                  </div>
                </div>
                <div className="field field-item with-border-right">
                  <div className="field__label small">
                    Data de validade <br /> Expiry date
                  </div>
                  <div className="field__value">{`${biometric?.cardValidityDateEnd}`}</div>
                </div>
              </div>
            </div>
            <div className="content-wrapper__right">
              <img
                className="profile-picture"
                src={`data:image/png;base64,${picture?.picture}`}
                alt={`${biometric?.name} ${biometric?.firstNames} ${biometric?.thirdName}`}
              />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default CardFront;
