import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AppContainer } from 'components/Layouts';
import useConnector from 'infrastructure/hooks/useConnector';
import Loading from 'components/Loading';
import ErrorBlock from 'components/ErrorBlock';
import ReaderList from './ReaderList';

const Readers = () => {
  const { t } = useTranslation();
  const { client } = useConnector();
  const [loading, setLoading] = React.useState(false);
  const [readers, setReaders] = React.useState([]);
  const [error, setError] = React.useState();

  const fetchReaders = () => {
    setLoading(true);

    client
      .core()
      .readers()
      .then(
        (res) => {
          setReaders(res.data);
          setError(false);
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
        },
      );
  };

  React.useEffect(() => {
    fetchReaders();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <ErrorBlock
          title={t('errorBlock.reader.default.title', 'Something went wrong')}
          subtitle={t(
            'errorBlock.reader.default.subtitleDescription',
            'Something went wrong while loading the readers, please try again later or contact support.',
          )}
          actions={
            <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={fetchReaders}>
              {t('errorBlock.reader.default.button', 'Try again')}
            </Button>
          }
        />
      );
    }

    if (readers.length === 0) {
      return (
        <ErrorBlock
          title={t('errorBlock.reader.noReaders.title', 'No readers detected')}
          subtitle={t(
            'errorBlock.reader.noReaders.subtitleDescription',
            'Please check if the reader has been plugged in correctly. You can try plugging it in and out again.',
          )}
          actions={
            <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={fetchReaders}>
              {t('errorBlock.reader.noReaders.button', 'Search again')}
            </Button>
          }
        />
      );
    }

    return <ReaderList readers={readers} onRefresh={fetchReaders} />;
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

export default Readers;
