import styled from '@emotion/styled';

const Styled = styled.div`
  .card-dongle-logo {
    width: 175px;
  }

  .icon-wrapper__text {
    background: gold;
    border-radius: 50%;
    padding: 7px;
    left: 20px !important;
    top: 22px !important;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Styled;
