import React from 'react';
import { useTranslation } from 'react-i18next';
import useConnector from 'infrastructure/hooks/useConnector';
import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from 'components/Loading';
import { AppContainer } from 'components/Layouts';
import ErrorBlock from 'components/ErrorBlock';
import DeviceInfo from './components/DeviceInfo';
import UserInfo from './components/UserInfo';
import DependencyInformation from './components/DependencyInformation';
import DownloadInfo from './components/DownloadInfo';
import CorsInfo from './components/CorsInfo';
import Pkcs11Config from './components/Pkcs11Config';
import TransactionInfo from './components/TransactionInfo';
import AtrInfo from './components/AtrInfo';

const Admin = () => {
  const { client } = useConnector();
  const { t } = useTranslation();
  const [version, setVersion] = React.useState();
  const [info, setInfo] = React.useState();
  const [atrList, setAtrList] = React.useState();
  const [transactionList, setTransactionList] = React.useState();
  const [loadingInfo, setLoadingInfo] = React.useState(true);
  const [loadingVersion, setLoadingVersion] = React.useState(true);
  const [error, setError] = React.useState(false);

  const fetchAtrList = async () => {
    try {
      let atrList = await client.core().getAtrList();
      setAtrList(atrList.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTransactions = async () => {
    try {
      let res = await client.core().getTransactions();
      if (res.data) {
        setTransactionList(res.data);
      } else {
        setTransactionList([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setLoadingInfo(true);
    setLoadingVersion(true);

    await fetchTransactions();
    await fetchAtrList();

    client
      .core()
      .version()
      .then(
        (res) => {
          setVersion(res);
          setLoadingVersion(false);
        },
        (err) => {
          setError(err);
          setLoadingVersion(false);
        },
      );

    client
      .core()
      .info()
      .then(
        (info) => {
          setInfo(info);
          setLoadingInfo(false);
        },
        (err) => {
          setError(err);
          setLoadingInfo(false);
        },
      );
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const renderContent = () => {
    if (loadingInfo || loadingVersion) {
      return <Loading />;
    }

    if (error) {
      return (
        <ErrorBlock
          title={t('errorBlock.default.title', 'Whoops! Something went wrong')}
          subtitle={t(
            'errorBlock.default.subtitleDescription',
            'Something went wrong while fetching the data, please try again..',
          )}
          actions={
            <Button color="primary" size="medium" variant="outlined" startIcon={<ReplayIcon />} onClick={fetchData}>
              {t('errorBlock.default.submit', 'Try again')}
            </Button>
          }
        />
      );
    }

    return (
      <Box>
        <DeviceInfo info={info} version={version} />

        <Box sx={{ mt: 3 }}>
          <UserInfo info={info} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <DependencyInformation info={info} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <DownloadInfo />
        </Box>

        <Box sx={{ mt: 3 }}>
          <CorsInfo info={info} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <TransactionInfo data={transactionList} refresh={fetchTransactions} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <AtrInfo data={atrList} refresh={fetchAtrList} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Pkcs11Config />
        </Box>
      </Box>
    );
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

export default Admin;
