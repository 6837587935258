import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  width: 100%;

  .keyboard__header {
    background-color: ${theme.palette.mode === 'dark' ? theme.palette.primaryDark[900] : '#e5e5e5'};
    padding: 16px 20px 10% 16px;
    /* min-height: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
  }

  .calc-input {
    margin-top: 5px;

    input {
      font-size: 42px;
      font-weight: bold;
      padding: 0;
    }
  }

  .simple-keyboard.myTheme1 {
    background-color: ${theme.palette.mode === 'dark' ? theme.palette.primaryDark[900] : '#fff'};
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .simple-keyboard.myTheme1 .hg-button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.palette.mode === 'dark' ? 'rgb(8 20 34)' : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? '#06101a;' : '#ddd'};
    color: ${theme.palette.mode === 'dark' ? 'white' : 'black'};
  }

  .simple-keyboard.myTheme1 .hg-button:active {
    background: ${theme.palette.mode === 'dark' ? '#06101a;' : '#ddd'};
    color: white;
  }

  .red {
    background-color: ${
      theme.palette.mode === 'light' ? theme.palette.error['500'] : theme.palette.error['700']
    } !important;
  }

  .green {
    background-color: ${
      theme.palette.mode === 'light' ? theme.palette.success['500'] : theme.palette.success['700']
    } !important;
  }


  .mobile-keyboard {
    .hg-rows {

      &:last-child {
        .hg-button-space {
          flex-grow: 10;
        }
      }
    }
  }
`,
);

export default Styled;
