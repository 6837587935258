import { padEnd, truncate, sum, map, join, split } from 'lodash';

export const fallbackCopyConsentToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyConsentToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
};

export const pad = (string) => {
  return padEnd(truncate(string, { length: 30, omission: '' }), 30, '<');
};

export const calculate = (string) => {
  const dict = {
    '<': 0,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    A: 10,
    B: 11,
    C: 12,
    D: 13,
    E: 14,
    F: 15,
    G: 16,
    H: 17,
    I: 18,
    J: 19,
    K: 20,
    L: 21,
    M: 22,
    N: 23,
    O: 24,
    P: 25,
    Q: 26,
    R: 27,
    S: 28,
    T: 29,
    U: 30,
    V: 31,
    W: 32,
    X: 33,
    Y: 34,
    Z: 35,
  };
  return (
    sum(
      map(
        map(string, (letter) => {
          return dict[letter.toUpperCase()];
        }),
        (val, index) => {
          let weighted = val;
          switch (index % 3) {
            case 0:
              weighted = val * 7;
              break;
            case 1:
              weighted = val * 3;
              break;
            case 2:
              break;
          }
          return weighted;
        },
      ),
    ) % 10
  );
};

export const constructMachineReadableStrings = (rnData) => {
  const mrs = [];

  if (
    rnData?.cardNumber &&
    rnData?.sex &&
    rnData?.nationality &&
    rnData?.cardValidityDateEnd &&
    rnData?.cardValidityDateBegin &&
    rnData?.nationalNumber
  ) {
    // First line
    const prefix = 'ID';
    let first = 'BEL' + rnData.cardNumber.substr(0, 9) + '<' + rnData.cardNumber.substr(9);
    first += calculate(first);
    first = pad(prefix + first);
    mrs.push(first.toUpperCase());

    // Second line
    let second = rnData.nationalNumber.substr(0, 6);
    second += calculate(second);
    second += rnData.sex;
    const validity =
      rnData.cardValidityDateEnd.substr(8, 2) +
      rnData.cardValidityDateEnd.substr(3, 2) +
      rnData.cardValidityDateEnd.substr(0, 2);
    second += validity + calculate(validity);
    second += rnData.nationality.substr(0, 3);
    second += rnData.nationalNumber;
    const finalCheck =
      rnData.cardNumber.substr(0, 10) + rnData.nationalNumber.substr(0, 6) + validity + rnData.nationalNumber;
    second += calculate(finalCheck);
    second = pad(second);
    mrs.push(second.toUpperCase());

    // Third line
    let third =
      join(split(rnData.name, ' '), '<') +
      '<<' +
      join(split(rnData.firstNames, ' '), '<') +
      '<' +
      join(split(rnData.thirdName, ' '), '<');
    third = pad(third);
    mrs.push(third.toUpperCase());
  }

  return mrs;
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const getUUID = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
