import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReaderItem from './ReaderItem';

const ReaderList = (props) => {
  const { t } = useTranslation();

  const { readers, onRefresh } = props;

  return (
    <Box>
      <Stack sx={{ mb: 2 }} direction="row" alignItems="flex-end" justifyContent="space-between">
        <Box>
          <Typography variant="h2">{t('page.reader.title', 'Choose your reader')}</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {t('page.reader.subtitle', 'Please select the reader you want to use')}
          </Typography>
        </Box>
        <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={onRefresh}>
          {t('action.refresh', 'Refresh')}
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Readers">
          <TableHead>
            <TableRow>
              <TableCell>{t('field.readerName', 'Reader name')}</TableCell>
              <TableCell>{t('field.modules', 'Modules')}</TableCell>
              <TableCell>{t('field.proposedModules', 'Proposed modules(s)')}</TableCell>
              <TableCell align="center">{t('field.isPinPad', 'PinPad?')}</TableCell>
              <TableCell align="center">{t('field.isCardInserted', 'Card inserted?')}</TableCell>
              <TableCell align="right">{t('field.actions', 'Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {readers.map((reader) => (
              <ReaderItem key={reader.id} reader={reader} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

ReaderList.defaultProps = {
  readers: [],
};

export default ReaderList;
