import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Styled from './Step5Content.style';
import { useTheme } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import Finder from 'components/Finder';
import { useClient, useConnector, useErrorNotify } from 'infrastructure/hooks';
import Loading from 'components/Loading';

const Step5Content = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { client } = useConnector();
  const { notifyError } = useErrorNotify();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { info, ...rest } = props;
  const entity = 'SimpleSign';
  const uploadFolder = `${info.localFolder}/_uploaded`;
  const signedFolder = `${info.localFolder}/_archived`;

  React.useEffect(() => {
    instantiate();
  }, []);

  const continueClick = async () => {
    props.next();
  };

  const instantiate = async () => {
    setLoading(true);
    try {
      const c = client.fileex();
      await c.createType(entity, 'UPLOAD', [uploadFolder], false);
      await c.createType(entity, 'SIGNED', [signedFolder], false);
      setError();
    } catch (error) {
      notifyError({
        message: t(
          'simplesign.content5.error',
          'Something went wrong while initialising the File Exchange module, please try again',
        ),
      });
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Styled theme={theme}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h2">{t('simplesign.content5.title', 'View the signed document')}</Typography>
          {/* <Typography variant="p">{t('simplesign.content5.caption', 'See the signed document here')}</Typography> */}
        </Box>
        <Box>
          {!error && (
            <Stack spacing={2}>
              <Typography variant="p">
                {t(
                  'simplesign.content5.description',
                  'You can find your signed document in the type SIGNED and in the folder _external',
                )}
              </Typography>
              <Finder entity="SimpleSign" />
            </Stack>
          )}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {!error && (
            <Button
              color="primary"
              size="medium"
              variant="contained"
              startIcon={<ReplayIcon />}
              onClick={continueClick}
            >
              {t('simplesign.content5.next', 'Retry flow')}
            </Button>
          )}
          {error && (
            <Button color="error" size="medium" variant="outlined" startIcon={<ReplayIcon />} onClick={instantiate}>
              {t('simplesign.content5.error', 'Re-initialise')}
            </Button>
          )}
        </Box>
      </Stack>
    </Styled>
  );
};

export default Step5Content;
