// Import the functions you need from the SDKs you need
import {
  collection,
  onSnapshot,
  setDoc,
  updateDoc,
  doc,
  getDoc,
  where,
  Timestamp,
  query,
  getDocs,
} from 'firebase/firestore';
import HttpError from '../../../utils/errors/HttpError';
import FirebaseService from '../Firebase/FirebaseService';
import { getUUID } from '../../../utils/helpers';

const getCollection = () => {
  const db = FirebaseService.getFireStore();
  return collection(db, 'authentications');
};

const createDocument = async (callbackUrl, partnerId) => {
  try {
    const id = getUUID();
    const ref = getDocumentReference(id);
    const data = {
      id: id,
      createdOn: Timestamp.now(),
      updatedOn: Timestamp.now(),
      callbackUrl: callbackUrl,
      partnerId: partnerId,
      status: 'REQUESTED',
    };
    await setDoc(ref, data);
    return {
      id: id,
      data: data,
    };
  } catch (e) {
    console.error('Error adding document: ', e);
    throw new HttpError(e);
  }
};

const getDocument = async (id) => {
  const db = FirebaseService.getFireStore();
  const docRef = doc(db, 'authentications', id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap;
  } else {
    // doc.data() will be undefined in this case
    throw new SyntaxError('No such document');
  }
};

const getDocumentsByPartner = async (id) => {
  const db = getCollection();
  const q = query(db, where('partnerId', '==', id));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((d) => d.data());
};

const getDocumentReference = (id) => {
  const db = FirebaseService.getFireStore();
  return doc(db, 'authentications', id);
};

export default {
  getDocumentsByPartner: getDocumentsByPartner,
  getCollection: getCollection,
  getDocument: getDocument,
  getDocumentReference: getDocumentReference,
  createDocument: createDocument,
};
