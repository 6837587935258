import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import useFinder from './../hooks/useFinder';
import ChangeEntityDialog from '../dialogs/ChangeEntityDialog';

const Entity = () => {
  const { entity, editable } = useFinder();
  const [open, setOpen] = React.useState();
  const { t } = useTranslation();

  return (
    <Box className="fx__entity">
      <Box className="entity__info">
        <Typography className="entity__info__title" color="primary" variant="overline">
          {t('fileExplorer.entity', 'Entity')}
        </Typography>
        <Typography className="entity__info__value" variant="h6">
          {entity}
        </Typography>
      </Box>

      {editable && (
        <>
          <IconButton color="secondary" size="small" className="entity__change" onClick={() => setOpen(true)}>
            <EditIcon />
          </IconButton>
          <ChangeEntityDialog open={open} onClose={() => setOpen(false)} />
        </>
      )}
    </Box>
  );
};

Entity.defaultProps = {};

export default Entity;
