import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FlakyIcon from '@mui/icons-material/Flaky';
import useConnector from 'infrastructure/hooks/useConnector';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import PinPadDialog from 'components/PinPadDialog';

const VerifyPinCode = (props) => {
  const { t } = useTranslation();
  const { client } = useConnector();
  const { notifyError } = useErrorNotify();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const certificate = useSelector((state) => state.truststore.certificate);

  const { osDialog } = props;

  const toggleDialog = () => {
    if (osDialog) {
      onSubmit();
      return;
    }

    setOpen(!open);
  };

  const onSubmit = (pin) => {
    try {
      setLoading(true);

      // Only close when you are coming from the web pin dialog, otherwise a verify pin loop will occur and crashes the sdk.
      if (open) {
        toggleDialog();
      }

      const instance = client.truststore();

      const data = {
        certId: certificate.id,
        osDialog: osDialog,
      };

      if (pin) {
        data.pin = pin;
      }

      instance.verifyPin(data).then(
        (res) => {
          if (res && res.success && res.data.verified) {
            setLoading(false);
            enqueueSnackbar(t('notification.success.pinCorrect', 'Successfully verified the pin code'), {
              variant: 'success',
            });
          } else {
            setLoading(false);
            enqueueSnackbar(t('notification.error.pinIncorrect', 'Pin code is not correct'), { variant: 'error' });
          }
        },
        (err) => {
          setLoading(false);
          notifyError(err);
        },
      );
    } catch (err) {
      notifyError(err);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography sx={{ mb: 1 }} variant="h6">
        {t('verifyPinCodeAction.title', 'Verify pin code')}
      </Typography>
      <LoadingButton loading={loading} variant="contained" startIcon={<FlakyIcon />} onClick={toggleDialog}>
        {t('verifyPinCodeAction.button', 'Verify pin code')}
      </LoadingButton>
      <PinPadDialog open={open} onClose={toggleDialog} onSubmit={onSubmit} />
    </Box>
  );
};

VerifyPinCode.defaultProps = {
  // generic || paymentGeneric
  type: 'generic',
};

export default VerifyPinCode;
