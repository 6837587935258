import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { setEntity } from 'infrastructure/reducers/fileExchange';
import { AppContainer } from 'components/Layouts';
import Finder from 'components/Finder';

const FileExplorer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const entity = useSelector((state) => state.fileExchange.entity);

  const handleEntityChanged = (value) => {
    if (entity === value) {
      return;
    }

    dispatch(setEntity(value));
  };

  return (
    <AppContainer fullscreen>
      <Box className="fx">
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3">{t('page.fileExplorer.title', 'File explorer')}</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {t('page.fileExplorer.subtitleDescription', 'Tip: double click on the folder to view its content')}
          </Typography>
        </Box>
        <Finder editable entity={entity} onEntityChanged={handleEntityChanged} />
      </Box>
    </AppContainer>
  );
};

export default FileExplorer;
