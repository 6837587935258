import React from 'react';
import { useTheme } from '@mui/material/styles';
import Styled from './IdemiaCard.style';

const IdemiaCard = () => {
  const theme = useTheme();

  return (
    <Styled theme={theme}>
      <img className="chip" src="/assets/images/credit-card.svg" alt="Chip" />
      <svg
        className="logo"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Calque_1"
        x="0"
        y="0"
        viewBox="0 0 144 36"
        xmlSpace="preserve"
      >
        <path className="st0" d="M15.8 18l3.6-18h-3l-3.6 18 3.6 18h3l-3.6-18z" />
        <path className="st0" d="M3.3 18l9.6-18H9.6L0 18l9.6 18h3.3L3.3 18zm26.6 0L26.3 0h-3l3.6 18-3.6 18h3l3.6-18z" />
        <path
          className="st0"
          d="M42.7 18L33.1 0h-3.3l9.6 18-9.6 18h3.3l9.6-18zm89.2 2.3l3.3-8.3 3.3 8.3h-6.6zm-.9 2.4h8.5l1.6 4.1h2.9l-7.1-17.5h-3.2l-7.1 17.5h2.8l1.6-4.1zm-7.9-13.4h-2.7v17.5h2.7V9.3zm-8.4 17.5V9.3h-3.8l-5.6 13.5-5.6-13.5H96v17.5h2.7V13.7h.1l5.4 13.1h2.3l5.5-13.1v13.1h2.7zM80 9.3v17.5h11.3v-2.5h-8.5v-5.2h7.7v-2.5h-7.7v-4.8h8.5V9.3H80zm-7.4 8.8c0 1.2-.3 2.4-1 3.4-.4.5-.8 1-1.3 1.4-1.2.9-2.8 1.4-4.3 1.3h-2.7V11.9h2.8c1.5 0 3.1.4 4.3 1.4 1.4 1.2 2.2 2.9 2.2 4.8m2.9-.1c0-1.8-.6-3.5-1.5-5-.8-1.1-1.8-2.1-3-2.7-1.5-.7-3.1-1.1-4.8-1.1h-5.5v17.5h5.6c2.2.1 4.3-.6 6.1-1.9 1.9-1.6 3.1-4.1 3.1-6.8M55 9.3h-2.7v17.5H55V9.3z"
        />
      </svg>
    </Styled>
  );
};

export default IdemiaCard;
