import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button, Stack, Typography } from '@mui/material';
import { AppContainer } from 'components/Layouts';
import TextBlock from './components/TextBlock';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { clear } from 'infrastructure/reducers/truststore';
import TruststoreActions from './components/TruststoreActions';

const TruststoreCertificateDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const certificate = useSelector((state) => state.truststore.certificate);
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(clear());
    navigate('/app/truststore');
  };

  const renderContent = () => {
    return (
      <Box>
        <Stack sx={{ mb: 2 }} direction="row" alignItems="flex-end" justifyContent="space-between">
          <Box>
            <Typography variant="h2">{t('page.truststoredetail.title', 'Truststore Certificate')}</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {t(
                'page.truststoredetail.subtitle',
                'Below you can find more information about the selected certificate',
              )}
            </Typography>
          </Box>
          <Button variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={() => goBack()}>
            {t('action.goBack', 'Go back')}
          </Button>
        </Stack>

        <Stack sx={{ mb: 2 }} direction="column">
          <TextBlock title="Subject" body={certificate.subject} />
          <TextBlock title="Issuer" body={certificate.issuer} />
          <TextBlock title="Type" body={certificate.certificateType} />
          <TextBlock title="ID" body={certificate.id} />
          <TextBlock title="Serial number" body={certificate.serialNumber} />
          <TextBlock title="Exponent" body={certificate.exponent} />
          <TextBlock title="Remainder" body={certificate.remainder} />
          <TextBlock title="Hash subject public key" body={certificate.hashSubPubKey} />
          <TextBlock title="Hash issuer public key" body={certificate.hashIssPubKey} />
          <TextBlock title="Url" body={certificate.url} />
          <TextBlock title="Certificate" body={certificate.certificate} />
        </Stack>

        <Box>
          <TruststoreActions />
        </Box>
      </Box>
    );
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

export default TruststoreCertificateDetail;
