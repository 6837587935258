import React from 'react';
import { clsx } from 'clsx';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';

const Styled = styled.div`
  padding: ${(props) => props.theme.spacing(2, 1.6, 0, 1.6)};
`;

const DialogContent = ({ className, children }) => {
  const theme = useTheme();

  return (
    <Styled theme={theme} className={clsx('simpleDialog__content', className)}>
      {children}
    </Styled>
  );
};

DialogContent.defaultProps = {};

export default DialogContent;
