import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import LoopIcon from '@mui/icons-material/Loop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const TransactionInfo = (props) => {
  const { t, i18n } = useTranslation();

  const generateKey = (row) => {
    return `${row.module}_${row.txDate}_${row.useCase}`;
  };

  const update = async () => {
    props.refresh();
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Stack sx={{ mt: 0.5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('page.admin.transactioninfo.title', 'Transaction information')}</Typography>
        <Box>
          <Button
            sx={{ ml: 1 }}
            color="primary"
            size="small"
            variant="contained"
            startIcon={<LoopIcon />}
            onClick={update}
          >
            {t('action.refresh', 'Refresh')}
          </Button>
        </Box>
      </Stack>
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
          <Typography>{t('page.admin.transactioninfo.accordeon', 'Transaction list')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack sx={{ mt: 0.5 }} direction="row" justifyContent="space-between" alignItems="center">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('page.admin.transactioninfo.module', 'Module')}</TableCell>
                    <TableCell>{t('page.admin.transactioninfo.usecase', 'Use-case')}</TableCell>
                    <TableCell>{t('page.admin.transactioninfo.counter', 'counter')}</TableCell>
                    <TableCell>{t('page.admin.transactioninfo.date', 'date')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data?.map((row) => (
                    <TableRow key={generateKey(row)} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.module}
                      </TableCell>
                      <TableCell>{row.useCase}</TableCell>
                      <TableCell>{row.counter}</TableCell>
                      <TableCell>{row.txDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TransactionInfo;
