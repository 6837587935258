import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SignService from 'infrastructure/services/Sign';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import Dropzone from 'components/Dropzone';
import TruststoreStepperDialog from './components/TruststoreStepperDialog';

const TruststoreUploadAndSignDocument = (props) => {
  const { t } = useTranslation();
  const { notifyError } = useErrorNotify();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState();
  const [signResponse, setSignResponse] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const nonRepudiationCertificate = useSelector((state) => state.truststore.certificate);

  const toggleDialog = () => {
    setOpen(!open);
  };

  const onFileAccepted = (file) => {
    setFile(file);
  };

  const getCertificate = (cert) => (cert?.certificates ? cert.certificates[0].certificate : cert?.certificate);

  const onSign = async () => {
    try {
      setLoading(true);

      let certificateChain = [];
      const body = {
        file,
        nonRepudiationCertificate: getCertificate(nonRepudiationCertificate),
        certificateChain: certificateChain,
        algorithm: 'SHA384',
      };

      const response = await SignService.uploadFile({ body });
      setSignResponse(response);
      toggleDialog();

      setLoading(false);
    } catch (err) {
      notifyError(err);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography sx={{ mb: 1 }} variant="h6">
        {t('uploadAndSignDocumentAction.title', 'Upload and sign document')}
      </Typography>

      <Dropzone onFileAccepted={onFileAccepted} />

      <LoadingButton
        sx={{ mt: 1 }}
        loading={loading}
        disabled={!file}
        variant="contained"
        startIcon={<AssignmentTurnedInIcon />}
        onClick={onSign}
      >
        {t('uploadAndSignDocumentAction.button', 'Sign uploaded document')}
      </LoadingButton>

      <TruststoreStepperDialog open={open} signResponse={signResponse} onClose={toggleDialog} />
    </Box>
  );
};

TruststoreUploadAndSignDocument.defaultProps = {};

export default TruststoreUploadAndSignDocument;
