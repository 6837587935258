import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const Styled = styled(Box)`
  .dialog__stepper {
    padding: 25px 15px 20px 15px;
  }

  .stepper-type-btn {
    padding: 20px;
    min-width: 95px;
  }
`;

export default Styled;
