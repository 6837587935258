import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme }) => `
  .front {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: ${theme.palette.mode === 'dark' ? 'rgba(160,174,192, 1)' : 'rgb(226, 232, 240)'};
    color: rgba(45, 55, 72, 1);

    .title {
      font-weight: 700;
      font-size: 14px;
    }

    .sub-title {
      font-size: 9px;
    }

    .card-content {
      font-size: 10px;
      margin-top: 10px;
      line-height: 14px;
    }

    .italic {
      font-style: italic;
    }

    .bold {
      font-weight: 700;
    }

    .chip {
      top: 100px;
      left: 20px;
      position: absolute;
      width: 75px;

      img {
        width: 100%;
      }
    }

    .card-row {
      margin-top: 5px;
      display: flex;
    }

    .card-row-2 {
      display: flex;
      justify-content: space-between;
    }

    .chip-spacing {
      margin-left: 123px;
    }

    .text-right {
      text-align: right;
    }

    .text-spacing {
      margin-left: 10px;
    }

    .align-end {
      align-items: flex-end;
    }

    .mt-5 {
      margin-top: 5px;
    }

    .image {
      position: absolute;
      right: 5px;
      bottom: 0;
      margin-bottom: 5px;
      width: 100px;
      height: 143px;
      border-radius: 5px;

      &::before {
        content: '';
        width: 100%;
        height: 143px;
        background-color: ${theme.palette.mode === 'dark' ? 'rgb(133 147 165)' : 'rgb(210 216 224)'};
        border-radius: 5px;
        position: absolute;
        left: -2px;
      }

      &::after {
        content: attr(alt);
        font-size: 11px;
        color: rgba(45, 55, 72, 1);
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        /* width: 100%; */
        /* height: 100%; */
        transform: translate(-50%, -50%);
      }
    }
  }

  .back {
    /*Ratio is 8/5*/
    width: 475px;
    height: 275px;
    padding: 10px 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: ${theme.palette.mode === 'dark' ? 'rgba(160,174,192, 1)' : 'rgb(226, 232, 240)'};
    color: rgba(45, 55, 72, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-content {
      font-size: 10px;
      line-height: 14px;
    }

    .barcode {
      font-family: 'Libre Barcode 39', cursive;
      transform: scale(1.55, 7);
      margin-top: 28px;
      margin-right: 26px;
    }

    .italic {
      font-style: italic;
    }

    .bold {
      font-weight: 700;
    }

    .card-row-2 {
      display: flex;
      justify-content: space-between;
    }

    .mt-5 {
      margin-top: 5px;
    }

    .machine-readable {
      font-size: 1.5rem;
      line-height: 1;
      font-family: 'OCR-B', monospace;
    }
  }
`,
);

export default Styled;
