import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import useClient from 'infrastructure/hooks/useClient';
import useConnector from 'infrastructure/hooks/useConnector';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DistributionService } from 'infrastructure/services';
import Installation from 'components/Installation';
import Loading from 'components/Loading';
import { AppContainer } from 'components/Layouts';
import ErrorBlock from 'components/ErrorBlock';
import ConfigurationService from 'infrastructure/services/Configuration';

const InstallT1Connector = () => {
  const { t } = useTranslation();
  const { client } = useClient();
  const { init } = useConnector();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [version, setVersion] = React.useState();

  const getVersion = async () => {
    try {
      setLoading(true);

      const latestVersion = client.connectorVersions.find((v) => v.latest);
      setVersion({ new: latestVersion.version, uris: latestVersion.uris });
      setError(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const retryInitialization = () => {
    init(client.connector.url, client.connector.port);
  };

  React.useEffect(() => {
    getVersion();
  }, []);

  if (loading) {
    return (
      <AppContainer>
        <Loading />
      </AppContainer>
    );
  }

  if (error) {
    return (
      <AppContainer>
        <ErrorBlock
          title={t('errorBlock.connector.installation.title', 'Something went wrong')}
          subtitle={t(
            'errorBlock.connector.installation.subtitleDescription',
            'Something went wrong while fetching the installation uris.',
          )}
          actions={
            <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={getVersion}>
              {t('errorBlock.connector.installation.button', 'Try again')}
            </Button>
          }
        />
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <Box sx={{ mb: 1 }}>
        <Typography variant="h2">{t('connector.installation.title', 'Trust1Connector not found')}</Typography>
        <Typography variant="body1">
          {t(
            'connector.installation.description',
            'Are you sure that you installed the Trust1Connector? Did you re-initialize the Trust1Connector with incorrect settings?',
          )}
        </Typography>

        <Button
          color="primary"
          size="medium"
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={retryInitialization}
          sx={{ mt: 2 }}
        >
          {t('action.refresh', 'Refresh')}
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Installation
          title={t('connector.installation.installers.title', 'If not? Choose your installer!')}
          uris={version.uris}
          newVersion={version.new}
        />
      </Box>
    </AppContainer>
  );
};

export default InstallT1Connector;
