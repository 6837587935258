import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from 'components/dialogs/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const CreateType = (props) => {
  const { t } = useTranslation();
  const { icon, loading, open, onClose, onConfirm, title, description } = props;

  return (
    <Dialog max="400px" open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <DialogTitle>
          {icon && React.cloneElement(icon, { color: 'action', sx: { fontSize: 30, mr: 1 } })}
          {title}
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14, mb: 1.6 }}>{description}</DialogContentText>
      </DialogContent>
      <DialogFooter>
        <Stack direction="row" spacing={1}>
          <Button size="small" onClick={onClose}>
            {t('action.cancel', 'Cancel')}
          </Button>
          <LoadingButton color="primary" size="small" variant="contained" loading={loading} onClick={onConfirm}>
            {t('action.confirm', 'Confirm')}
          </LoadingButton>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
};

CreateType.defaultProps = {
  open: false,
  onClose: () => false,
  onSubmit: () => false,
};

export default CreateType;
