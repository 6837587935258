// Import the functions you need from the SDKs you need
import { collection, onSnapshot, addDoc, where, doc, getDoc, query, getDocs } from 'firebase/firestore';
import HttpError from '../../../utils/errors/HttpError';
import FirebaseService from '../Firebase/FirebaseService';
import { getUUID } from '../../../utils/helpers';

const getCollection = () => {
  const db = FirebaseService.getFireStore();
  return collection(db, 'partners');
};

const getDocument = async (id) => {
  const db = FirebaseService.getFireStore();
  const docRef = doc(db, 'partners', id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap;
  } else {
    // doc.data() will be undefined in this case
    throw new SyntaxError('No such document');
  }
};

const getDocuments = async () => {
  const db = getCollection();
  const q = query(db);
  const querySnapshot = await getDocs(q);
  console.log(querySnapshot.data());
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
  });
};

const findPartner = async (name, password) => {
  try {
    const db = getCollection();
    const q = query(db, where('username', '==', name), where('password', '==', password));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    } else {
      return null;
    }
  } catch (e) {
    throw HttpError();
  }
};

const getDocumentReference = (id) => {
  const db = FirebaseService.getFireStore();
  return doc(db, 'partners', id);
};

export default {
  findPartner: findPartner,
  getDocuments: getDocuments,
  getCollection: getCollection,
  getDocument: getDocument,
  getDocumentReference: getDocumentReference,
};
