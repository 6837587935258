import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Styled from './IsabelPaymentCard.style';

const CrelanPaymentCard = () => {
  const theme = useTheme();
  const applicationData = useSelector((state) => state.card.applicationData);

  const cardNumber = React.useMemo(() => {
    let strRes = '';

    if (!applicationData?.pan) {
      return null;
    }

    [...applicationData.pan].forEach((comp, idx) => {
      idx % 4 === 0 ? (strRes += ' ' + comp) : (strRes += comp);
    });

    return strRes;
  }, [applicationData.pan]);

  return (
    <Styled theme={theme}>
      <div className="card-payment-logo">
        <img src="/assets/images/card-logos/isabel.png" alt="Isabel" />
      </div>

      <div className="chip-row">
        <img className="chip" src="/assets/images/credit-card.svg" alt="Credit card chip" />
        <div className="chip-type">
          <div className="text-uppercase">transactions</div>
          <div className="chip-type__toggle">
            <div className="chip-type__toggle__button"></div>
          </div>
          <div className="text-uppercase">secured</div>
        </div>
      </div>

      <div className="card-content">
        <div className="card-content-text">[Company name]</div>
        <div className="card-content-text">{applicationData?.name}</div>
        <div className="card-content-text">User id: [TODO]</div>
        <div className="card-content-text">Card id: {cardNumber}</div>
      </div>
    </Styled>
  );
};

export default CrelanPaymentCard;
