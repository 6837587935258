import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from 'components/Loading';
import { AppContainer } from 'components/Layouts';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import SmartIDComponent from './components/SmartIDComponent';
import SmartIDService from 'infrastructure/services/SmartID';
import { Trans } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useErrorNotify } from 'infrastructure/hooks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SmartID = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [correlationId, setCorrelationId] = React.useState();
  const [error, setError] = React.useState(null);
  const [means, setMeans] = React.useState(null);
  const [selectedMeans, setSelectedMeans] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { notifyError } = useErrorNotify();

  React.useEffect(() => {
    getMeans();
    if (searchParams.get('correlationId')) {
      setCorrelationId(searchParams.get('correlationId'));
    }
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMeans(typeof value === 'string' ? value.split(',') : value);
  };

  const getMeans = async () => {
    setLoading(true);
    try {
      const res = await SmartIDService.getAvailableMeans();
      setMeans(res.data);
      setSelectedMeans(res.data);
    } catch (error) {
      notifyError({
        message: t('smartid.error.means', 'Could not retrieve the means for Smart ID'),
      });
      console.error(error);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await SmartIDService.authenticate(selectedMeans);
      window.location.replace(res.data.redirectUri);
    } catch (error) {
      console.error(error);
      setError('Could not request for authentication');
    }
    setLoading(false);
  };

  const handleBack = async () => {
    setCorrelationId(null);
    setError(null);
    setMeans(null);
    await getMeans();
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <Box>
        <Stack sx={{ mb: 2 }} direction="row" alignItems="flex-end" justifyContent="space-between">
          <Box>
            <Typography variant="h2">{t('page.smartid.title', 'Demo Trust1Authentication Service')}</Typography>
          </Box>
          {/* <Button color="primary" size="medium" variant="outlined" startIcon={<RefreshIcon />} onClick={onRefresh}>
            {t('action.refresh', 'Refresh')
          </Button> */}
        </Stack>

        {correlationId && <SmartIDComponent correlationId={correlationId} onBack={handleBack}></SmartIDComponent>}

        {!correlationId && error && (
          <Alert severity="error" sx={{ my: 2 }}>
            <AlertTitle>{t('errorBlock.default.title', 'Whoops! Something went wrong')}</AlertTitle>
            {error}
          </Alert>
        )}
        {!correlationId && (
          <Stack>
            <Typography variant="body2">{t('smartid.request.explanation')}</Typography>
            <Typography variant="body2">{t('smartid.request.explanation2')}</Typography>
            <Button sx={{ mt: 1.5, width: 400 }} color="secondary" variant="contained" onClick={fetchData}>
              <Trans i18nKey="smartid.request.button">Request authentication</Trans>
            </Button>
            <Stack sx={{ marginTop: 4 }} spacing={3}>
              <Stack spacing={1}>
                <Typography variant="h4">
                  <Trans i18nKey="smartid.request.options">Authentication request options</Trans>
                </Typography>
                <Typography variant="body2">
                  <Trans i18nKey="smartid.request.optionsdetail">
                    Below you can select with means are sent to the authentication request
                  </Trans>
                </Typography>
              </Stack>

              <FormControl>
                {means && (
                  <>
                    <InputLabel id="means-select">Means</InputLabel>
                    <Select
                      multiple
                      input={<OutlinedInput label="Mean" />}
                      MenuProps={MenuProps}
                      labelId="means-select"
                      value={selectedMeans}
                      label="Means"
                      onChange={handleChange}
                    >
                      {means.map((mean) => (
                        <MenuItem key={mean} value={mean}>
                          {mean}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Stack>
          </Stack>
        )}
      </Box>
    );
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

export default SmartID;
