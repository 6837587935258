import React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const defaultStyling = { pt: 1, pb: 1, pr: 2, pl: 2 };

const Dependency = ({ top, bottom, title, loading, success, error }) => {
  const topStyling = { ...defaultStyling, borderTopLeftRadius: 10, borderTopRightRadius: 10 };
  const bottomStyling = { ...defaultStyling, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 };
  const paperStyles = top ? topStyling : bottom ? bottomStyling : defaultStyling;

  return (
    <Paper sx={paperStyles} variant="block" elevation={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{title}</Typography>

        {loading && <Skeleton variant="circular" width={24} height={24} />}
        {!loading && error && <CancelIcon color="error" />}
        {!loading && success && <CheckCircleIcon color="success" />}
      </Stack>
    </Paper>
  );
};

Dependency.defaultProps = {
  loading: false,
};

export default Dependency;
