import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoopIcon from '@mui/icons-material/Loop';
import DistributionService from 'infrastructure/services/Distribution';
import ValidationService from 'infrastructure/services/Validation';
import Dependency from './components/Dependency';

const DependencyInformation = ({ info }) => {
  const { t } = useTranslation();
  const [loadingDistribution, setLoadingDistribution] = React.useState();
  const [distribution, setDistribution] = React.useState();
  const [loadingValidation, setLoadingValidation] = React.useState();
  const [validation, setValidation] = React.useState();

  const fetchDistribution = async () => {
    try {
      setLoadingDistribution(true);

      const distributionResponse = await DistributionService.getSystemInfo();
      setDistribution(distributionResponse);

      setLoadingDistribution(false);
    } catch (err) {
      setDistribution(null);
      setLoadingDistribution(false);
    }
  };

  const fetchValidation = async () => {
    try {
      setLoadingValidation(true);

      await ValidationService.getStatus();
      setValidation(true);

      setLoadingValidation(false);
    } catch (err) {
      setValidation(false);
      setLoadingValidation(false);
    }
  };

  const loadAll = () => {
    fetchDistribution();
    fetchValidation();
  };

  React.useEffect(() => {
    loadAll();
  }, []);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('page.admin.title.dependencyStatus', 'Dependency status')}</Typography>
        <Button color="primary" size="small" variant="contained" startIcon={<LoopIcon />} onClick={loadAll}>
          {t('action.refresh', 'Refresh')}
        </Button>
      </Stack>

      <Stack sx={{ mt: 2 }} spacing={0}>
        <Dependency
          top
          title={t('page.admin.dependency.trust1connectorApi', 'Trust1Connector API')}
          error={info?.t1CInfoAPI?.status !== 'ACTIVATED'}
          success={info?.t1CInfoAPI?.status === 'ACTIVATED'}
        />
        <Dependency
          loading={loadingValidation}
          title={t('page.admin.dependency.validationService', 'Validation Service')}
          error={!validation}
          success={validation}
        />
        <Dependency
          bottom
          loading={loadingDistribution}
          title={t('page.admin.dependency.distributionService', 'Distribution Service')}
          error={!distribution?.isAlive}
          success={distribution?.isAlive}
        />
      </Stack>
    </Box>
  );
};

export default DependencyInformation;
