import { v4 as uuidv4 } from 'uuid';
import { useContext } from 'react';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { useConnector, useLoader, useError } from 'infrastructure/hooks';
import { finderContext } from '../Finder.context';
import useFinder from './useFinder';

const useFinderActions = () => {
  const { client } = useConnector();
  const { setType } = useFinder();
  const { enqueueSnackbar } = useSnackbar();
  const { loader, showLoader, hideLoader } = useLoader();
  const { error, updateError } = useError();
  const { entity, type, relPaths, setContent, setTypes } = useContext(finderContext);

  const createType = (name) => {
    return new Promise((resolve, reject) => {
      const key = 'createType';

      showLoader(key);

      const c = client.fileex();

      c.createType(entity, name, [], true).then(
        (res) => {
          setType(name);
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const deleteType = () => {
    return new Promise((resolve, reject) => {
      const key = 'deleteType';
      showLoader(key);

      const c = client.fileex();

      // entity: string, type: string, initPath?: [string], modal?: boolean, timeout?: number
      c.deleteType(entity, type, [], true).then(
        (res) => {
          setType(null);
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const fetchTypes = () => {
    return new Promise((resolve, reject) => {
      const key = 'fetchTypes';

      showLoader(key);

      const c = client.fileex();

      c.listTypes(entity).then(
        (res) => {
          setTypes(res.data.types);
          resolve(res.data.types);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const fetchDirectoryContent = () => {
    return new Promise((resolve, reject) => {
      const key = 'fetchDirectoryContent';
      showLoader(key);

      const c = client.fileex();

      c.listTypeContent(entity, type, relPaths).then(
        (res) => {
          setContent(res.data.files);
          resolve(res.data.files);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
          enqueueSnackbar(err.description, { variant: 'error' });
        },
      );
    });
  };

  const createFolder = (folderName) => {
    return new Promise((resolve, reject) => {
      const key = 'createFolder';

      showLoader(key);
      const c = client.fileex();

      // entity: string, type: string, relPath: [string], recursive?: boolean
      c.createDir(entity, type, [...relPaths, folderName], false).then(
        (res) => {
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const downloadFile = (fileName, autoDownload = false) => {
    return new Promise((resolve, reject) => {
      const key = 'downloadFile';

      showLoader(key);

      const c = client.fileex();

      // entity: string, type: string, fileName: string, relPath?: [string], notifyOnCompletion?: boolean
      c.upload(entity, type, fileName, relPaths).then(
        (res) => {
          if (autoDownload) {
            FileSaver.saveAs(res, fileName);
          }

          resolve(res);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          hideLoader(key);
          updateError(key, err);
        },
      );
    });
  };

  const renameFile = (fileName, newFileName) => {
    return new Promise((resolve, reject) => {
      const key = 'renameFile';

      showLoader(key);

      const c = client.fileex();

      // entity: string, type: string, fileName: string, newfileName: string, relPath?: [string]
      c.renameFile(entity, type, fileName, newFileName, [...relPaths.join(',')]).then(
        (res) => {
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          updateError(key, err);
          hideLoader(key);
          reject(err);
        },
      );
    });
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const key = 'renameFile';

      showLoader(key);

      const c = client.fileex();

      // entity: string, type: string, file: Blob, fileName: string, relPath?: [string], implicitCreationType?: boolean, notifyOnCompletion?: boolean
      c.download(entity, type, file, file.name, relPaths).then(
        (res) => {
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const moveFile = (previousLocation, newLocation, fileName) => {
    return new Promise((resolve, reject) => {
      const key = 'moveFile';

      const { entity: prevEntity, type: prevType, relPaths: prevRelPath } = previousLocation;
      const { /*entity: newEntity, */ type: newType, relPaths: newRelPath } = newLocation;

      showLoader(key);

      const c = client.fileex();

      // entity: string, fromType: string, toType: string, fileName: string, fromrelPath?: [string], toRelPath?: [string]
      c.moveFile(prevEntity, prevType, newType, fileName, prevRelPath, newRelPath).then(
        (res) => {
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const copyFile = (previousLocation, newLocation, fileName) => {
    return new Promise((resolve, reject) => {
      const key = 'moveFile';

      const { entity: prevEntity, type: prevType, relPaths: prevRelPath } = previousLocation;
      const { /* entity:  newEntity,*/ type: newType, relPaths: newRelPath } = newLocation;

      showLoader(key);

      const c = client.fileex();

      const lastIndex = fileName.lastIndexOf('.');
      const newFileName = fileName.slice(0, lastIndex) + ' (1)' + fileName.slice(lastIndex);

      // entity: string, fromType: string, toType: string, fileName: string, newfileName: string, fromrelPath?: [string], toRelPath?: [string],
      c.moveFile(prevEntity, prevType, newType, fileName, newFileName, prevRelPath, newRelPath).then(
        (res) => {
          resolve(res.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const listPrinters = () => {
    return new Promise((resolve, reject) => {
      const key = 'listPrinters';

      showLoader(key);

      const c = client.rawprint();

      c.list().then(
        (res) => {
          resolve(res.data.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  const printData = (name, data) => {
    return new Promise((resolve, reject) => {
      const key = 'printData';

      showLoader(key);

      const job = `print-job-${uuidv4()}`;

      const c = client.rawprint();

      c.print(name, job, data).then(
        (res) => {
          resolve(res.data.data);
          hideLoader(key);
        },
        (err) => {
          reject(err);
          updateError(key, err);
          hideLoader(key);
        },
      );
    });
  };

  return {
    loader,
    error,
    createType,
    fetchTypes,
    deleteType,
    createFolder,
    fetchDirectoryContent,
    downloadFile,
    renameFile,
    uploadFile,
    moveFile,
    copyFile,
    listPrinters,
    printData,
  };
};

export default useFinderActions;
