import React from 'react';
import Grid from '@mui/material/Grid';
import CardFront from './components/Front';
import CardBack from './components/Back';
import Styled from './DNI3Card.style';

const DNI3Card = () => {
  return (
    <Styled>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} md="auto">
          <CardFront />
        </Grid>
        <Grid item xs={12} md="auto">
          <CardBack />
        </Grid>
      </Grid>
    </Styled>
  );
};

export default DNI3Card;
