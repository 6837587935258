import React from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import PinPad from 'components/PinPad';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useConnector from 'infrastructure/hooks/useConnector';
import SignService from 'infrastructure/services/Sign';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import Loading from 'components/Loading';

const Step1 = (props) => {
  const { t } = useTranslation();
  const { client } = useConnector();
  const { notifyError } = useErrorNotify();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState();
  const reader = useSelector((state) => state.card.reader);
  const currentModule = useSelector((state) => state.card.currentModule);

  const rootCertificate = useSelector((state) => state.card.rootCertificate);
  const intermediateCertificate = useSelector((state) => state.card.intermediateCertificates);
  const nonRepudiationCertificate = useSelector((state) => state.card.nonRepudiationCertificate);

  const { preventInitialTrigger, signResponse, stepCompleted, keyboardTab, onKeyboardTabChanged } = props;

  React.useEffect(() => {
    if (!preventInitialTrigger && (reader.pinpad || reader.forcePinpad)) {
      onPinPadSubmit();
    }
  }, []);

  const getCertificate = (cert) => (cert?.certificates ? cert.certificates[0].certificate : cert?.certificate);

  const onPinPadSubmit = (pin) => {
    setLoading(true);
    const c = client.generic(reader.id);

    const { bytes, digestAlgorithm, originalDocument, originalDocumentName, signatureDate } = signResponse;

    const data = {
      algorithm: digestAlgorithm.toLowerCase(),
      data: bytes,
    };

    if (pin) {
      data.pin = pin;
    }

    c.sign(currentModule, data).then(
      async (res) => {
        try {
          if (res && res.success) {
            let certificateChain = [];
            for (let cert of nonRepudiationCertificate.certificates) {
              certificateChain.push(cert.certificate);
            }
            for (let cert of intermediateCertificate.certificates) {
              certificateChain.push(cert.certificate);
            }
            for (let cert of rootCertificate.certificates) {
              certificateChain.push(cert.certificate);
            }

            const body = {
              signingCertificate: getCertificate(nonRepudiationCertificate),
              certificateChain,
              digestAlgorithm,
              bytesToSign: originalDocument,
              signedBytes: res.data.data,
              signatureDate: signatureDate,
              documentName: originalDocumentName,
            };
            const response = await SignService.sign({ body: body });
            stepCompleted({ ...response, originalDocumentName });

            enqueueSnackbar(t('notification.success.documentSigned', 'Successfully signed'), { variant: 'success' });
          } else {
            setLoading(false);
            enqueueSnackbar(t('notification.error.pinIncorrect', 'Pin code is not correct'), { variant: 'error' });
          }
        } catch (err) {
          setLoading(false);
          notifyError(err);
        }
      },
      (err) => {
        setLoading(false);
        notifyError(err);
      },
    );
  };

  if (loading) {
    return (
      <Box sx={{ minWidth: 300, minHeight: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
        <Button sx={{ borderRadius: 0 }} onClick={() => onKeyboardTabChanged('numpad')}>
          {t('keyboards.tab.numpad', 'NUMPAD')}
        </Button>
        <Button sx={{ borderRadius: 0 }} onClick={() => onKeyboardTabChanged('mobile')}>
          {t('keyboards.tab.mobile', 'MOBILE')}
        </Button>
      </ButtonGroup>
      <PinPad
        layout={keyboardTab}
        title={t('uploadAndSignDocumentAction.stepper.step1.hint', 'Please enter your pin to sign the document')}
        onSubmit={onPinPadSubmit}
      />
    </Box>
  );
};

Step1.defaultProps = {
  stepCompleted: () => false,
  signResponse: {},
};

export default Step1;
