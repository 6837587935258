import React from 'react';
import { useTheme } from '@mui/material/styles';
import Styled from './LuxtrustCard.style';

const LuxtrustCard = () => {
  const theme = useTheme();

  return (
    <Styled theme={theme}>
      <img className="chip" src="/assets/images/credit-card.svg" alt="Chip" />
      <img className="logo" src="/assets/images/card-logos/luxtrust.png" alt="Chip" />
    </Styled>
  );
};

export default LuxtrustCard;
