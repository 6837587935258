import Api from 'utils/Api';
import HttpError from 'utils/errors/HttpError';
import SyntaxError from 'utils/errors/SyntaxError';

const getStatus = async () => {
  try {
    let url = window._env_.REACT_APP_BFF_URL;
    const response = await Api.get(`${url}/v1/validation/status`);

    if (response.status === 200) {
      return true;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const validateChain = async ({ body = {} } = {}) => {
  try {
    const { certificates } = body;
    const certificateChain = certificates.map((certificate, i) => ({ order: i, certificate }));

    const data = {
      certificateChain,
    };

    const response = await Api.post(`${window._env_.REACT_APP_BFF_URL}/v1/validate`, data);

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

export default {
  getStatus,
  validateChain,
};
