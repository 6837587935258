import React from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useConnector from 'infrastructure/hooks/useConnector';
import SignService from 'infrastructure/services/Sign';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import Loading from 'components/Loading';

const Step1 = (props) => {
  const { t } = useTranslation();
  const { client } = useConnector();
  const { notifyError } = useErrorNotify();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState();
  const certificate = useSelector((state) => state.truststore.certificate);

  const { preventInitialTrigger, signResponse, stepCompleted, keyboardTab, onKeyboardTabChanged } = props;

  React.useEffect(() => {
    if (!preventInitialTrigger) {
      onPinPadSubmit();
    }
  }, []);

  const getCertificate = (cert) => (cert?.certificates ? cert.certificates[0].certificate : cert?.certificate);

  const onPinPadSubmit = () => {
    setLoading(true);
    const c = client.truststore();

    const { bytes, digestAlgorithm, originalDocument, originalDocumentName, signatureDate } = signResponse;

    const data = {
      data: bytes,
      osDialog: true,
      certId: certificate.id,
    };

    c.sign(data).then(
      async (res) => {
        try {
          if (res && res.success) {
            // empty since there is no chain
            let certificateChain = [];

            const body = {
              signingCertificate: getCertificate(certificate),
              certificateChain,
              digestAlgorithm,
              bytesToSign: originalDocument,
              signedBytes: res.data.data,
              signatureDate: signatureDate,
              documentName: originalDocumentName,
            };
            const response = await SignService.sign({ body: body });
            stepCompleted({ ...response, originalDocumentName });

            enqueueSnackbar(t('notification.success.documentSigned', 'Successfully signed'), { variant: 'success' });
          } else {
            setLoading(false);
            enqueueSnackbar(t('notification.error.pinIncorrect', 'Pin code is not correct'), { variant: 'error' });
          }
        } catch (err) {
          setLoading(false);
          notifyError(err);
        }
      },
      (err) => {
        setLoading(false);
        notifyError(err);
      },
    );
  };

  if (loading) {
    return (
      <Box sx={{ minWidth: 300, minHeight: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      {/* <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
        <Button sx={{ borderRadius: 0 }} onClick={() => onKeyboardTabChanged('numpad')}>
          {t('keyboards.tab.numpad', 'NUMPAD')}
        </Button>
        <Button sx={{ borderRadius: 0 }} onClick={() => onKeyboardTabChanged('mobile')}>
          {t('keyboards.tab.mobile', 'MOBILE')}
        </Button>
      </ButtonGroup>
      <PinPad
        layout={keyboardTab}
        title={t('uploadAndSignDocumentAction.stepper.step1.hint', 'Please enter your pin to sign the document')}
        onSubmit={onPinPadSubmit}
      /> */}
    </Box>
  );
};

Step1.defaultProps = {
  stepCompleted: () => false,
  signResponse: {},
};

export default Step1;
