import React from 'react';
import Box from '@mui/material/Box';
import Loading from 'components/Loading';
import { AppContainer } from 'components/Layouts';
import SimpleSignSteps from './components/SimpleSignSteps';
import Step1Content from './components/Step1Content';
import Step2Content from './components/Step2Content';
import Step3Content from './components/Step3Content/Step3Content';
import Step4Content from './components/Step4Content';
import Step5Content from './components/Step5Content';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SimpleSign = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  const [file, setFile] = React.useState();
  const [info, setInfo] = React.useState();

  const [currentStep, setCurrentStep] = React.useState(1);
  const totalSteps = 5;

  React.useEffect(() => {
    setLoading(false);
  }, []);

  const stepContinue = () => {
    setCurrentStep(currentStep + 1);
  };
  const stepReturn = () => {
    setCurrentStep(currentStep - 1);
  };

  const step1Continue = (info) => {
    setInfo(info);
    setCurrentStep(currentStep + 1);
  };
  const step2Continue = (file) => {
    setFile(file);
    setCurrentStep(currentStep + 1);
  };

  const retry = () => {
    setFile(undefined);
    setCurrentStep(1);
  };

  const documentation = () => {
    window.open('https://t1t.gitbook.io/t1c-js-guide-v3/other/simple-sign', '_blank', 'noreferrer');
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <Box sx={{ marginBottom: 5 }}>
        <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'end' }}>
          <Button color="primary" size="medium" variant="outlined" onClick={documentation}>
            {t('simplesign.documentation', 'SimpleSign CMS Documentation')}
          </Button>
        </Box>
        <Stack spacing={2} direction="row">
          {/* Steps */}
          <Box sx={{ marginRight: 2, width: '440px' }}>
            <SimpleSignSteps currentStep={currentStep} totalSteps={totalSteps}></SimpleSignSteps>
          </Box>
          {/* Content */}
          {currentStep === 1 && (
            <Box>
              <Step1Content next={step1Continue}></Step1Content>
            </Box>
          )}

          {currentStep === 2 && (
            <Box>
              <Step2Content next={step2Continue} back={stepReturn}></Step2Content>
            </Box>
          )}

          {currentStep === 3 && (
            <Box>
              <Step3Content file={file} next={stepContinue} back={stepReturn}></Step3Content>
            </Box>
          )}

          {currentStep === 4 && (
            <Box>
              <Step4Content next={stepContinue} back={stepReturn}></Step4Content>
            </Box>
          )}

          {currentStep === 5 && (
            <Box sx={{ width: '100%' }}>
              <Step5Content info={info} next={retry}></Step5Content>
            </Box>
          )}
        </Stack>
      </Box>
    );
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

export default SimpleSign;
