import * as React from 'react';
import { styled } from '@mui/material/styles';

const Svg = styled('svg')({
  verticalAlign: 'bottom',
});

const SvgUsb = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" {...props}>
      <path className="fa-primary" d="M0 160v192c0 35.35 28.65 64 64 64l416 .0005v-320L64 96C28.65 96 0 124.7 0 160z" />
      <path
        className="fa-secondary"
        d="M608 128h-128v256h128c17.67 0 32-14.33 32-32V160C640 142.3 625.7 128 608 128zM584 312c0 8.875-7.125 16-16 16h-16c-8.875 0-16-7.125-16-16v-16c0-8.875 7.125-16 16-16h16c8.875 0 16 7.125 16 16V312zM584 216c0 8.875-7.125 16-16 16h-16c-8.875 0-16-7.125-16-16v-16c0-8.875 7.125-16 16-16h16c8.875 0 16 7.125 16 16V216z"
      />
    </Svg>
  );
};

export default SvgUsb;
