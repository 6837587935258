import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Styled from './Step2Content.style';
import { useTheme } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Dropzone from 'components/Dropzone';
import SimpleSignService from 'infrastructure/services/SimpleSign';
import { useErrorNotify } from 'infrastructure/hooks';

const Step2Content = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [file, setFile] = useState();
  const { notifyError } = useErrorNotify();

  const backClick = async () => {
    props.back();
  };

  const continueClick = async () => {
    try {
      await SimpleSignService.uploadDocument(file, file.name);
      props.next(file);
    } catch (error) {
      notifyError({
        message: t('simplesign.content2.uploaderror', 'Something went wrong during upload, please try again'),
      });
      console.error(error);
    }
  };

  const onFileAccepted = (file) => {
    setFile(file);
  };

  return (
    <Styled theme={theme}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h2">{t('simplesign.content2.title', 'Upload a document')}</Typography>
          <Typography variant="p">
            {t('simplesign.content2.caption', 'Upload a document to be signed in the SimpleSign application')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">{t('simplesign.content2.filechoose', 'Choose a PDF file to upload')}</Typography>
          <Box sx={{ marginTop: 2 }}>
            <Dropzone onFileAccepted={onFileAccepted} />
          </Box>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button
            disabled={!file}
            color="primary"
            size="medium"
            variant="contained"
            startIcon={<NavigateNextIcon />}
            onClick={continueClick}
          >
            {t('simplesign.content2.next', 'Upload document')}
          </Button>
          <Button
            color="secondary"
            size="medium"
            variant="outlined"
            startIcon={<KeyboardReturnIcon />}
            onClick={backClick}
          >
            {t('simplesign.content2.back', 'Return')}
          </Button>
        </Stack>
      </Stack>
    </Styled>
  );
};

export default Step2Content;
