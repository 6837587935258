import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import useFinder from '../../hooks/useFinder';
import useFinderActions from '../../hooks/useFinderActions';

const DeleteTypeDialog = (props) => {
  const { t } = useTranslation();
  const { notifyError } = useErrorNotify();
  const { loader, deleteType, fetchTypes } = useFinderActions();
  const { setType } = useFinder();

  const onConfirm = async () => {
    try {
      await deleteType();
      setType(null);
      fetchTypes();
      props.onClose();
    } catch (err) {
      notifyError(err);
    }
  };

  const { open, onClose } = props;

  return (
    <ConfirmDialog
      open={open}
      icon={<DeleteIcon />}
      loading={loader?.deleteType}
      onClose={onClose}
      onConfirm={onConfirm}
      title={t('fileExplorer.dialogs.deleteType.title', 'Delete type')}
      description={t(
        'fileExplorer.dialogs.deleteType.subtitleDescription',
        'Are you sure you want to delete this type with all its content?',
      )}
    />
  );
};

DeleteTypeDialog.defaultProps = {
  open: false,
  onClose: () => false,
};

export default DeleteTypeDialog;
