import styled from '@emotion/styled';

const Styled = styled.div`
  /*Ratio is 8/5*/
  width: 400px;
  height: 250px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  border-radius: 10px;
  background-image: linear-gradient(180deg, #91c8db 0%, #eee7a9 27%, #eee7a9 76%, #91c8db 100%);
  color: black;

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: 700;
  }

  .card-row {
    margin-top: 5px;
    display: flex;
  }

  .flag-be {
    height: 25px;
  }

  .titles {
    flex: 1;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
  }

  .sub-title {
    font-size: 9px;
  }

  .card-content {
    font-size: 10px;
    margin-top: 10px;
    line-height: 14px;
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: 700;
  }

  .chip {
    margin-top: 10px;
    align-items: center;
  }

  .chip__triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 10px 18px 0;
    border-color: transparent #069de5 transparent transparent;
  }

  .chip__img {
    width: 45px;
  }

  .valid-from-to {
    margin-top: 10px;
  }

  .lawyer-icon {
    margin-top: 5px;
    display: flex;
    align-items: flex-end;

    img {
      width: 60px;
    }
  }

  .lawyer-icon__content {
    margin-left: 10px;
  }

  .content-text {
    line-height: 1.2;
  }

  .profile-image {
    position: absolute;
    right: 5px;
    bottom: 0;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 5px;
  }
`;

export default Styled;
