import React from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MouseIcon from '@mui/icons-material/Mouse';
import ListIcon from '@mui/icons-material/List';
import Dialog from 'components/dialogs/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import Search from 'components/Search';
import { useConnector } from 'infrastructure/hooks';
import { Alert, Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setPkcs11ConfigSet } from 'infrastructure/reducers/card';

const Modules = (props) => {
  const { t } = useTranslation();
  const { client } = useConnector();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modulesList = useSelector((state) => state.config.modules);
  const [selectedModule, setSelectedModule] = React.useState();
  const [toggle, setToggle] = React.useState(false);
  const [pkcs11ConfigPresent, setPkcs11ConfigPresent] = React.useState(false);
  const [list, setList] = React.useState(modulesList);
  const { onModuleSelected, ...rest } = props;

  const toggleDialog = () => {
    setToggle(!toggle);
  };

  const onModuleSelect = async (module) => {
    if (module == 'pkcs11') {
      const pkcs11Config = await client.pkcs11(null).getConfig();
      if (pkcs11Config.data && (pkcs11Config.data.macos || pkcs11Config.data.win || pkcs11Config.data.linux)) {
        dispatch(setPkcs11ConfigSet(true));
        setPkcs11ConfigPresent(true);
      } else {
        dispatch(setPkcs11ConfigSet(false));
        setPkcs11ConfigPresent(false);
      }
    }
    setSelectedModule(module);
    onModuleSelected(module);
    toggleDialog();
  };

  const clearModule = () => {
    setSelectedModule(null);
    onModuleSelected(null);
    setList(modulesList);
  };

  const onSearch = debounce((event) => {
    const { value } = event.target;

    const newList = modulesList.filter((item) => t(item).includes(value));

    setList(newList);
  }, 250);

  if (selectedModule) {
    return (
      <Box>
        <Chip
          label={selectedModule}
          size="small"
          color="secondary"
          onDelete={clearModule}
          sx={{ svg: { color: 'white !important' } }}
        />
        {selectedModule == 'pkcs11' && !pkcs11ConfigPresent && (
          <Box sx={{ my: 2 }}>
            <Alert severity="warning">
              {t('readers.modules.pkcs11.not-present', 'No PKCS11 configuration is present. Go to the')}{' '}
              <Link onClick={() => navigate('/app/admin')}>
                {t('readers.modules.pkcs11.not-present-admin', 'administration')}
              </Link>{' '}
              {t('readers.modules.pkcs11.not-present-configure', 'page to configure')}
            </Alert>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      <Button sx={{ fontSize: 10 }} color="secondary" size="small" variant="contained" onClick={toggleDialog} {...rest}>
        {t('action.overruleProposed', 'Overrule proposed')}
      </Button>
      <Dialog max="600px" open={toggle} onClose={toggleDialog}>
        <DialogHeader onClose={toggleDialog}>
          <DialogTitle>
            <ListIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
            {t('dialog.title.choosePreferredModule', 'Choose preferred module')}
          </DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Search fullWidth autoFocus onChange={onSearch} />
          <TableContainer sx={{ maxHeight: 450, minHeight: 450 }}>
            <Table stickyHeader size="small" aria-label="Modules">
              <TableHead>
                <TableRow>
                  <TableCell>{t('field.name', 'Name')}</TableCell>
                  <TableCell align="right">{t('field.actions', 'Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((module) => (
                  <TableRow key={module} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {module}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        startIcon={<MouseIcon />}
                        onClick={() => onModuleSelect(module)}
                        sx={{ fontSize: 10, 'svg:nth-of-type(1)': { fontSize: 14 } }}
                      >
                        {t('action.select', 'Select')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogFooter>
          <Button onClick={toggleDialog}>{t('action.cancel', 'Cancel')}</Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

Modules.defaultProps = {
  onModuleSelected: () => false,
};

export default Modules;
