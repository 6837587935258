import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ListCell from '../ListCell';

const DateModifiedCell = (props) => {
  const { i18n } = useTranslation();

  const { date, width, align, ...rest } = props;

  const getContent = React.useMemo(() => {
    const now = moment();
    const end = moment(date, 'DD/MM/YYYY HH:mm:ss').add(1, 'hour');
    const duration = moment.duration(now.diff(end));
    const hours = duration.asHours();

    if (hours <= 24) {
      return end.locale(i18n.language).fromNow();
    }

    return end.locale(i18n.language).format('DD MMMM YYYY, HH:mm');
  }, [date]);

  return (
    <ListCell className="ld__c" width={width} align={align} {...rest}>
      {getContent}
    </ListCell>
  );
};

DateModifiedCell.defaultProps = {
  width: 15,
  align: 'right',
};

export default DateModifiedCell;
