import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useConnector from 'infrastructure/hooks/useConnector';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'clipboard-copy';

const Certificate = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { client } = useConnector();
  const [parsedCertificates, setParsedCertificates] = useState();
  const [loading, setLoading] = useState(true);

  const onClick = (cert) => {
    copy(cert);
    enqueueSnackbar(t('action.copiedToClipboard', 'Copied to clipboard!'));
  };

  React.useEffect(() => {
    setLoading(true);
    let promises = props.certificates.map((cert) => {
      return client
        .x509()
        .toJSON({
          cert: cert.certificate,
        })
        .then((res) => {
          return {
            original: cert,
            parsed: res,
          };
        });
    });
    Promise.all(promises).then((res) => {
      setParsedCertificates(res);
      setLoading(false);
    });
  }, props.certificates);
  const { title, certificates, ...rest } = props;

  return (
    <Box sx={{ mb: 0.5 }} {...rest}>
      <Typography variant="overline">{title}</Typography>

      {!loading && parsedCertificates.length === 0 && (
        <Paper variant="code">{t('template.certificates.nothingFound', 'No certificate was found')}</Paper>
      )}

      {!loading &&
        parsedCertificates.length > 0 &&
        parsedCertificates.map((cert, index) => (
          <Box sx={{ mb: 1 }} key={`${title}-cert-${index}`}>
            <Box sx={{ textAlign: 'right' }}>
              <Tooltip title={t('action.copyCertificate', 'Copy the certificate')}>
                <IconButton
                  aria-label="Copy"
                  size="small"
                  disabled={!cert.original}
                  onClick={() => onClick(cert.original.certificate)}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
            <Paper variant="code">
              {cert.parsed.subject || t('template.certificates.nothingFound', 'No certificate was found')}
            </Paper>
          </Box>
        ))}
    </Box>
  );
};

Certificate.defaultProps = {
  certificates: [],
};

export default Certificate;
