import styled from '@emotion/styled';
import Container from '@mui/material/Container';

const Styled = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'fullscreen',
})(
  ({ fullscreen, theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;




  ${
    fullscreen &&
    `
    padding-top: calc(var(--MuiDocs-header-height) + 36px);

    ${theme.breakpoints.up('lg')} {
      padding-left: 20px;
      padding-right: 20px;
      margin-right: 20px;
    }
  `
  }

  ${
    !fullscreen &&
    `
      max-width: 125ch;
      padding-top: calc(var(--MuiDocs-header-height) + 36px);

      ${theme.breakpoints.up('lg')} {
        margin-right: 5%;
      }
    `
  }

  .app-layout__container__inner {
    flex: 1;
  }
`,
);

export default Styled;
