import { useContext } from 'react';
import { finderContext } from '../Finder.context';

const useFinder = () => {
  const {
    entity,
    setEntity,
    type,
    setType,
    relPaths,
    setRelPaths,
    content,
    setContent,
    types,
    setTypes,
    selectable,
    editable,
    selectedRelPaths,
    setSelectedRelPaths,
    currentLocation,
    setCurrentLocation,
  } = useContext(finderContext);

  const updateEntity = (newEntity) => {
    setEntity(newEntity);
    setTypes([]);
    setType(null);
    setRelPaths([]);
    setContent([]);
  };

  const updateType = (value) => {
    setType(value);

    if (relPaths.length > 0) {
      setRelPaths([]);
      setSelectedRelPaths([]);
    }
  };

  const basicActions = {
    entity,
    setEntity: updateEntity,
    type,
    setType: updateType,
    relPaths,
    setRelPaths,
    content,
    setContent,
    types,
    setTypes,
    selectable,
    editable,
    selectedRelPaths,
    setSelectedRelPaths,
    currentLocation,
    setCurrentLocation,
  };

  return {
    ...basicActions,
  };
};

export default useFinder;
