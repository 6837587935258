import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import useClient from 'infrastructure/hooks/useClient';
import useConnector from 'infrastructure/hooks/useConnector';
import Loading from 'components/Loading';
import ErrorBlock from 'components/ErrorBlock';
import { AppContainer } from 'components/Layouts';
import InstallT1Connector from './blocks/InstallT1Connector';
import GiveConsent from './blocks/GiveConsent';
import VersionCheck from './blocks/VersionCheck';

const InitializeConnector = () => {
  const { client } = useClient();
  const { t } = useTranslation();

  // 1) Initialize the Trust1Connector
  // Happens automatically by loading the hook
  const { loading, error, init } = useConnector();

  React.useEffect(() => {
    init(client.connector.url, client.connector.port);
  }, []);

  // 2) Show a loading state accordingly.
  if (loading) {
    return (
      <AppContainer>
        <Loading />
      </AppContainer>
    );
  }

  // 3)    Check if initialization has failed
  // 3.1)  Check if the err.code == 112999, could not connect with the trust1connector and display UI accordingly
  // The codes are sometimes a number or a string, so no strict comparisons.
  if (error?.code == 112999) {
    return <InstallT1Connector />;
  }

  // 3.2)  Check if the err.code == 814500 || err.code == 814501, new consent is required and display the UI accordingly
  // The codes are sometimes a number or a string, so no strict comparisons.
  if (error?.code == 814500 || error?.code == 814501) {
    return (
      <AppContainer>
        <GiveConsent />
      </AppContainer>
    );
  }

  // 3.3)  For everything else show default error page (uncatched error has occured in the sdk)
  if (error) {
    return (
      <AppContainer>
        <ErrorBlock
          title={t('errorBlock.connector.defaultError.title', 'Whoops! Something went wrong')}
          subtitle={t(
            'errorBlock.connector.defaultError.subtitleDescription',
            'Something went wrong while initializing the Trust1Connector. Please contact support',
          )}
        />
      </AppContainer>
    );
  }

  //  4) Check for newer versions and display the ui accordingly.
  //  5) Return the outlet component and continue the rendering process of the tree.
  return (
    <VersionCheck>
      <Outlet />
    </VersionCheck>
  );
};

export default InitializeConnector;
