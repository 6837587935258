import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from 'components/dialogs/Dialog';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import { TextInput } from 'components/Inputs';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FolderIcon from '@mui/icons-material/Folder';
import validators from 'utils/validators';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import useFinderActions from '../../hooks/useFinderActions';

const CreateTypeDialog = (props) => {
  const { t } = useTranslation();
  const { notifyError } = useErrorNotify();
  const { loader, fetchTypes, createType } = useFinderActions();

  const { open, onClose } = props;

  const onFormSubmit = async (values) => {
    try {
      await createType(values.name);
      fetchTypes();
      onClose();
    } catch (err) {
      notifyError(err);
    }
  };

  return (
    <Dialog max="400px" open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <DialogTitle>
          <FolderIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
          {t('fileExplorer.dialogs.createType.title', 'Create type')}
        </DialogTitle>
      </DialogHeader>
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText sx={{ fontSize: 14, mb: 1.6 }}>
                {t(
                  'fileExplorer.dialogs.createType.subtitleDescription',
                  'Create a new  type and assign a location where the data should be stored on your local file system.',
                )}
              </DialogContentText>
              <TextInput
                autoFocus
                fullWidth
                label={t('field.name', 'Name')}
                name="name"
                size="small"
                margin="dense"
                validate={validators.isRequired}
              />
            </DialogContent>
            <DialogFooter>
              <Stack direction="row" spacing={1}>
                <Button size="small" onClick={onClose}>
                  {t('action.cancel', 'Cancel')}
                </Button>
                <LoadingButton
                  loading={loader?.createType}
                  disabled={!valid}
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  {t('action.confirm', 'Confirm')}
                </LoadingButton>
              </Stack>
            </DialogFooter>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

CreateTypeDialog.defaultProps = {
  open: false,
  onClose: () => false,
  onSubmit: () => false,
};

export default CreateTypeDialog;
