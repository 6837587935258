import styled from '@emotion/styled';

const Styled = styled.div(
  ({ theme, width, align }) => `
  display: flex;
  width: ${width}%;
  padding: ${theme.spacing(0.8, 1)};
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  justify-content: ${align === 'center' ? 'center' : align === 'right' ? 'flex-end' : 'flex-start'}
`,
);

export default Styled;
