import React from 'react';
import { clsx } from 'clsx';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useFinder from '../hooks/useFinder';
import ListHeader from '../components/ListHeader';
import ListCell from '../components/ListCell';
import FileSizeCell from '../components/cells/FileSizeCell';
import DateModifiedCell from '../components/cells/DateModifiedCell';
import FileNameCell from '../components/cells/FileNameCell';
import ListCellActions from '../components/ListCellActions';

const Content = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState();
  const { selectable, content, relPaths, setRelPaths, selectedRelPaths, setSelectedRelPaths } = useFinder();

  const updateSelectedPath = (item) => {
    const newRelPaths = [...selectedRelPaths];
    // When the user selects a folder on the same relPaths, update the path, else add to array
    if (selectedRelPaths.length > relPaths.length) {
      newRelPaths[newRelPaths.length - 1] = item.name;
    } else {
      newRelPaths.push(item.name);
    }

    setSelectedRelPaths(newRelPaths);
  };

  const onListRowClick = (event, item) => {
    // If its selectable, a directory and not selected yet.
    if (!selectable || (selectable && item.isDir && !isSelected(item))) {
      setSelected(item.name);
      updateSelectedPath(item);
    }

    // If double clicked
    if (event.detail === 2) {
      if (!item.isDir) {
        return;
      }

      setRelPaths((state) => [...state, item.name]);
      setSelected(null);
    }
  };

  const isSelected = (item) => selected === item.name;

  return (
    <Box className="fx__content">
      <Box className="lh">
        <ListHeader isButton width={50} title={t('field.name', 'Name')} sort="desc" />
        <ListHeader isButton width={30} title={t('field.dateModified', 'Date modified')} />
        <ListHeader isButton width={15} title={t('field.size', 'Size')} align="right" />
        <ListHeader width={10} title={t('field.actions', 'Actions')} align="right" />
      </Box>
      <Box className="fx__content__data">
        {content.map((item, index) => (
          <Box
            key={`${item.name}-${index}`}
            className={clsx({
              ld: true,
              selected: isSelected(item),
              'disabled-content-row': selectable && !item.isDir,
            })}
          >
            <FileNameCell
              width={50}
              label={item.name}
              isDirectory={item.isDir}
              onClick={(event) => onListRowClick(event, item)}
            />
            <DateModifiedCell
              width={30}
              date={item.lastModificationTime}
              onClick={(event) => onListRowClick(event, item)}
            />
            <FileSizeCell width={15} align="right" size={item.size} onClick={(event) => onListRowClick(event, item)} />
            <ListCell width={10} align="right">
              <ListCellActions record={item} />
            </ListCell>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

Content.defaultProps = {
  content: [],
  onRowClicked: () => false,
  onContentItemChanged: () => false,
};

export default Content;
