import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgWindows from 'components/icons/SvgWindows';
import SvgMacOS from 'components/icons/SvgMacOS';
import SvgLinux from 'components/icons/SvgLinux';
import useClient from 'infrastructure/hooks/useClient';
import Installer from './components/Installer';

const windowsInstallers = ['WIN32', 'WIN64'];
const macOSInstallers = ['MACOS', 'MACOSARM'];
const linuxInstallers = ['LINUX32', 'LINUX64'];

const Installation = ({ title, uris, newVersion }) => {
  const theme = useTheme();
  const { client } = useClient();
  const { t } = useTranslation();

  const windowsList = React.useMemo(() => {
    const arr = client?.installers && client.installers.length > 0 ? client.installers : uris;

    return arr.filter((item) => windowsInstallers.includes(item.os));
  }, [uris, client]);

  const macOSList = React.useMemo(() => {
    const arr = client?.installers && client.installers.length > 0 ? client.installers : uris;

    return arr.filter((item) => macOSInstallers.includes(item.os));
  }, [uris, client]);

  const linuxList = React.useMemo(() => {
    const arr = client?.installers && client.installers.length > 0 ? client.installers : uris;

    return arr.filter((item) => linuxInstallers.includes(item.os));
  }, [uris, client]);

  return (
    <Box>
      <Typography variant="h4">{title || t('connector.installers.title', 'Choose your new version?')}</Typography>
      <Typography variant="body2">
        {t('connector.installers.subtitleDescription', 'Please disable popups to prevent the download from failing.')}
      </Typography>

      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Installer
            icon={<SvgWindows fill={theme.palette.secondary.main} />}
            title={t('connector.installers.windows.title', 'Windows')}
            description={t(
              'connector.installers.windows.description',
              'Here you can download the Windows version of the Trust1Connector, both 64- and 32-bit versions are available',
            )}
            newVersion={newVersion}
            osList={windowsList}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Installer
            icon={<SvgMacOS fill={theme.palette.secondary.main} />}
            title={t('connector.installers.macOS.title', 'MacOS')}
            description={t(
              'connector.installers.macOS.description',
              'Here you can download the MacOS version of the Trust1Connector, both Intel and Apple silicon versions are available',
            )}
            newVersion={newVersion}
            osList={macOSList}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Installer
            icon={<SvgLinux fill={theme.palette.secondary.main} />}
            title={t('connector.installers.linux.title', 'Linux')}
            description={t(
              'connector.installers.linux.description',
              'Here you can download the Linux version of the Trust1Connector, both 64- and 32-bit versions are available',
            )}
            newVersion={newVersion}
            osList={linuxList}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Installation.defaultProps = {
  uris: [],
};

export default Installation;
