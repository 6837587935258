import React from 'react';
import Box from '@mui/material/Box';
import { Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const Step1 = (props) => {
  const onSelect = (type) => {
    const { stepCompleted } = props;

    stepCompleted(type);
  };

  const { onClose } = props;

  return (
    <Box sx={{ minWidth: 350, minHeight: 150, p: 2, textAlign: 'center' }}>
      <Typography>
        <Trans i18nKey="PinPad.stepper.step1.title">Choose the type of code</Trans>
      </Typography>

      <Stack sx={{ mt: 2 }} direction="row" justifyContent="center" spacing={2}>
        <Button className="stepper-type-btn" variant="contained" onClick={() => onSelect('Pin')}>
          PIN
        </Button>
        <Button className="stepper-type-btn" variant="contained" onClick={() => onSelect('Can')}>
          CAN
        </Button>
      </Stack>

      <Button sx={{ mt: 1.5 }} color="secondary" size="small" onClick={onClose}>
        <Trans i18nKey="action.close">Close</Trans>
      </Button>
    </Box>
  );
};

export default Step1;
