import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import useFinderActions from '../../hooks/useFinderActions';

const DownloadAction = (props) => {
  const { t } = useTranslation();
  const { notifyError } = useErrorNotify();
  const { downloadFile } = useFinderActions();

  const handleDownloadFile = async () => {
    try {
      const { fileName, onClose } = props;

      await downloadFile(fileName, true);
      onClose();
    } catch (err) {
      notifyError(err);
    }
  };

  return (
    <MenuItem disableRipple onClick={handleDownloadFile}>
      <FileDownloadIcon />
      {t('action.download', 'Download')}
    </MenuItem>
  );
};

DownloadAction.defaultProps = {
  onClose: () => false,
};

export default DownloadAction;
