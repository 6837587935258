import Api from 'utils/Api';
import HttpError from 'utils/errors/HttpError';
import SyntaxError from 'utils/errors/SyntaxError';

const getJWT = async () => {
  try {
    const response = await Api.get(`${window._env_.REACT_APP_DS_URL}/v3_5/tokens/application`, null, {
      apikey: window._env_?.REACT_APP_API_KEY,
    });

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const getSystemInfo = async () => {
  try {
    const response = await Api.get(`${window._env_.REACT_APP_DS_URL}/v3_5/system/status`);

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

const getLatestVersion = async (url) => {
  try {
    const jwt = await getJWT();

    let _url = `${window._env_.REACT_APP_DS_URL}/v3_5/versions/latest`;
    if (url) {
      _url = url;
    }

    const response = await Api.get(_url, null, {
      Authorization: `Bearer ${jwt}`,
    });

    if (response.status === 200) {
      return response.data.data;
    }

    throw new HttpError(response);
  } catch (err) {
    if (err instanceof HttpError) {
      throw err;
    }

    throw new SyntaxError(err.message);
  }
};

export default {
  getJWT,
  getSystemInfo,
  getLatestVersion,
};
