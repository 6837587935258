import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Styled from './ListHeader.style';

const ListHeader = (props) => {
  const { isButton, title, width, sort, align } = props;

  const renderContent = () => {
    if (isButton) {
      const restProps = {};

      if (sort && sort.toUpperCase() === 'ASC') {
        restProps.endIcon = <KeyboardArrowUpIcon />;
      }

      if (sort && sort.toUpperCase() === 'DESC') {
        restProps.endIcon = <KeyboardArrowDownIcon />;
      }

      return (
        <Button className="lh__c" endIcon={null} {...restProps}>
          {title}
        </Button>
      );
    }

    return <Box className="lh__c">{title}</Box>;
  };

  return (
    <Styled className="lh__c" width={width} align={align}>
      {renderContent()}
    </Styled>
  );
};

ListHeader.defaultProps = {
  // ASC, DESC
  sort: false,
  width: 10,
};

export default ListHeader;
