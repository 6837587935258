import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Styled from './FullLoadingScreen.style';

const FullLoadingScreen = ({ title }) => (
  <Styled>
    <div className="wrapper">
      <CircularProgress size={25} />
      {title && (
        <Typography className="title" variant="body2">
          {title}
        </Typography>
      )}
    </div>
  </Styled>
);

export default FullLoadingScreen;
