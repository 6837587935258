import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/dialogs/Dialog';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogContent from 'components/dialogs/DialogContent';
import DialogFooter from 'components/dialogs/DialogFooter';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Finder from 'components/Finder';
import useErrorNotify from 'infrastructure/hooks/useErrorNotify';
import useFinder from '../../hooks/useFinder';
import useFinderActions from '../../hooks/useFinderActions';

const MoveFileDialog = (props) => {
  const { t } = useTranslation();
  const { notifyError } = useErrorNotify();
  const { entity, type, relPaths } = useFinder();
  const { moveFile, fetchDirectoryContent } = useFinderActions();

  const { open, onClose, fileName } = props;

  const currentLocation = {
    entity,
    type,
    relPaths,
  };

  const onConfirm = async (newLocation) => {
    try {
      await moveFile(currentLocation, newLocation, fileName);
      fetchDirectoryContent();
      onClose();
    } catch (err) {
      notifyError(err);
    }
  };

  return (
    <Dialog max="960px" open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <DialogTitle>
          <DriveFileMoveIcon color="action" sx={{ fontSize: 30, mr: 1 }} />
          {t('fileExplorer.dialogs.moveFile.title', 'Move file')}
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14, mb: 1.6 }}>
          {t(
            'fileExplorer.dialogs.moveFile.subtitleDescription',
            'Please select the new location where the file should be stored',
          )}
        </DialogContentText>

        <Finder
          selectable
          entity={currentLocation.entity}
          currentLocation={currentLocation}
          onLocationSelected={onConfirm}
        />
      </DialogContent>
      <DialogFooter />
    </Dialog>
  );
};

MoveFileDialog.defaultProps = {
  open: false,
  onClose: () => false,
  onSubmit: () => false,
};

export default MoveFileDialog;
