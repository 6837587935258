import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PinPad from 'components/PinPad';
import Styled from './PinPadDialog.style';

const PinPadDialog = (props) => {
  const { t } = useTranslation();
  const [tab, setTab] = React.useState('numpad');
  const { open, onClose, onSubmit } = props;

  React.useEffect(() => {
    return () => {
      if (open === false) {
        setTab('numpad');
      }
    };
  }, [open]);

  return (
    <Dialog onClose={onClose} open={open} max={tab === 'numpad' ? `300px` : `550px`}>
      <Styled>
        <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
          <Button sx={{ borderRadius: 0 }} onClick={() => setTab('numpad')}>
            {t('keyboards.tab.numpad', 'NUMPAD')}
          </Button>
          <Button sx={{ borderRadius: 0 }} onClick={() => setTab('mobile')}>
            {t('keyboards.tab.mobile', 'MOBILE')}
          </Button>
        </ButtonGroup>

        <PinPad layout={tab} onSubmit={onSubmit} />
      </Styled>
    </Dialog>
  );
};

PinPadDialog.defaultProps = {
  open: false,
  onClose: () => false,
  onSubmit: () => false,
};

export default PinPadDialog;
