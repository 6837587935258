import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const CardFront = () => {
  const biometric = useSelector((state) => state.card.biometric);
  const picture = useSelector((state) => state.card.picture);

  return (
    <div className="front">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <div className="european-flag">
            <div className="european-flag__text">ES</div>
            <img src="/assets/images/flag-of-europe.svg" alt="Flag of Europe" className="european-flag__img" />
          </div>
          <img src="/assets/images/flag-of-spain.svg" alt="Flag of Spain" className="spain-flag" />
        </Stack>
        <Box sx={{ flex: 1, marginLeft: 1.5 }}>
          <div className="front__title">REINO DE ESPAÑA</div>
          <div className="front__subtitle">Documento Nacional De Identidad</div>
        </Box>
        <img className="top-icon-shape" src="/assets/images/spain-id-shape.svg" />
      </Stack>

      <div className="card-content">
        <div className="card-content__left">
          <img
            className="profile-picture"
            src={`data:image/png;base64,${picture?.picture}`}
            alt={`${biometric?.name} ${biometric?.firstNames} ${biometric?.thirdName}`}
          />
        </div>
        <div className="card-content__middle">
          <div className="field dni">
            <div className="field__label">DNI</div>
            <div className="field__value">[TODO]</div>
          </div>
          <div className="field">
            <div className="field__label">Apellidos / Apelidos</div>
            <div className="field__value">
              {biometric?.firstNames} {biometric?.thirdName}
            </div>
          </div>
          <div className="field">
            <div className="field__label">Nombre / Izena</div>
            <div className="field__value">{biometric?.name}</div>
          </div>
          <div className="field-row">
            <div className="field">
              <div className="field__label small">Sexo / Sexua</div>
              <div className="field__value">{biometric?.sex}</div>
            </div>
            <div className="field">
              <div className="field__label small">Nacionalidad / Nazionalitatea</div>
              <div className="field__value">{biometric?.nationality.toUpperCase()}</div>
            </div>
            <div className="field">
              <div className="field__label small">Nacimiento / Nacemento</div>
              <div className="field__value">{biometric?.birthDate}</div>
            </div>
          </div>
          <div className="field-row">
            <div className="field">
              <div className="field__label">Emision / Emision</div>
              <div className="field__value">{biometric?.cardValidityDateBegin}</div>
            </div>
            <div className="field">
              <div className="field__label">Validez / Baliotasuna</div>
              <div className="field__value">{biometric?.cardValidityDateEnd}</div>
            </div>
          </div>
          <div className="field">
            <div className="field__label">Num Soporte</div>
            <div className="field__value">
              {`${biometric?.cardNumber.substr(0, 3)}-${biometric?.cardNumber.substr(
                3,
                7,
              )}-${biometric?.cardNumber.substr(10, 2)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFront;
