import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import AuthenticationList from '../AuthenicationList';
import { useTranslation } from 'react-i18next';

const Authentications = (props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack sx={{ mb: 2 }} direction="row" alignItems="flex-end" justifyContent="space-between">
        <Box>
          <Typography variant="h4">{t('page.authentications.title', 'Authentication requests')}</Typography>
        </Box>
        <Button
          color="primary"
          size="medium"
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={props.getAuthentications}
        >
          {t('action.refresh', 'Refresh')}
        </Button>
      </Stack>
      <AuthenticationList authentications={props.authentications} loading={props.loading} />
    </Box>
  );
};

export default Authentications;
